import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ParametersService {

  private DEFAULT_COLOR = '#ec7000';

  constructor(
    private route: ActivatedRoute,
  ) { }

  getColor(): Observable<string> {
    return this.route.queryParams
      .pipe(map(params => params.color || sessionStorage.getItem('color') || this.DEFAULT_COLOR))
      .pipe(map(color => {
        sessionStorage.setItem('color', color);
        return color;
      }));
  }
}
