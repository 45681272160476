'use strict';
class Aware2 {
    
    handleCaptureButton() {
        console.log('aqui aware 111');
        if (hasError) {
            return;
        }
        currentTask = task.CAPTURE;
        if (isCameraInitialized) {
            console.log('aqui aware 112');
            startAutocapture();
        }
        else {
            console.log('aqui aware 113');
            initializeCamera();
        }
    }
    

    /*allReady() {
        console.log('aqui aware 0');
        let mainCamera = new Module.Camera();
        console.log('aqui aware 1');
        mainCamera.SetPropertyInt(Module.CameraProperty.ORIENTATION, mainCamera.IsMobileDevice() ? Module.CameraOrientation.PORTRAIT.value : Module.CameraOrientation.LANDSCAPE.value);
        console.log('aqui aware 2');
        mainCamera.SetPropertyString(Module.CameraProperty.CAMERA_TAG_ID, "previewWindow");
        console.log('aqui aware 3');
        mainCamera.SetPropertyString(Module.CameraProperty.ROI_TAG_ID, "ovalOverlay");
        console.log('aqui aware 4');
        mainCamera.SetPropertyString(Module.CameraProperty.FINISHED_INITIALIZING_SUCCESS_FN, "cameraFinishedInitializingSuccess");
        console.log('aqui aware 5');
        mainCamera.SetPropertyString(Module.CameraProperty.FINISHED_INITIALIZING_FAILURE_FN, "cameraFinishedInitializingFailure");
        console.log('aqui aware 6');
        mainCamera.SetPropertyString(Module.CameraProperty.FINISHED_COLLECTING_FRAMES_FN, "cameraFinishedCollectingFrames");
        console.log('aqui aware 7');
        mainCamera.Initialize();
        // this.prepareAutocapturePayload(mainCamera);
        console.log('aqui aware fim');
        return mainCamera;
    } */

    startCamera() {
        console.log('AWARE startCamera');
        mainCamera.Play();
    }

    stopCamera() {
        console.log('AWARE stopCamera');
        mainCamera.Stop();
    }

    pauseCamera() {
        console.log('AWARE pauseCamera');
        mainCamera.Pause();
    }

    prepareAutocapturePayload(mainCamera) {
        console.log('AWARE prepareAutocapturePayload 1.1');
        let payloadObj = new Module.Payload();
        console.log('AWARE prepareAutocapturePayload 1.2');
        payloadObj.SetPropertyString(Module.PayloadProperty.USERNAME, 'Itau');
        console.log('AWARE prepareAutocapturePayload 1.3');
        let payload = payloadObj.GetAutocapturePayload(mainCamera);
        console.log('AWARE prepareAutocapturePayload 1.4 - ', payload);
        return payload;
    }
    
    prepareAnalyzePayload(mainCamera) {
        let payloadObj = new Module.Payload();
        payloadObj.SetPropertyString(Module.PayloadProperty.USERNAME, payloadUsername);
        payloadObj.SetPropertyString(Module.PayloadProperty.WORKFLOW, mainCamera.IsMobileDevice() ? Module.CHARLIE4 : Module.HOTEL4);
        let payload = payloadObj.GetAnalyzePayload(mainCamera);
        return payload;
    }
}

export default Aware2;