import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

datadogRum.init({
  applicationId: environment.dataDogID,
  clientToken: environment.dataDogTK,
  env: environment.production ? 'prod' : 'hom',
  site: 'datadoghq.com',
  service: 'ou3-biometria-captura-web',
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});