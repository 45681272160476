declare const CyberTWC: any;
export let capture: any;
export let captureResponde: any;
export const getFieldsCaronte = () => new Promise(() => {
  try {
    CyberTWC.getCollect((data: any) => {
      captureResponde = data;
      console.log('CyberTWC_HELPER', captureResponde);
      return captureResponde;
    });
  } catch (e) {
    console.log('aqui_17', e);
    return e;
  }
});
