<div class="app-success">
    <div class="app-success-body box-margin">
        <div class="content">
            <div class="animation">
                <img aria-hidden="true" src="assets/icons/face_sem_upload.png" width="200" height="200" id="face_sem_upload">
                <br clear="all" />
                <img aria-hidden="true" src="assets/icons/face_upload.gif" width="70" height="70">
            </div>
            
            <div class="message">
                <label id="label_register_success" tabindex="1">Foto enviada com sucesso!</label>
            </div>
        </div>
        <div class="content-fixed-botton" aria-hidden="true">
            <div class="action-buttons">
                <div class="action-button">
                    <app-button [fullWidth]="true" label='voltar para o site' (click)="redirectUrlNull()">
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</div>