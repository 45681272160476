import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnInit {

  @Input() label: string;
  @Input() fullWidth = false;
  color: Observable<string>;

  constructor(
    private parametersService: ParametersService,
  ) { }

  ngOnInit(): void {
    this.color = this.parametersService.getColor();
  }
}
