import { IEncrypted } from 'src/app/types/domain';
import { environment } from 'src/environments/environment';

declare const CryptoJS: any;
declare const JSEncrypt: any;

async function encryptRsa(message: string, publicKey: string): Promise<string> {
  const encrypt_vr = new JSEncrypt();
  encrypt_vr.setPublicKey(publicKey);
  return encrypt_vr.encrypt(message);
}

const encryptAes = (text: string, aesKey: string) => CryptoJS.AES.encrypt(text, aesKey);

function randomText(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const encrypt = async (payload: string): Promise<IEncrypted> => {
  const aesKey = randomText(16);
  const rsaKey = environment.publicKey;
  const encryptedPayload = encryptAes(payload, aesKey);
  const encryptedKey = await encryptRsa(aesKey, rsaKey);
  return { payload: encryptedPayload.toString(), key: encryptedKey };
};
