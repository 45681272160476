import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ParametersService } from 'src/app/services/parameters/parameters.service';

@Component({
  selector: 'app-capture-icon',
  templateUrl: './capture-icon.component.html',
  styleUrls: ['./capture-icon.component.scss'],
})
export class CaptureIconComponent implements OnInit {
  color: Observable<string>;

  constructor(
    private parametersService: ParametersService,
  ) { }

  ngOnInit(): void {
    this.color = this.parametersService.getColor();
  }
}
