<span class="app-capture-icon">
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <svg aria-hidden="true" focusable="false" version="1.0" xmlns="http://www.w3.org/2000/svg" width="72.000000pt" height="72.000000pt"
        viewBox="0 0 72.000000 72.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,72.000000) scale(0.100000,-0.100000)" [style.fill]="color | async" stroke="none">
            <path d="M90 710 c-49 -12 -77 -49 -85 -112 -9 -72 -4 -113 15 -113 11 0 16
17 20 72 7 99 24 116 123 123 55 4 72 9 72 20 0 18 -88 24 -145 10z" />
            <path d="M486 711 c-14 -22 6 -31 69 -31 53 0 69 -4 92 -24 25 -21 29 -33 33
-98 4 -56 8 -73 20 -73 12 0 15 15 14 76 -1 82 -12 110 -49 135 -30 19 -169
32 -179 15z" />
            <path d="M204 467 c-10 -28 0 -82 16 -82 11 0 15 12 15 44 0 44 -19 68 -31 38z" />
            <path d="M367 474 c-4 -4 -7 -40 -7 -80 0 -64 -2 -72 -21 -77 -25 -7 -20 -31
7 -35 40 -6 55 28 52 113 -3 72 -13 97 -31 79z" />
            <path d="M484 467 c-10 -28 0 -82 16 -82 11 0 15 12 15 44 0 44 -19 68 -31 38z" />
            <path d="M203 234 c-11 -30 74 -74 143 -74 39 0 134 45 134 64 0 26 -19 28
-51 4 -43 -32 -134 -33 -178 0 -36 26 -40 27 -48 6z" />
            <path d="M4 225 c-11 -27 4 -146 21 -171 23 -35 45 -43 131 -46 65 -2 79 0 79
12 0 11 -17 16 -72 20 -98 7 -116 24 -123 122 -5 70 -22 100 -36 63z" />
            <path d="M687 234 c-4 -4 -7 -36 -7 -70 0 -52 -4 -68 -24 -91 -21 -25 -33 -29
-98 -33 -56 -4 -73 -8 -73 -20 0 -12 14 -14 79 -12 124 4 148 28 150 149 1 70
-8 96 -27 77z" />
        </g>
    </svg>

</span>