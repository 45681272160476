<div class="erro-interno">
  <img class="erro-img" src="assets\recfacial_roxo_celular_vertical.png" alt="" aria-hidden="true" />
  <h1 class="erro-title bold">
    por favor, siga nossas dicas para tentar novamente <span aria-hidden="true"></span>
  </h1>
  <div class="resumo-head">
    <div class="list-tips">
      <ul>
          <li>
              <div class="image">
                  <img aria-hidden="true" src="assets/lamb.png" />
              </div>
              <div class="text">
                  esteja em um local iluminado.
              </div>
              <br clear="all" />
          </li>
          <li>
              <div class="image">
                  <img aria-hidden="true" src="assets/attention.png" />
              </div>
              <div class="text">
                  não use boné, óculos ou algo que cubra seu rosto.
              </div>
              <br clear="all" />
          </li>
      </ul>
    </div>
    <!--
    <div class="resumo-head-first">
      <div class="resumo-text">
        <voxel-icon style="color: #EC7000">inovacao_outline</voxel-icon>
        <p>
          <span class="voxel-text-l"> o local deve estar iluminado </span>
        </p>
      </div>
    </div>
    <div class="resumo-head-second">
      <div class="resumo-text">
        <voxel-icon style="color: #EC7000">aviso_outline</voxel-icon>
        <p>
          <span class="voxel-text-l">não use boné, óculos ou algo que cubra seu rosto</span>
          </p>
      </div>
    </div>
    -->
  </div>
</div>