import { CaptureRoutingModule } from './capture-routing.module';
import { UploadComponent } from './upload/upload.component';
import { DoubtComponent } from './doubt/doubt.component';
import { ComponentModule } from '../components/components.module';
import { HomeComponent } from './home/home.component.utils';
import { SuccessComponent } from './success/success.component';
import { ErrorComponent } from './error/error.component';
import { ErrorriskComponent } from './errorrisk/errorrisk.component';
import { CamComponent } from './cam/cam.component.utils';
import { NgModule } from '@angular/core';
import { ErrorpermissionComponent } from './errorpermission/errorpermission.component.utils';
import { ErrorContentComponent } from './error-content/error-content.component';
import { FacetecErrorComponent } from './error-content/facetec-error/facetec-error.component';
import { HttpService } from '../services/http.service';
import { ParametersService } from '../services/parameters/parameters.service';

@NgModule({
  declarations: [
    UploadComponent,
    DoubtComponent,
    HomeComponent,
    CamComponent,
    SuccessComponent,
    ErrorComponent,
    ErrorriskComponent,
    ErrorpermissionComponent,
    ErrorContentComponent,
    FacetecErrorComponent,
  ],
  imports: [
    CaptureRoutingModule,
    ComponentModule,
  ],
  providers: [
    ParametersService,
    HttpService,
  ],
})
export class CaptureModule { }
