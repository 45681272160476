import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SysteminformationService {
  window = {
    jscd : {
      screen: '',
      browser: '',
      browserVersion: '',
      browserMajorVersion: 0,
      mobile: false,
      os: '',
      osVersion: '',
      cookies: false,
      flashVersion: '',
      nAgt: '',
    },
  };

  constructor() { }

  getInfos(screenWidth: any, screenHeight: any, browserAgt: any) {
    const unknown = '-';
    let width = 0;
    let height = 0;
    // screen
    let screenSize = '';
    if ((screenWidth) && (screenWidth !== '')) {
      width = (screenWidth) ? screenWidth : 0;
      height = (screenHeight) ? screenHeight : 0;
      screenSize += '' + width + ' x ' + height;
    }

    // browser
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browser = navigator.appName;
    let version = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset = 0;
    let verOffset = 0;
    const fields_agt = {
      browser_agt: '',
      verOffset_agt: '',
      version_agt: 0,
    };
    let ix = 0;
    // Opera
    if (browserAgt.Opera !== -1) {
      browser = 'Opera';
      verOffset = verOffset + browserAgt.Opera;
      version = nAgt.substring(verOffset + 6);
      if (nAgt.indexOf('Version') !== -1) {
        version = nAgt.substring(verOffset + 8);
      }
    }
    // Opera Next
    if (browserAgt.OPR !== -1) {
      verOffset = verOffset + browserAgt.OPR;
      browser = 'Opera';
      version = nAgt.substring(verOffset + 4);
    } else if (browserAgt.Edge !== -1) {
      verOffset = verOffset + browserAgt.Edge;
      browser = 'Microsoft Legacy Edge';
      version = nAgt.substring(verOffset + 5);
    } else if (browserAgt.Edg !== -1) {
      verOffset = verOffset + browserAgt.Edg;
      browser = 'Microsoft Edge';
      version = nAgt.substring(verOffset + 4);
    } else if (browserAgt.MSIE !== -1) {
      verOffset = verOffset + browserAgt.MSIE;
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(verOffset + 5);
    } else if (browserAgt.Chrome !== -1) {
      verOffset = verOffset + browserAgt.Chrome;
      browser = 'Chrome';
      version = nAgt.substring(verOffset + 7);
    } else if (browserAgt.Safari !== -1) {
      verOffset = verOffset + browserAgt.Safari;
      browser = 'Safari';
      version = nAgt.substring(verOffset + 7);
      if (browserAgt.Version !== -1) {
        version = nAgt.substring(verOffset + 8);
      }
    } else if (browserAgt.Firefox !== -1) {
      verOffset = verOffset + browserAgt.Firefox;
      browser = 'Firefox';
      version = nAgt.substring(verOffset + 8);
    } else if (browserAgt.Trident !== -1) {
      verOffset = verOffset + browserAgt.Trident;
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(nAgt.indexOf('rv:') + 3);
    } else if (nAgt.lastIndexOf(' ') + 1 < nAgt.lastIndexOf('/')) {
      nameOffset = 0;
      verOffset = verOffset + nAgt.lastIndexOf(' ') + 1;
      browser = nAgt.substring(nameOffset, verOffset);
      version = nAgt.substring(verOffset + 1);
      if (browser.toLowerCase() === browser.toUpperCase()) {
        browser = navigator.appName;
      }
    }

    // trim the version string
    if (version.indexOf(';') !== -1) {
      ix = version.indexOf(';');
      version = version.substring(0, ix);
    } else if (version.indexOf(' ') !== -1) {
      ix = version.indexOf(' ');
      version = version.substring(0, ix);
    } else if (version.indexOf(')') !== -1) {
      ix = version.indexOf(')');
      version = version.substring(0, ix);
    }

    majorVersion = parseInt('' + version, 10);
    if (isNaN(majorVersion)) {
      version = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    // mobile version
    const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)|BlackBerry|webOS|Mobi/.test(nVer);

    // cookie
    const cookieEnabled = (navigator.cookieEnabled) ? true : false;

/*    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = (document.cookie.indexOf('testcookie') !== -1) ? true : false;
    }
*/
    // system
    let os = unknown;
    const clientStrings = [
        { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
        { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
        { s: 'Windows Vista', r: /Windows NT 6.0/ },
        { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
        { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
        { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
        { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
        { s: 'Windows 98', r: /(Windows 98|Win98)/ },
        { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
        { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
        { s: 'Windows CE', r: /Windows CE/ },
        { s: 'Windows 3.11', r: /Win16/ },
        { s: 'Android', r: /Android/ },
        { s: 'Open BSD', r: /OpenBSD/ },
        { s: 'Sun OS', r: /SunOS/ },
        { s: 'Chrome OS', r: /CrOS/ },
        { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
        { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
        { s: 'Mac OS X', r: /Mac OS X/ },
        { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: 'QNX', r: /QNX/ },
        { s: 'UNIX', r: /UNIX/ },
        { s: 'BeOS', r: /BeOS/ },
        { s: 'OS/2', r: /OS\/2/ },
        { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
    ];

    // for (const field in this.formErrors) {
    // for (const field of Object.keys(this.formErrors)) {
    // for (const id in clientStrings) {
    // for (const id of Object.keys(clientStrings)) {
    for (const id of Object.keys(clientStrings)) {
      const cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    let osVersion = unknown;

    if (/Windows/.test(os)) {
      const verify_windows = /Windows (.*)/.exec(os);

      if ((verify_windows) && (verify_windows.length > 0)) {
        osVersion = 1 in verify_windows ? verify_windows[1] : '';
      }

      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS':
      case 'Mac OS X':
      case 'Android':
        const verify_android = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(nAgt);
        if ((verify_android) && (verify_android.length > 0)) {
          osVersion = 1 in verify_android ? verify_android[1] : '';
        }

        // osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        const verify_ios = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        if ((verify_ios) && (verify_ios.length > 0)) {
          osVersion = 3 in verify_ios ? verify_ios[1] + '.' + verify_ios[2] : '';
        }

        break;
    }

    const flashVersion = 'no check';
    /*if (typeof swfobject !== 'undefined') {
        var fv = swfobject.getFlashPlayerVersion();
        if (fv.major > 0) {
            flashVersion = fv.major + '.' + fv.minor + ' r' + fv.release;
        }
        else  {
            flashVersion = unknown;
        }
    }*/

    this.window.jscd = {
      screen: screenSize,
      browser,
      browserVersion: version,
      browserMajorVersion: majorVersion,
      mobile,
      os,
      osVersion,
      cookies: cookieEnabled,
      flashVersion,
      nAgt,
    };

    return this.window.jscd;
  }

  /*getnAgt(agt_comb: {}, agt: int, browser_nome: string, nAgt_function: any) {
    let fields_agt_comb = {
      browser_agt_comb: agt_comb.browser_agt,
      verOffset_agt_comb: agt_comb.verOffset_agt,
      version_agt_comb: agt_comb.version_agt,
      renove: false,
    };

    // Opera
    if ((browser_nome === 'Opera') && (nAgt_function.indexOf('Opera') !== -1)) {
      fields_agt_comb.browser_agt_comb = 'Opera';
      fields_agt_comb.verOffset_agt_comb = verOffset + nAgt_function.indexOf('Opera');
      fields_agt_comb.version_agt_comb = nAgt_function.substring(verOffset + 6);
      if (nAgt_function.indexOf(('Version') !== -1)) {
        fields_agt_comb.version_agt_comb = nAgt_function.substring(verOffset + 8);
      }
      fields_agt_comb.renAgt_function_comb: true;
    }
    // Opera Next
    if ((browser_nome === 'OPR') && (nAgt_function.indexOf('OPR') !== -1)) {
      fields_agt_comb.verOffset_agt_comb = fields_agt_comb.verOffset_agt_comb + nAgt_function.indexOf('OPR');
      fields_agt_comb.browser_agt_comb = 'Opera';
      fields_agt_comb.version_agt_comb = nAgt_function.substring(fields_agt_comb.verOffset_agt_comb + 4);
    } else if ((browser_nome === 'Edge') && (nAgt_function.indexOf('Edge') !== -1)) {
      fields_agt_comb.verOffset_agt_comb = fields_agt_comb.verOffset_agt_comb + nAgt_function.indexOf('Edge');
      fields_agt_comb.browser_agt_comb = 'Microsoft Legacy Edge';
      fields_agt_comb.version_agt_comb = nAgt_function.substring(fields_agt_comb.verOffset_agt_comb + 5);
    } else if ((browser_nome === 'Edg') && (nAgt_function.indexOf('Edg') !== -1)) {
      fields_agt_comb.verOffset_agt_comb = fields_agt_comb.verOffset_agt_comb + nAgt_function.indexOf('Edg');
      fields_agt_comb.browser_agt_comb = 'Microsoft Edge';
      fields_agt_comb.version_agt_comb = nAgt_function.substring(fields_agt_comb.verOffset_agt_comb + 4);
    } else if ((browser_nome === 'MSIE') && (nAgt_function.indexOf('MSIE') !== -1)) {
      fields_agt_comb.verOffset_agt_comb = fields_agt_comb.verOffset_agt_comb + nAgt_function.indexOf('MSIE');
      fields_agt_comb.browser_agt_comb = 'Microsoft Internet Explorer';
      fields_agt_comb.version_agt_comb = nAgt_function.substring(fields_agt_comb.verOffset_agt_comb + 5);
    } else if ((browser_nome === 'Chrome') && (nAgt_function.indexOf('Chrome') !== -1)) {
      fields_agt_comb.verOffset_agt_comb = fields_agt_comb.verOffset_agt_comb + nAgt_function.indexOf('Chrome');
      fields_agt_comb.browser_agt_comb = 'Chrome';
      fields_agt_comb.version_agt_comb = nAgt_function.substring(fields_agt_comb.verOffset_agt_comb + 7);
    } else if ((browser_nome === 'Safari') && (nAgt_function.indexOf('Safari') !== -1)) {
      fields_agt_comb.verOffset_agt_comb = fields_agt_comb.verOffset_agt_comb + nAgt_function.indexOf('Safari');
      fields_agt_comb.browser_agt_comb = 'Safari';
      fields_agt_comb.version_agt_comb = nAgt_function.substring(fields_agt_comb.verOffset_agt_comb + 7);
      if (nAgt_function.indexOf(('Version') !== -1)) {
        fields_agt_comb.version_agt_comb = nAgt_function.substring(fields_agt_comb.verOffset_agt_comb + 8);
      }
    } else if ((browser_nome === 'Firefox') && (nAgt_function.indexOf('Firefox') !== -1)) {
      fields_agt_comb.verOffset_agt_comb = fields_agt_comb.verOffset_agt_comb + nAgt_function.indexOf('Firefox');
      fields_agt_comb.browser_agt_comb = 'Firefox';
      fields_agt_comb.version_agt_comb = nAgt_function.substring(fields_agt_comb.verOffset_agt_comb + 8);
    } else if ((browser_nome === 'Trident/') && (nAgt_function.indexOf('Trident/') !== -1)) {
      fields_agt_comb.verOffset_agt_comb = fields_agt_comb.verOffset_agt_comb + nAgt_function.indexOf('Trident/');
      fields_agt_comb.browser_agt_comb = 'Microsoft Internet Explorer';
      fields_agt_comb.version_agt_comb = nAgt_function.substring(nAgt_function.indexOf('rv:') + 3);
    } else if (nAgt_function.lastIndexOf(' ') + 1 < nAgt_function.lastIndexOf('/')) {
      fields_agt_comb.nameOffset_agt_comb = 0;
      fields_agt_comb.verOffset_agt_comb = fields_agt_comb.verOffset_agt_comb + nAgt_function.lastIndexOf(' ') + 1;
      fields_agt_comb.browser_agt_comb = nAgt_function.substring(fields_agt_comb.nameOffset_agt_comb, fields_agt_comb.verOffset_agt_comb);
      fields_agt_comb.version_agt_comb = nAgt_function.substring(fields_agt_comb.verOffset_agt_comb + 1);
    }
    return fields_agt_comb;
  }*/

}
