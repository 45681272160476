import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ErrorsServices } from '../interfaces/ErrorsServices';
import { generateApiKey } from '../shared/helpers/encryption';
import { saveLinksCharon } from '../shared/helpers/dataAggregator';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorsPage } from '../interfaces/ErrorsPage';

@Injectable({
  providedIn: 'root',
})
export class CharonService {
  // Essa Classe deverá ser descontinuada quando migrarmos para Bio 2.0
  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  async carontLinks() {
    const header = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Methods': 'GET, POST, PUT',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, application/json',
      'Access-Control-Expose-Headers': 'true',
      'Content-Type': 'application/json',
      'x-itau-apikey': generateApiKey(6),
    });
    try {
      const observable = await this.http.get(environment.charonUrl, { headers: header, observe: 'response' })
        .pipe(
          tap((res: any) => { })
        );
      return lastValueFrom(observable)
        .then(
          response => {
            const session = response?.headers?.get('X-Charon-Session');
            localStorage.setItem('charon_session_link', session);
            saveLinksCharon(response.body._links)
          })
        .catch(
          (error: any) => {
            this.router.navigate(['redirect/error'], { queryParams: { errorCode: ErrorsPage.FAIL_KEY }, skipLocationChange: true });
            throw error;
          });
    } catch (error) {
      console.error(ErrorsServices.CarontFail, error);
    }
  }
}