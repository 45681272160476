import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
import { CatchError } from 'src/app/types/error';

@Injectable({
  providedIn: 'root',
})
export class CharonService {
  token: any;
  decoded: any;
  redirect_url: any;
  client_id: any;
  response_session: {
    'resposta_sessao': any,
    'token': any,
    'decoded': any,
    'redirect_url': any,
    'client_id': any,
    'not_session': boolean,
  };
  resposta_facequality: any;
  myFormattedDate: any;
  pipe_date = new DatePipe('en-US'); // Use your own locale
  register_timeout = {
    captureKey: '',
    cod_tipo_operacao: '1',
    codigo_ipv6: '',
    codigo_ipv4: '192.0.0.1',
    codigo_motivo_nao_captura: '',
    conteudo_analise_sdk: {
      video: {
        workflow_data: {
          frames: [
            {
              data: '',
              timestamp: 0,
              tags: [] as any,
            },
            {
              data: '',
              timestamp: 0,
              tags: [] as any,
            },
            {
              data: '',
              timestamp: 0,
              tags: [] as any,
            },
            {
              data: '',
              timestamp: 0,
              tags: [] as any,
            },
          ],
        },
      },
    },
    datetime_operacao: '2021-08-04 00:06:14.180',
    documentos_cliente: {
      documento: 'aba82dbe-3efd-43aa-823d-02de46415c39',
    },
    fabricante_dispositivo: 'Samsung',
    id_captura_apos_timeout: '1',
    dispositivo_confianca_cliente: 'nulo',
    id_cliente_confiavel: false,
    id_processo_origem: '13',
    id_sessao: '0004220069000010337400430361067CF020',
    id_tipo_captura: '2',
    localizacao: {
      latitude: '-23.5577625',
      longitude: '-46.6172617',
      timezone: 'PST',
      cidade: 'São Paulo',
      uf: 'SP',
      pais: 'Brasil',
    },
    mensagem_orientacao: [],
    modelo_dispositivo: 'Samsung Galaxy Note 10 Plus',
    numero_tentativa: '0',
    resolucao_camera: '1900 x 1080',
    sistema_operacional: 'Android',
    versao_app_canal: '4.5.1',
    versao_sdk_fornecedor: '2.3',
    versao_sdk_mobile: '6.0.4',
    versao_sistema_operacional: 'Lollipop',
  };

  error_facequality = [] as any;
  response_facequality: {
    'error_message_front': any,
    'error_facequality': any,
    'code': any,
    'status': any,
    'message': any,
    'statusPack': false,
    'controlPackSuccess': any,
    'register_timeout': any,
    'frame4': any,
    'register': any,
    'display_class1': any,
    'display_class2': any,
    'classProgress': any,
    'controlPackRegisterSuccess': any,
    'classProgressRegister': any,
    'register_success': any,
  };

  facequality = {
    package: '',
  };
  frame_temporary: { data: string; timestamp: number; tags: never[]; };
  classProgress: string;
  message_sucess_register = ['Estamos analisando sua foto', 'Esse é um momento importante para garantir sua segurança', 'Estamos validando suas informações', ' Cuidamos da segurança das suas transações', 'a tecnologia está identificando pontos no seu rosto'];
  ignore_facequality: boolean;
  resposta_register: any;
  response_register: {
    'display_class1': any,
    'display_class2': any,
    'capturado': any,
    'showPopup': any,
    'tentativas': any,
    'ignore_facequality': false,
    'err': any,
    'err_message': any,
    'resposta_register': any,
  };
  falhou = false;

  constructor(
    private http: HttpClient,
  ) { }

  getLinks() {
    try {
      return this.http.get(environment.charonUrl, {
        observe: 'response', headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Methods': 'GET, POST, PUT',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, application/json',
          'Access-Control-Expose-Headers': 'true',
          'Content-Type': 'application/json',
          'x-itau-apikey': this.randomText(6),
        }),
      }).pipe(map((res: any) => ({
        facequality: res?.body?._links?.facequality?.href,
        session: res?.body?._links?.session?.href,
        parameters: res?.body?._links?.parameters?.href,
        register: res?.body?._links?.register?.href,
        logs: res?.body?._links?.logs?.href,
        charon_session: res?.headers?.get('X-Charon-Session'),
      })));
		} catch (error) {
			console.error(CatchError.sendLogsFail, `Error: ${error}`)
		}
  }

  generateUUID() {
    return uuidv4();
  }

  randomText(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}