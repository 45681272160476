import { DatePipe, Location } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import DeviceDetector from 'device-detector-js';
import html2canvas from 'html2canvas';
import jwtDecode from 'jwt-decode';
import { Subscription } from 'rxjs';
import { AngularSampleApp } from 'src/assets/facetec/angular-sample-controller.utils';
import { IEncrypted } from 'src/app/types/domain';
import { capture$, constraints, drawIndicator, startAutoCapture } from 'src/app/helper/cam';
import { captureResponde, getFieldsCaronte } from 'src/app/helper/caronte';
import { encrypt } from 'src/app/helper/crypt';
import { environment } from '../../../environments/environment';

import { Config } from 'src/assets/facetec/Config';
import { SampleAppUtilities } from 'src/assets/facetec/utilities/SampleAppUtilities.utils';
import { FaceTecIDScanResult, FaceTecSessionResult } from 'src/assets/facetec/core-sdk/FaceTecSDK.js/FaceTecPublicApi';
import { LivenessCheckProcessor } from 'src/assets/facetec/processors/LivenessCheckProcessor.utils';
import { HomeComponent } from '../home/home.component.utils';
import { NgxSpinnerService } from 'ngx-spinner';
import Aware2 from 'src/assets/lib/aware/aware-KnomiWeb-1.2/cam';

import { HttpService } from 'src/app/services/http.service';
import { CharonService } from 'src/app/services/charon/charon.service';
import { ErrorcallsService } from 'src/app/services/errorcalls/errorcalls.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { SysteminformationService } from 'src/app/services/systeminformation/systeminformation.service';
import { FaceTecSDK } from 'src/assets/facetec/core-sdk/FaceTecSDK.js/FaceTecSDK';
import { facetecLabels } from 'src/assets/facetec/facetec-labels';
import { LogsService } from 'src/app/services/logs/logs.service';
import { TaggingServiceGA4 } from 'src/app/services/tagging/taggingGA4.service';

declare const window: any;
declare const address: any;
declare const mainCamera: any;

const enum Popup {
  INITIAL = 'initial',
  SECONDARY = 'secondary',
  ERROR_NO_CAPTURE = 'error_no_capture',
  ERROR_NO_FRAME = 'error_no_frame',
  ERROR_NO_APROVED_QUALITY = 'no_aproved_quality',
  ERROR_NO_APROVED_ERROR_SERVER = 'no_aproved_error_server',
  ERROR_NO_INDICATION = 'error_no_indication',
  ERROR_RISK_A = 'error_risk_a',
  ERROR_RISK_B = 'error_risk_b',
  ERROR_RISK_AB = 'error_risk_ab',
  ERROR_GENERAL = 'general',
  CAPTURE = 'captura',
  ERROR_NEW_TRY = 'Error_New_Try',
  ERROR_NO_PERMISSION = 'error_no_permission',
  ERROR_NO_MOBILE = 'error_no_mobile',
  ERROR_ATTEMPTS = 'Attempts_Error',
  SUCCESS = 'sucesso_captura',
  ERROR_NO_SESSION = 'error_no_session',
}

@Component({
  selector: 'app-cam',
  templateUrl: './cam.component.html',
  styleUrls: ['./cam.component.scss'],
})

@HostListener('window:beforeunload', ['$event'])

export class CamComponent implements OnInit, OnDestroy {
  @ViewChild('video') video: ElementRef;
  @ViewChild('ovalOverlay') ovalOverlay: ElementRef;
  sdkFacetecInicializado = false;
  labelBtnFacetecTudoPronto = 'tudo pronto';
  address: any;
  addressipv6: any;
  type_liveness = 'aware';
  aria_hidden = true;
  aria_hidden_spinner = true;
  btnHabilitado: any;
  statusBar: any;
  aria_hidden_capture = true;
  aria_hidden_success = true;
  aria_hidden_capture_model = true;
  aria_hidden_capture_mensagem = true;
  block_register = false;
  error_register = false;
  // Variaveis de controle
  showPopup?: Popup = Popup.INITIAL;
  showPopupError = false;
  isStreaming = false;
  captureSub: Subscription;
  captureSub2: any;
  code = 0;
  page = '';
  key_capture = '';
  redirect_url = '';
  redirect_error_url = '';
  redirectUrlRetry = '';
  message = '';
  cpf_idpessoa = '';
  origin_process = '';
  status = '';
  captureKey = '';
  fields = {
    code: 0,
    status: '',
    message: '',
    key_capture: '',
    cpf_idpessoa: '',
    origin_process: '',
    redirect_url: '',
    captureKey: this.captureKey,
  };
  client_id = '';
  fields_token = {
    grant_type: '',
    client_id: '',
    client_secret: '',
  };

  fields_keys = {
    clientId: '64d5a9da-1c05-4a6f-9111-b786eabb4137',
    processOriginId: '3',
    urlRedirect: 'www.itau.com.br',
  };

  resp = {};
  cam_error: any;
  caronte_session: any;
  caronte_register: any;
  caronte_facequality: any;
  caronte_parameters: any;
  caronte_logs: any;
  caronte_header: any;
  token: any;
  decoded = {
    aud: '',
    captureKey: '',
    exp: 0,
    iss: '',
    originProcess: '',
    redirectUrl: 'www.itau.com.br',
    urlRedirectError: 'www.itau.com.br',
    redirectUrlError: 'www.itau.com.br',
    redirectUrlRetry: 'www.itau.com.br',
    sub: '',
    consultaBureau: null,
    customAttributes: {
      idbiocath: '',
      idproposta: '',
    },
  };
  idbiocath: any;
  idproposta: any;
  tela_dividida = true;
  tentativas: any;

  cn: any;
  ambiente: any;
  negocio: any;
  tipoDeCanal: any;
  segmento: string;
  prefix_pagina: any;
  link_tag: any;
  tid: any;

  session = {
    captureKey: this.captureKey,
  };

  register = {
    captureKey: this.captureKey,
    cod_tipo_operacao: '1',
    codigo_ipv6: '00:00:00',
    codigo_ipv4: '192.0.0.1',
    codigo_motivo_nao_captura: '',
    conteudo_analise_sdk: {
      video: {
        workflow_data: {
          frames: [
            {
              data: '',
              timestamp: 0,
              tags: [] as any,
            },
            {
              data: '',
              timestamp: 0,
              tags: [] as any,
            },
            {
              data: '',
              timestamp: 0,
              tags: [] as any,
            },
            {
              data: '',
              timestamp: 0,
              tags: [] as any,
            },
          ],
        },
      },
      faceScan: '',
      auditTrailImage: '',
      lowQualityAuditTrailImage: '',
      livenessCheckSucess: true,
      iv: '',
      key: '',
      pi: '',
    },
    datetime_operacao: '2023-08-04 00:06:14.180',
    documentos_cliente: {
      documento: 'aba82dbe-1111-1111-823d-02de46415c39',
    },
    fabricante_dispositivo: 'Samsung',
    id_captura_apos_timeout: '0',
    dispositivo_confianca_cliente: 'nulo',
    id_cliente_confiavel: false,
    id_processo_origem: '13',
    id_sessao: '0004220069000010337400430361067CF020',
    id_tipo_captura: '2',
    localizacao: {
      latitude: '-23.5577625',
      longitude: '-46.6172617',
      timezone: 'PST',
      cidade: 'São Paulo',
      uf: 'SP',
      pais: 'Brasil',
    },
    mensagem_orientacao: [],
    modelo_dispositivo: 'Samsung Galaxy Note 10 Plus',
    numero_tentativa: '0',
    resolucao_camera: '1900 x 1080',
    sistema_operacional: 'Android',
    versao_app_canal: '4.5.1',
    versao_sdk_fornecedor: '2.3',
    versao_sdk_mobile: '6.0.4',
    versao_sistema_operacional: 'Lollipop',
    logs_caronte: [],
    conteudo_facetec: {},
    consulta_bureau: null,
  };

  register_timeout = {
    captureKey: this.captureKey,
    cod_tipo_operacao: '1',
    codigo_ipv6: '00:00:00',
    codigo_ipv4: '192.0.0.1',
    codigo_motivo_nao_captura: '',
    conteudo_analise_sdk: {
      video: {
        workflow_data: {
          frames: [
            {
              data: '',
              timestamp: 0,
              tags: [] as any,
            },
            {
              data: '',
              timestamp: 0,
              tags: [] as any,
            },
            {
              data: '',
              timestamp: 0,
              tags: [] as any,
            },
            {
              data: '',
              timestamp: 0,
              tags: [] as any,
            },
          ],
        },
      },
    },
    datetime_operacao: '2023-08-04 00:06:14.180',
    documentos_cliente: {
      documento: 'aba82dbe-3efd-43aa-823d-02de46415c39',
    },
    fabricante_dispositivo: 'Samsung',
    id_captura_apos_timeout: '1',
    dispositivo_confianca_cliente: 'nulo',
    id_cliente_confiavel: false,
    id_processo_origem: '13',
    id_sessao: '0004220069000010337400430361067CF020',
    id_tipo_captura: '2',
    localizacao: {
      latitude: '-23.5577625',
      longitude: '-46.6172617',
      timezone: 'PST',
      cidade: 'São Paulo',
      uf: 'SP',
      pais: 'Brasil',
    },
    mensagem_orientacao: [],
    modelo_dispositivo: 'Samsung Galaxy Note 10 Plus',
    numero_tentativa: '0',
    resolucao_camera: '1900 x 1080',
    sistema_operacional: 'Android',
    versao_app_canal: '4.5.1',
    versao_sdk_fornecedor: '2.3',
    versao_sdk_mobile: '6.0.4',
    versao_sistema_operacional: 'Lollipop',
    logs_caronte: [],
    conteudo_facetec: {},
    consultaBureau: null,
  };
  packx: string;
  facequality = {
    package: '',
    token: '',
  };
  classProgress = '';
  statusPack = false;
  controlPack = 0;
  controlPackSuccess = 0;
  controlPackRegisterSuccess = 0;
  classProgressRegister = '';
  frame4 = '';
  frames = [];
  frame_temporary = { data: '', timestamp: 0, tags: [] as any };

  myFormattedDate: any;

  error_facequality = [] as any;
  error_message_front = 'centralize o rosto na moldura';
  pipe = new DatePipe('en-US'); // Use your own locale
  accesstoken = false;
  new_resultados: any;
  resposta_facequality: any;
  resposta_register: any;
  resposta_sessao: any;
  capturado = false;

  controlTimeout = false;
  display_class1 = '';
  display_class2 = 'box-none';
  baseurl = environment.baseUrl;
  ignore_facequality = false;
  infos_os = {
    screen: '',
    os: '',
    osVersion: '',
    mobile: false,
    nAgt: '',
  };
  infos_logs = {
    nom_titulo: '',
    mensagem: '',
    chave_captura: '',
    id_pessoa: '',
    canal_origem: '',
  };
  deviceInfo = null;
  permission = '';
  message_sucess_register = ['Estamos analisando sua foto', 'Esse é um momento importante para garantir sua segurança', 'Estamos validando suas informações', ' Cuidamos da segurança das suas transações', 'a tecnologia está identificando pontos no seu rosto'];
  register_success = 'imagem analisada';
  take_my_picture = false;
  urlRetryPermissionCam: any;
  verifyTid = 'initial';

  display_none = '';
  display_none_percentual = '';
  display_none_progress = 'display_none';
  sessionTimeout = false;
  take_my_picture_on_camera_close = false;
  control_timeout_register = false;
  control_register = false;

  aria_hidden_capture_mensagem_h1_show = true;
  aria_hidden_capture_label = false;

  response_crypt: {
    payload: any,
    key: any,
  };
  error_facequality_conection: string;
  ignore_new_try = false;
  refresh_page = true;
  remove_beforeunload = true;
  verifica_recarrega = true;
  imageBack = false;
  messageRetorno = '';
  error_liveness_qualidade = false;
  caronte_key: any;
  caronte_payload: any;
  consultaBureau: null;
  consulta_ips: any;
  btnStatus = true;
  isError = false;
  isSafari = false;
  isFacequality = true;
  newAware = new Aware2();
  mainCamera: any;
  intervalFacequality: any;
  latestEnrollmentIdentifier = '';
  latestSessionResult: FaceTecSessionResult | null = null;
  latestIDScanResult: FaceTecIDScanResult | null = null;
  latestProcessor: LivenessCheckProcessor;
  camComponent: CamComponent;
  homeComponent: HomeComponent;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone,
    private charonService: CharonService,
    private errorcallsService: ErrorcallsService,
    private systeminformationService: SysteminformationService,
    private _location: Location,
    private loadingService: LoadingService,
    private spinner: NgxSpinnerService,
    private newSpinner: NgxSpinnerService,
    private httpService: HttpService,
    @Inject(LogsService)
    private logsService: LogsService,
    private taggingServiceGA4: TaggingServiceGA4,
  ) {
    this.loadingService.startLoading();
  }

  // FACETEC
  onLivenessCheckPressed() {
    AngularSampleApp.resetResponseObservable(this, undefined, this.resposta_sessao.tokenLiveness);
  }

  escondeMsg(event) {
    const msgStatus = document.getElementById('status').innerHTML;
    // tslint:disable-next-line: triple-equals
    if (msgStatus != 'Carregando...') {
      this.btnStatus = false;
    }
  }

  capturePosTimeout() {
    const ovalOverlay = document.getElementById('video');
    html2canvas(ovalOverlay!).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      this.register_timeout.conteudo_analise_sdk.video.workflow_data.frames[0].data = imgData;
      this.register_timeout.conteudo_analise_sdk.video.workflow_data.frames[1].data = imgData;
      this.register_timeout.conteudo_analise_sdk.video.workflow_data.frames[2].data = imgData;
      this.register_timeout.conteudo_analise_sdk.video.workflow_data.frames[3].data = imgData;

      this.register_timeout.conteudo_analise_sdk.video.workflow_data.frames[0].timestamp = 1539642745492;
      this.register_timeout.conteudo_analise_sdk.video.workflow_data.frames[1].timestamp = 1539642745527;
      this.register_timeout.conteudo_analise_sdk.video.workflow_data.frames[2].timestamp = 1539642745625;
      this.register_timeout.conteudo_analise_sdk.video.workflow_data.frames[3].timestamp = 1539642746131;

    });
  }

  backClicked() {
    this._location.back = () => { console.info('is_back_378'); return false; };
    this._location.back();
  }

  ngOnInit() {
    this.taggingServiceGA4.initTagging();
    this._location.back = () => { console.info('is_back_383'); return false; };
    window.history.back = () => { console.info('is_back_384'); return false; };
    this.getTaticoLogs(captureResponde);
    if (environment.disableConsoleLog) {
      console.info = () => { };
    }

    const scanResult = sessionStorage.getItem('scanResult');
    this.register.conteudo_facetec = JSON.parse(scanResult);
    this.conectCaronte('session');

    const now = new Date();
    const now_milliseconds = now.getMilliseconds();
    this.myFormattedDate = this.pipe.transform(now, 'yyyy-MM-dd HH:mm:ss');
    const formmatdate = this.myFormattedDate.toString() + '.' + now_milliseconds;
    this.register.datetime_operacao = formmatdate;
    this.register_timeout.datetime_operacao = formmatdate;
    this.activatedRoute.queryParams.subscribe(async (params: Params) => {
      this.cn = params.cn;
      this.ambiente = params.ambiente;
      this.negocio = params.negocio;
      this.tipoDeCanal = params.tipoDeCanal;
      this.prefix_pagina = params.prefix_pagina;
      this.tid = params.tid;
      this.segmento = params.customer_segment;

      sessionStorage.setItem('cn', this.cn);
      sessionStorage.setItem('ambiente', this.ambiente);
      sessionStorage.setItem('negocio', this.negocio);
      sessionStorage.setItem('tipoDeCanal', this.tipoDeCanal);
      sessionStorage.setItem('prefix_pagina', this.prefix_pagina);
      sessionStorage.setItem('tid', this.tid);
      this.key_capture = params.key_capture;

      this.link_tag = '&cn=' + this.cn + '&ambiente=' + this.ambiente + '&negocio=' + this.negocio + '&customer_segment' + this.segmento + '&tipoDeCanal=' + this.tipoDeCanal + '&prefix_pagina=' + this.prefix_pagina + '&tid=' + this.tid;

      localStorage.setItem('permission_cam', 'true');

    });

    sessionStorage.setItem('baseurl', this.baseurl);

    const tentativas_init = sessionStorage.getItem('tentativas');
    this.tentativas = tentativas_init;

    if ((this.tentativas === 'NaN') || (this.tentativas === null) || (this.tentativas === 0) || (this.tentativas === '') || (this.tentativas === '0')) {

      this.tentativas = 0;
      sessionStorage.setItem('tentativas', this.tentativas);
    }
    this.tentativas = 0;
    sessionStorage.setItem('tentativas', this.tentativas);

    this.tentativas = parseInt(this.tentativas, 10);

    const novaTentativa = this.verificaTentativa(this.tentativas);
    this.tentativas = novaTentativa;

    let redirect_storage = localStorage.getItem('redirect_url');
    if (redirect_storage == null) {
      redirect_storage = 'itau.com.br';
    }

    this.verifyFunction();
    this.display_class1 = '';
    this.display_class2 = 'box-none';
    const nAgt = navigator.userAgent;
    const modelsAgts = {
      Opera: nAgt.indexOf('Opera'),
      OPR: nAgt.indexOf('OPR'),
      Edge: nAgt.indexOf('Edge'),
      Edg: nAgt.indexOf('Edg'),
      MSIE: nAgt.indexOf('MSIE'),
      Chrome: nAgt.indexOf('Chrome'),
      Safari: nAgt.indexOf('Safari'),
      Firefox: nAgt.indexOf('Firefox'),
      Trident: nAgt.indexOf('Trident/'),
    };

    this.infos_os = this.systeminformationService.getInfos('', '', modelsAgts);
    this.logsService.handleLogs('INITIAL', `A URL de acesso atual é a  ${window.location.href}`, 'CAPTURE_PAGE');

    const deviceDetector = new DeviceDetector();
    const userAgent = this.infos_os.nAgt;
    const device = deviceDetector.parse(userAgent);

    if (environment.disableDesktop && !this.infos_os.mobile) {
      if (redirect_storage == null) {
        redirect_storage = 'itau.com.br';
      }
      window.document.location.href = 'https://' + redirect_storage + '/?&type=' + Popup.ERROR_NO_MOBILE + this.link_tag;
    }

    // Validar o browser do usuário, caso seja Iphone Safari deve aplicar um CSS
    if (device.client && device.client.name === 'Mobile Safari') {
      this.isSafari = true;
    }

    const device_model = '' + device.device?.model;
    const fabricante = device.device?.brand + '';

    this.register.modelo_dispositivo = device_model;
    this.register.fabricante_dispositivo = fabricante;
    const iHeight = window.screen.height;
    const iWidth = window.screen.width;
    this.verificaModeloDispositivo(this.register.fabricante_dispositivo, this.register.modelo_dispositivo, iWidth, iHeight);
    this.register.resolucao_camera = iWidth + ' X ' + iHeight;
    this.register.sistema_operacional = this.infos_os.os;
    this.register.versao_app_canal = '0';
    this.register.versao_sdk_fornecedor = '0';
    this.register.versao_sdk_mobile = '0';
    this.register.versao_sistema_operacional = this.infos_os.osVersion;

    this.register_timeout.modelo_dispositivo = device_model;
    this.register_timeout.fabricante_dispositivo = fabricante;
    this.register_timeout.resolucao_camera = iWidth + ' X ' + iHeight;
    this.register_timeout.sistema_operacional = this.infos_os.os;
    this.register_timeout.versao_app_canal = '0';
    this.register_timeout.versao_sdk_fornecedor = '0';
    this.register_timeout.versao_sdk_mobile = '0';
    this.register_timeout.versao_sistema_operacional = this.infos_os.osVersion;

    setTimeout(() => {
      this.btnHabilitado = setInterval(() => {
        clearInterval(this.btnHabilitado);
        this.loadingService.finishLoading();
      }, 500);

      this.accesstoken = this.verifyToken(this.token, this.status);
      const addressFront = (<HTMLInputElement>document.getElementById('address')).value;
      const addressFrontipv6 = (<HTMLInputElement>document.getElementById('addressipv6')).value;

      this.address = addressFront;
      this.addressipv6 = addressFrontipv6;
      this.logsService.handleLogs('IP', `O IP do usuário dessa chave é o ${this.address}`, 'CAPTURE_PAGE');
    }, 1500);

    this.getTaticoLogsInit();
  }

  setIfModelIos(infosOs: string, devicesIos: any, deviceModel: any) {
    let devicesreturn = devicesIos;
    if (infosOs === 'iOS') {
      const verificaModelIos = this.setIosModel(this.infos_os.os, devicesIos, deviceModel);
      devicesreturn = verificaModelIos;
    }
    return devicesreturn;
  }

  async initFacetec(facetecLicenseKey: string, dateExpiryKey: string) {
    const { FaceTecSDK } = await import('src/assets/facetec/core-sdk/FaceTecSDK.js/FaceTecSDK');
    SampleAppUtilities.formatUIForDevice();

    // Set a the directory path for other FaceTec Browser SDK Resources.
    FaceTecSDK.setResourceDirectory('/assets/facetec/core-sdk/FaceTecSDK.js/resources');

    // Set the directory path for required FaceTec Browser SDK images.
    FaceTecSDK.setImagesDirectory('/assets/facetec/core-sdk/FaceTec_images');

    // Esses dois proximos metodos nao são usados no sample
    FaceTecSDK.setCustomization(
      Config.retrieveConfigurationWizardCustomization(FaceTecSDK),
    );
    FaceTecSDK.setDynamicDimmingCustomization(Config.retrieveLowLightConfigurationWizardCustomization(FaceTecSDK));

    const keys = "{  \"domains\": \"" + environment.facetecLicenseDomains + "\",  \"expiryDate\": \"" + dateExpiryKey + "\",  \"key\": \"" + facetecLicenseKey + "\"}"
    Config.initializeFromAutogeneratedConfig(
      FaceTecSDK,
      keys,
      (initializedSuccessfully: boolean) => {
        if (initializedSuccessfully) {
          SampleAppUtilities.enableControlButtons();
          FaceTecSDK.configureLocalization(facetecLabels);
        }
        SampleAppUtilities.displayStatus(
          FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(
            FaceTecSDK.getStatus(),
          ),
        );
        console.info('$ Inicializado o módulo FaceTec; Retorno: ', FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus()));
        this.logsService.handleLogs('FaceTec', `Inicializado o módulo FaceTec; Retorno: ${FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus())}`, 'FACETEC');
      },
    );

    SampleAppUtilities.fadeInMainUIContainer();
    this.sdkFacetecInicializado = true;
  }

  verificaTentativa(verificacaoTentativa: any) {
    if (verificacaoTentativa > 3) {
      this.tentativas = 0;
      sessionStorage.setItem('tentativas', this.tentativas);
      return this.tentativas;
    }

    return verificacaoTentativa;
  }

  verificaModeloDispositivo(fabricanteDispositivo: string, modeloDispositivo: string, iDispositivoWidth: any, iDispositivoHeight: any) {
    if ((fabricanteDispositivo === 'Apple') || (modeloDispositivo === '') || (!modeloDispositivo) || (modeloDispositivo === null)) {
      this.register.modelo_dispositivo = this.verifyWidthHeight(iDispositivoWidth, iDispositivoHeight, modeloDispositivo);
      this.register.modelo_dispositivo = '';
    }
  }

  verifyWidthHeight(iDeviceWidth: any, iDeviceHeight: any, modeloDeviceDispositivo: string) {
    if (iDeviceWidth === 390 && iDeviceHeight === 844) {
      return '12';
    }

    if (iDeviceWidth === 428 && iDeviceHeight === 926) {
      return '12 Pro Max';
    }

    if (iDeviceWidth === 414 && iDeviceHeight === 896) {
      return 'XS Max, XR, 11, 11 Pro Max';
    }

    if (iDeviceWidth === 375 && iDeviceHeight === 812) {
      return 'X, XS, 11 Pro';
    }

    if (iDeviceWidth === 414 && iDeviceHeight === 736) {
      return '8 Plus';
    }

    if (iDeviceWidth === 375 && iDeviceHeight === 667) {
      return '6, 6s, 7, 8';
    }

    if (iDeviceWidth === 476 && iDeviceHeight === 847) {
      return '6 Plus, 6s Plus, 7 Plus';
    }

    if (iDeviceWidth === 320 && iDeviceHeight === 568) {
      return '5, 5S, 5C, SE 1st gen';
    }

    if (iDeviceWidth === 320 && iDeviceHeight === 480) {
      return '1st gen, 3G, 3GS, 4, 4S';
    }

    if (iDeviceWidth === 744 && iDeviceHeight === 1133) {
      return 'iPad Mini (6th gen)';
    }

    if (iDeviceWidth === 810 && iDeviceHeight === 1080) {
      return 'iPad 7th gen, iPad 8th gen, iPad 9th gen';
    }

    if (iDeviceWidth === 1024 && iDeviceHeight === 1366) {
      return 'iPad Pro';
    }

    if (iDeviceWidth === 834 && iDeviceHeight === 1194) {
      return 'iPad Pro (5th gen 11)';
    }

    if (iDeviceWidth === 820 && iDeviceHeight === 1180) {
      return 'iPad Air (4th gen)';
    }

    if (iDeviceWidth === 768 && iDeviceHeight === 1024) {
      return 'iPad';
    }

    if (iDeviceWidth === 834 && iDeviceHeight === 1112) {
      return 'iPad Air (3rd gen), iPad Pro (2nd gen 10.5)';
    }

    if (iDeviceHeight <= 480) {
      return '2-3';
    }
    return modeloDeviceDispositivo;
  }

  setProgressSucess() {
    this.controlPackRegisterSuccess = this.controlPackRegisterSuccess + 1;
    this.alterProgressSuccess(this.controlPackRegisterSuccess);
  }

  go(to: string) {
    this.router.navigateByUrl(to);
    this.router.navigate(['/orgtree'], { queryParams: { orgstructure: 1 } });
  }

  restartFunction() {
    this.showPopupError = false;
    this.verifyFunction();
  }

  trackItem(eventNameRename: string = '', typeTag: string = '', pageName: string = '', tiNegocio: string): void {
    let eventName = eventNameRename;
    if (tiNegocio === 'NCC') {
      this.cn = 'AC';
    } else if (tiNegocio === 'NCC-RV') {
      this.cn = 'IT';
    }

    if (this.cn !== 'AC') {
      eventName = eventNameRename.replace('Biometria:', '');
    }

    let customgeral = {};
    if ((this.idproposta) && ((this.idproposta !== ''))) {
      customgeral = {
        idproposta: this.idproposta,
      };
    }

    if (typeTag === 'pageLoad') {

      const custom = {
        itemClicado: eventName,
        events: ['Clique'],
      };
      if (this.type_liveness === 'facetec') {
        eventName = eventName + 'FT';
        if (eventName === 'Biometria:CapturaFT') {
          eventName = 'Biometria:Comecar';
        }
      }

      window.analyticsData = {
        rule: 'pageLoad',
        page: {
          nome: this.cn + ':' + this.ambiente + ':' + this.negocio + ':' + this.prefix_pagina + ':' + eventName,
        },
        custom: customgeral,
      };

    } else {
      const custom = {
        itemClicado: eventName,
        events: ['Clique'],
      };

      if (pageName === 'TudoPronto') {
        const category = 'Clique';
        let action = 'Biometria:Dicas';
        let label = 'BTN:Biometria:Dicas:TudoPronto';

        if (this.type_liveness === 'facetec') {
          action = 'Biometria:DicasFT';
          label = 'BTN:Biometria:DicasFT:TudoPronto';
        }

        window.analyticsData = {
          transactionId: 'UA-140135526-66',
          tid: 'UA-140135526-66',
          rule: 'customLink',
          page: {
            nome: this.cn + ':' + this.ambiente + ':' + this.negocio + ':' + this.prefix_pagina + ':' + eventName,
          },
          events: {
            category,
            action,
            label,
          },
          custom: customgeral,
        };

      } else if (pageName === 'TirarFoto') {
        let action = 'Biometria:Captura';
        let label = 'BTN:Biometria:Captura:TirarFoto';

        if (this.type_liveness === 'facetec') {
          action = 'Biometria:Comecar';
          label = 'BTN:Biometria:Comecar';
        }

        window.analyticsData = {
          transactionId: 'UA-140135526-66',
          tid: 'UA-140135526-66',
          rule: 'customLink',
          page: {
            nome: this.cn + ':' + this.ambiente + ':' + this.negocio + ':' + this.prefix_pagina + ':' + eventName,
          },
          events: {
            category: 'Clique',
            action,
            label,
          },
          custom: customgeral,
        };

      } else if (pageName === 'TentarNovamente') {
        let action = 'Biometria:Captura';
        let label = 'BNT:Biometria:Captura:Tentar' + parseInt(this.tentativas, 10);
        if (this.type_liveness === 'facetec') {
          action = 'Biometria:TenteNovamenteFT';
          const tentativas = parseInt(this.tentativas, 10) + 1;
          label = `BTN:Biometria:TentarFT${tentativas}`;
        }
        window.analyticsData = {
          transactionId: 'UA-140135526-66',
          tid: 'UA-140135526-66',
          rule: 'customLink',
          page: {
            nome: this.cn + ':' + this.ambiente + ':' + this.negocio + ':' + this.prefix_pagina + ':Captura',
          },
          events: {
            category: 'Clique',
            action,
            label,
          },
          custom: customgeral,
        };

      } else if (pageName === 'DadoNa') {
        window.analyticsData = {
          transactionId: 'UA-140135526-66',
          tid: 'UA-140135526-66',
          rule: 'customLink',
          page: {
            nome: this.cn + ':' + this.ambiente + ':' + this.negocio + ':' + this.prefix_pagina + ':' + eventName,
          },
          events: {
            category: 'Erro',
            action: 'Erro Sistemico',
            label: 'Erro Generico',
          },
          custom: customgeral,
        };

      } else {
        window.analyticsData = {
          transactionId: 'UA-140135526-66',
          tid: 'UA-140135526-66',
          rule: 'customLink',
          page: {
            nome: this.cn + ':' + this.ambiente + ':' + this.negocio + ':' + this.prefix_pagina + ':' + eventName,
          },
          events: {
            category: 'Clique',
            action: 'Biometria:Captura',
            label: 'BNT:Biometria:Captura:Tentar' + this.tentativas,
          },
          custom: customgeral,
        };

      }

    }
    setTimeout(() => {
      this.takeMyPictureTimeout();
      let alteranome = window.analyticsData.page.nome;

      alteranome = alteranome.replace(':Biometria:Biometria', ':Biometria');

      window.analyticsData.page.nome = alteranome;
      if (window._frameworkDA) {
        this.logsService.handleLogs('TAGUEAMENTO',
          `Final do Tagueamento. PageName: ${pageName} - Type: ${typeTag} - ${pageName.toUpperCase()}`,
          'TAGUEAMENTO_CAPTURE_PAGE');
        if ((this.tid) && (this.tid !== false) && (this.tid !== '')) {
          window._frameworkDA.Track();
        } else if (this.verifyTid === 'initial') {

          this.verifyTid = 'finish';

          this.logsService.handleLogs('TAGUEAMENTO', `TID Não Encontrado na URL: ${window.location.href}`, 'TAGUEAMENTO_CAPTURE_PAGE');

        }
      }
    }, 100);
  }

  ignoreFrameworkDa(varDa: any) {
    return varDa;
  }

  takeMyPicture() {

    if (this.infos_os.os === 'iOS') {
      this.trackItem('Biometria:Captura', 'Clique', 'TirarFoto', this.negocio);

      this.aria_hidden_capture_mensagem_h1_show = false;
      this.aria_hidden_capture_label = true;

      this.zone.run(() => {
        this.aria_hidden_capture_mensagem_h1_show = false;
        this.aria_hidden_capture_label = true;
      });

      this.aria_hidden_capture = false;
      this.aria_hidden_capture_mensagem = true;
      this.take_my_picture = true;
      this.take_my_picture_on_camera_close = true;

      const appCaptureInstructionsBox = document.getElementById('appCaptureInstructionsBox');
      if (appCaptureInstructionsBox) { appCaptureInstructionsBox.style.padding = '10px 16px 18px 16px'; }

      this.ignore_facequality = false;
    } else {
      this.trackItem('Biometria:Captura', 'Clique', 'TirarFoto', this.negocio);

      this.aria_hidden_capture_mensagem_h1_show = false;
      this.aria_hidden_capture_label = true;

      this.zone.run(() => {
        this.aria_hidden_capture_mensagem_h1_show = false;
        this.aria_hidden_capture_label = true;
      });

      this.aria_hidden_capture = false;
      this.aria_hidden_capture_mensagem = true;
      this.take_my_picture = true;
      this.take_my_picture_on_camera_close = true;
      document.getElementById('label_capture')?.click();
      document.getElementById('label_capture')?.focus();

      const appCaptureInstructionsBox = document.getElementById('appCaptureInstructionsBox');
      if (appCaptureInstructionsBox) { appCaptureInstructionsBox.style.padding = '10px 16px 18px 16px'; }

      let verifyOs = false;
      verifyOs = this.verifyIos(this.infos_os.os);
      // Aqui
      if (verifyOs === true) {
        setTimeout(() => {
          this.takeMyPictureTimeout();
        }, 1500);
      }
      this.ignore_facequality = false;
    }
  }

  takeMyPictureTimeout() {
    document.getElementById('label_capture')?.click();
    document.getElementById('label_capture')?.click();
    document.getElementById('label_capture')?.focus();
    return;
  }

  verificaPageStatus(paginaAtual: any) {
    if ((paginaAtual === 'undefined') || (paginaAtual === '') || (paginaAtual === null)) {
      this.aria_hidden = true;
      this.aria_hidden_capture = true;
      this.aria_hidden_capture_mensagem = true;
      this.aria_hidden_success = true;
      this.code = 500;
      this.status = 'Error';
      this.message = 'A função que deseja executar não foi encontrada.';
    } else if (paginaAtual === 'consultarstatus') {
      this.verifyKey(this.key_capture);
      this.consultStatus();
    } else if (paginaAtual === 'cadastrar') {
      this.verifyKey(this.key_capture);
      this.newRegister();
    } else if (paginaAtual === 'consultaravaliacao') {
      this.verifyKey(this.key_capture);
      this.consultEvaluation();
    } else if (paginaAtual === 'verificar') {
      this.verifyKey(this.key_capture);
    } else {
      this.code = 500;
      this.status = 'Error';
      this.message = 'A função que deseja executar não foi encontrada.';
    }
  }

  // Função que verifica se a chave que esta vindo da API existe na base.
  verifyFunction() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      // dados captuardos na URL.
      this.page = params.page;
      this.key_capture = params.key_capture;
      this.cpf_idpessoa = params.cpf_idpessoa;
      this.origin_process = params.processo_origem;

      this.register.captureKey = this.key_capture;
      this.session.captureKey = this.key_capture;
      this.register.id_processo_origem = this.origin_process;

      sessionStorage.setItem('page', this.page);
      sessionStorage.setItem('key_capture', this.key_capture);
      sessionStorage.setItem('cpf_idpessoa', this.cpf_idpessoa);
      sessionStorage.setItem('origin_process', this.origin_process);

      this.status = '';
      // Verifica se a chamada esta de acordo com as 4 chamadas permitidas (Consultar Status, Cadastrar Biometria, Consultar Avaliação, Autenticação da Chave), caso não já retornamos um erro.
      this.verificaPageStatus(this.page);
    });
  }

  verificaPage(verifyFunctionPage: any) {
    return verifyFunctionPage;
  }

  // Função que valida se os campos CPF_IDPESSOA e PROCESSO DE ORIGEM são válidas.
  verifyKey(verificaChave: string) {
    if ((verificaChave !== '') && (verificaChave !== 'undefined') && (verificaChave !== undefined) && (verificaChave !== null)) {
      // Verifica se a chave existe na base de dados.
      this.fields_token.grant_type = 'client_credentials';
      this.fields_token.client_id = this.client_id;
      this.fields_token.client_secret = environment.field_client;
      this.aria_hidden = false;
      this.aria_hidden_capture = true;
      this.aria_hidden_capture_mensagem = true;
      this.aria_hidden_success = false;

      this.key_capture = this.register.captureKey;
      this.code = 200;
      this.status = 'Sucesso';
      this.message = 'Chave verificada com sucesso.';
    } else {

      this.aria_hidden = true;
      this.aria_hidden_capture = true;
      this.aria_hidden_capture_mensagem = true;
      this.aria_hidden_success = true;
      this.fields = {
        code: 401,
        status: 'Erro',
        message: 'Autenticação não realizada. Chave não encontrada.',
        key_capture: this.key_capture,
        cpf_idpessoa: this.cpf_idpessoa,
        origin_process: this.origin_process,
        redirect_url: this.redirect_url,
        captureKey: '',
      };
    }
  }

  newRegister() {
    this.captureSub = capture$.subscribe((pack: any) => this.onPackage(pack));
  }

  consultEvaluation() {
    this.conectCaronte('facequality');
  }

  consultStatus() {
    this.conectCaronte('parameters');
  }

  resultRegister(resultResultado: any, rersultCaront_type: any) {
    this.new_resultados = resultResultado;
    this.caronte_session = this.new_resultados.session;
    this.caronte_register = this.new_resultados.register;
    this.caronte_facequality = this.new_resultados.facequality;
    this.caronte_parameters = this.new_resultados.parameters;
    this.caronte_header = resultResultado.charon_session;
    this.caronte_logs = this.new_resultados.logs;

    sessionStorage.setItem('caronte_session', this.caronte_session);
    sessionStorage.setItem('caronte_register', this.caronte_register);
    sessionStorage.setItem('caronte_header', this.caronte_header);

    switch (rersultCaront_type) {
      case 'register':
        this.handleRegister();
        break;
      case 'facequality':
        this.handleFaceQuality();
        break;
      default:
        this.handleSession();
        break;
    }
  }

  conectCaronte(caront_type = '', json?) {

    if (json !== '' && json !== undefined) {
      const obj = JSON.parse(json);
      this.register.conteudo_analise_sdk.faceScan = obj.faceScan;
      this.register.conteudo_analise_sdk.auditTrailImage = obj.auditTrailImage;
      this.register.conteudo_analise_sdk.lowQualityAuditTrailImage = obj.lowQualityAuditTrailImage;
      this.register.conteudo_analise_sdk.livenessCheckSucess = obj.livenessCheckSucess;
    }

    if (caront_type !== 'session') {
      this.accesstoken = this.verifyToken(this.token, this.status);
      if ((this.accesstoken !== true) && (caront_type !== 'session')) {
        return false;
      }
    }

    this.charonService.getLinks().subscribe(async resultado => {
      await this.resultRegister(resultado, caront_type);
    }, err2 => {
      if ((err2.error.message === 'NaN') || (err2.error.message === null) || (err2.error.message === 0) || (err2.error.message === 'undefined') || (err2.error.message === undefined)) {
        this.tentativas = 0;
        sessionStorage.setItem('tentativas', this.tentativas);

      }
      document.getElementById('btn_tente_novamente_sessao')?.focus();
      this.aria_hidden = true;
      this.aria_hidden_capture = true;
      this.aria_hidden_capture_mensagem = true;
      this.aria_hidden_success = true;
      console.error('O servidor esta fora do ar. Retorne ao site e tente novamente.');
      this.token = '';
      this.status = 'Error_Session';
      document.getElementById('btn_tente_novamente_sessao')?.focus();
      this.message = 'O servidor esta fora do ar. Retorne ao site e tente novamente.';
      sessionStorage.setItem('token', '');
    });
  }

  async handleSession() {
    const { key, payload } = await this.setCript(this.session);
    this.logsService.handleLogs('INICIO DA SESSÃO', `AES - ${key}`, 'CAPTURE_PAGE');

    const callback = (res) => {
      this.resposta_sessao = res;
      this.loadingService.finishLoading();

      if (this.resposta_sessao.supplier === 'facetec') {
        this.initFacetec(
          res.keyLicense,
          res.dateExpiryKey
        );
        this.type_liveness = 'facetec';
        sessionStorage.setItem('facetec', 'facetec');
      } else {
        sessionStorage.removeItem('facetec');
      }

      this.token = this.resposta_sessao.token;
      const tokenx = this.resposta_sessao.token;
      this.decoded = jwtDecode(tokenx);

      this.redirect_url = this.decoded.redirectUrl;

      this.redirectUrlRetry = this.decoded.redirectUrlRetry;
      if ((this.decoded.consultaBureau) && ((this.decoded.consultaBureau === 'true') || (this.decoded.consultaBureau === 'false'))) {
        this.register.consulta_bureau = this.decoded.consultaBureau;
      }

      if ((this.redirectUrlRetry !== null) && (this.redirectUrlRetry !== '') && (this.redirectUrlRetry !== undefined) && (this.redirectUrlRetry !== 'undefined')) {
        let callback_retry = this.redirectUrlRetry;
        callback_retry = callback_retry.replace('https://', '');
        this.redirectUrlRetry = callback_retry.replace('http://', '');
        this.redirectUrlRetry = this.redirectUrlRetry.replace('&&', '&');
        this.redirectUrlRetry = this.redirectUrlRetry.replace('?&', '?');
        sessionStorage.setItem('redirectUrlRetry', this.redirectUrlRetry);
        sessionStorage.setItem('redirectUrlRetryPermission', this.redirectUrlRetry);
      }
      if ((this.decoded.customAttributes) && (this.decoded.customAttributes.idbiocath !== '')) {
        this.idbiocath = this.decoded.customAttributes.idbiocath;

      }

      if (this.decoded.customAttributes) {
        if (this.decoded.customAttributes.idproposta !== '') {
          this.idproposta = this.decoded.customAttributes.idproposta;
          sessionStorage.setItem('idproposta', this.idproposta);
          this.logsService.handleLogs('TAGUEAMENTO', `O valor da variavel IDPROPOSTA é: ${this.idproposta}`, 'TAGUEAMENTO_CAPTURE_PAGE');
          const aolibInitBiometria = {
            brand: 'TAPETELARANJA',
            applicationId: this.idproposta,
            save: ['Biometria:Sucesso'],
            continue: ['Biometria:Dicas'],
          };
          window.aolib.init(aolibInitBiometria).then(resp => {
            this.logsService.handleLogs('BIOCATH_SUCESSO', `Envio biocath ${JSON.stringify(resp)}`, 'BIOCATH_CAPTURE_PAGE');
          });

        }
      } else {
        this.idproposta = '88664422';
      }

      this.trackEventGA4('dicas', 'screen_view');
      this.trackItem('Biometria:Dicas', 'pageLoad', 'Dicas', this.negocio);
      const newStr = this.redirect_url.split('?');
      let callback_end = this.redirect_url;

      const last_caracter = this.redirect_url.substr(-1, 1);
      if (last_caracter === '/') {
        this.redirect_url = this.redirect_url.substring(0, this.redirect_url.length - 1);
      }
      if (newStr.length > 1) {
        const new_init = newStr[0];
        delete newStr[0];
        const join_callback = newStr.join('&');
        callback_end = new_init + '?' + join_callback;
      } else {
        this.redirect_url = this.redirect_url + '?';
        callback_end = this.redirect_url;
      }

      callback_end = callback_end.replace('https://', '');
      this.redirect_url = callback_end.replace('http://', '');
      this.redirect_url = this.redirect_url.replace('&&', '&');
      this.redirect_url = this.redirect_url.replace('?&', '?');

      this.verifyLinkError(this.decoded.redirectUrlError, this.redirect_url);

      if (this.redirect_error_url.includes('?') === false) {
        this.redirect_error_url = this.redirect_error_url + '?';
      }

      this.client_id = this.decoded.sub;

      sessionStorage.setItem('client_id', this.client_id);
      sessionStorage.setItem('token', this.token);

      localStorage.setItem('redirect_url', this.redirect_url);
      document.getElementById('title_tips')?.focus();
      if (this.infos_os.os === 'iOS') {
        document.getElementById('title_tips')?.focus();
        document.getElementById('title_tips')?.click();
      }
      setTimeout(() => { }, 5000);
      localStorage.setItem('origin_process', this.decoded.originProcess);
    };

    const error = (errosession) => {
      this.trackEventGA4('dicas', 'visualization', 'modal:expirado');
      if ((errosession.error) && (errosession.error !== undefined) && (errosession.error.message !== undefined) && (errosession.error.message) && (errosession.error.message !== 'feature CREATE_SESSION failed')) {
        this.tentativas = 0;
        sessionStorage.setItem('tentativas', this.tentativas);

        this.verifyErrorBlacklist(errosession.error?.message);
      }
      const sessionRedirectUrlRetry = sessionStorage.getItem('redirectUrlRetry');

      if ((sessionRedirectUrlRetry !== null) && (sessionRedirectUrlRetry !== '') && (sessionRedirectUrlRetry !== undefined) && (sessionRedirectUrlRetry !== 'undefined')) {

        sessionStorage.setItem('redirectUrlRetry', '');
        window.document.location.href = 'https://' + sessionRedirectUrlRetry;
      }
      this.verifyErrorBlacklist(errosession.error?.message);
      this.aria_hidden = true;
      this.aria_hidden_capture = true;
      this.aria_hidden_capture_mensagem = true;
      this.aria_hidden_success = true;
      console.error('Sua sessão expirou ou não foi encontrada. Retorne ao site e tente novamente.');
      this.token = '';
      this.status = 'Error_Session';
      this.message = 'Sua sessão expirou ou não foi encontrada. Retorne ao site e tente novamente.';
      sessionStorage.setItem('token', '');
      setTimeout(() => {
        this.zone.run(() => {
          document.getElementById('btn_tente_novamente_sessao')?.focus();
        });
      }, 2000);
    };

    const params = {
      key,
      token: sessionStorage.getItem('token'),
      apikey: this.charonService.randomText(6),
      session: this.caronte_header,
      correlationID: this.charonService.generateUUID(),
      type_liveness: this.type_liveness
    }

    this.httpService.postData(this.caronte_session, payload, params, callback, error);
  }

  verifyErrorBlacklist(messageError: any) {
    if (messageError === 'Id Pessoa incorreto') {
      this.redirectUrl('no_aproved');
    }
    return true;
  }

  verifyLinkError(linkError: string, linkRedirect: string) {
    let new_link = linkError;
    let new_linkRedirect = linkRedirect;
    if ((linkError === '') || ((!linkError)) || ((linkError === 'undefined'))) {
      new_link = new_linkRedirect;
      const newStrErro = new_linkRedirect.split('?');
      let callback_end_erro = new_linkRedirect;
      const last_caracter = new_linkRedirect.substr(-1, 1);
      if (last_caracter === '/') {
        new_linkRedirect = new_linkRedirect.substring(0, new_linkRedirect.length - 1);
      }
      if (newStrErro.length > 1) {
        const new_init_erro = newStrErro[0];
        delete newStrErro[0];
        const join_callback_erro = newStrErro.join('&');
        callback_end_erro = new_init_erro + '?' + join_callback_erro;
      } else {
        new_linkRedirect = new_linkRedirect + '?';
        callback_end_erro = new_linkRedirect;
      }

      callback_end_erro = callback_end_erro.replace('https://', '');
      callback_end_erro = callback_end_erro.replace('http://', '');
      callback_end_erro = callback_end_erro.replace('&&', '&');
      callback_end_erro = callback_end_erro.replace('?&', '?');
      new_link = callback_end_erro;
      sessionStorage.setItem('redirectUrlError', new_link);
    }
    this.redirect_error_url = new_link;
  }

  async handleFaceQuality() {

    if ((this.infos_os.os === 'iOS') && ((this.tentativas === 'NaN') || (this.tentativas === null) || (this.tentativas === 0))) {
      this.tentativas = 0;
      sessionStorage.setItem('tentativas', this.tentativas);
      this.ignore_facequality = false;
    } else if ((this.infos_os.os === 'iOS') && ((this.tentativas === '1') || (this.tentativas === 1))) {
      this.ignore_facequality = false;
    } else if ((this.infos_os.os === 'iOS') && ((this.tentativas === '2') || (this.tentativas === 2))) {
      this.ignore_facequality = false;
    } else if ((this.infos_os.os === 'iOS') && ((this.tentativas === '3') || (this.tentativas === 3))) {
      this.ignore_facequality = false;
    }

    if (this.ignore_facequality === true) {
      return;
    }

    if ((this.tentativas === 'NaN') || (this.tentativas === null) || (this.tentativas === 0)) {
      this.tentativas = 0;
      sessionStorage.setItem('tentativas', this.tentativas);
    }
    this.token = sessionStorage.getItem('token');

    document.getElementById('label_capture')?.focus();

    const modal = document.getElementById('label_capture');
    modal?.focus();

    this.classProgressRegister = '';
    this.facequality.token = this.token;

    const { key, payload } = await this.setCript(this.facequality);
    sessionStorage.setItem('captureKey', this.key_capture);

    const afterCallback = () => {
      document.getElementById('label_capture')?.focus();
    }

    const error = () => {
      document.getElementById('label_capture')?.focus();
      this.statusPack = false;
      this.handleFaceQualityErro();
      setTimeout(() => {
        this.controlPackRegisterSuccess = 0;
      }, 35000);
    }

    const params = {
      key,
      token: sessionStorage.getItem('token'),
      apikey: this.charonService.randomText(6),
      session: this.caronte_header,
      correlationID: this.charonService.generateUUID()
    }

    this.httpService.postData(
      this.caronte_facequality,
      payload,
      params,
      (res) => this.handleFaceQualityRegister(res, this.controlPackSuccess),
      error,
      afterCallback
    );
  }

  handleFaceQualityRegister(faceQualityResponse: any, faceQualityControlPackSuccess: any) {
    this.error_facequality_conection = 'false';
    this.resposta_facequality = faceQualityResponse;
    const now = new Date();
    const now_milliseconds = now.getMilliseconds();
    this.myFormattedDate = this.pipe.transform(now, 'yyyy-MM-dd HH:mm:ss');
    this.handleFaceQualityRegisterControlPack(faceQualityControlPackSuccess);

    if ((this.resposta_facequality.feedback !== '') && (this.resposta_facequality.feedback !== 'undefined') && (this.resposta_facequality.feedback !== undefined) && (this.resposta_facequality.feedback !== null)) {
      const erros_facequality = this.resposta_facequality.feedback;
      let contador_erros = 0;
      for (let index = 0; index < erros_facequality.length; index++) {
        this.zone.run(() => {
          if (erros_facequality[0] === 'CORRUPTED_PACKAGE_1') {
            this.error_message_front = 'enquadre seu rosto no círculo';
          } else if (erros_facequality[0] === 'CORRUPTED_PACKAGE_2') {
            this.error_message_front = 'enquadre seu rosto no círculo';
          } else if (erros_facequality[0] === 'CORRUPTED_PACKAGE_3') {
            this.error_message_front = 'enquadre seu rosto no círculo';
          } else if (erros_facequality[0] === 'DELAYED_FRAME') {
            this.error_message_front = 'enquadre seu rosto no círculo';
          } else if (erros_facequality[0] === 'CAMERA_INJECTION') {
            this.error_message_front = 'enquadre seu rosto no círculo';
          } else if (erros_facequality[0] === 'REPLAY_ATTACK') {
            this.error_message_front = 'enquadre seu rosto no círculo';
          } else {
            this.error_message_front = erros_facequality[0];
          }
          const message_erro = {
            datetime_mensagem: this.myFormattedDate.toString() + '.' + now_milliseconds,
            mensagem_apresentada: this.error_message_front,
          };
          this.error_facequality.push(message_erro);
          contador_erros = contador_erros + 1;
        });
      }
      document.getElementById('label_capture')?.focus();
    }

    this.handleFaceQualityRegisterMessage(this.error_message_front, this.take_my_picture);
    document.getElementById('label_capture')?.focus();
    this.code = 200;
    this.status = 'Sucesso';
    this.message = 'Consulta do FaceQuality realizada com sucesso.';

    this.handleFaceQualityRegisterApproved(this.resposta_facequality.approved, now_milliseconds);
    this.statusPack = false;
  }

  handleFaceQualityRegisterControlPack(faceQualityResgiterControlPackSuccess: any) {
    if (faceQualityResgiterControlPackSuccess >= 4) {
      this.resposta_facequality.approved = true;
    }
  }

  handleFaceQualityRegisterMessage(faceQualityRegisterMessage: any, takeMyPicture: boolean) {
    if (faceQualityRegisterMessage === 'não identificamos seu rosto') {
      this.classProgress = '';
    } else if ((faceQualityRegisterMessage === 'Capturando Imagem...') || (faceQualityRegisterMessage === 'tudo certo!')) {
      this.classProgress = '100';
    } else if (takeMyPicture === false) {
      this.classProgress = '';
    } else {
      this.classProgress = '50';
    }
  }

  handleFaceQualityRegisterApproved(faceQualityRegisterApproved: boolean, faceQualityRegisterNowMilliseconds: any) {

    if ((faceQualityRegisterApproved) && (faceQualityRegisterApproved !== null) && (faceQualityRegisterApproved === true)) {

      if ((this.error_register === true) && (this.tentativas === 3)) {
        this.redirectUrl('no_frame');
        return;
      }
      this.error_message_front = 'Capturando Imagem...';
      document.getElementById('label_capture')?.focus();
      this.statusPack = false;
      this.imageBack = true;
      this.controlPackSuccess = this.controlPackSuccess + 1;
      this.controlPackSuccess = 4;
      if (this.facequality.package) {
        this.frame_temporary = { data: this.facequality.package, timestamp: 1539642745527, tags: [] };
      }
      this.handleFaceQualityRegisterControlPackSuccess(this.controlPackSuccess, faceQualityRegisterNowMilliseconds);
      document.getElementById('label_capture')?.focus();
      this.alterProgress(this.controlPackSuccess);
      if (this.infos_os.os === 'iOS') {
        this.showPopup = Popup.CAPTURE;
        this.status = 'Sucesso';
      }
    }
  }

  handleFaceQualityRegisterControlPackSuccess(faceQualityRegisterControlPackSuccess: any, faceQualityRegisterControlPackNowMilliseconds: any) {
    if (faceQualityRegisterControlPackSuccess === 1) {
      this.register_timeout.mensagem_orientacao = this.error_facequality;
      this.register_timeout.datetime_operacao = this.myFormattedDate.toString() + '.' + faceQualityRegisterControlPackNowMilliseconds;
    }
    if (this.infos_os.os === 'iOS') {
      document.getElementById('label_register_success')?.focus();
      document.getElementById('label_register_success')?.click();
    }
    if (faceQualityRegisterControlPackSuccess === 4) {
      this.frame4 = this.facequality.package;
      this.register.mensagem_orientacao = this.error_facequality;
      this.register.datetime_operacao = this.myFormattedDate.toString() + '.' + faceQualityRegisterControlPackNowMilliseconds;

      this.ignore_new_try = true;
      this.conectCaronte('register');

      document.getElementById('label_register_success')?.focus();

      const output = document.getElementById('videoBox');
      if (output) { output.style.display = 'none'; }

      const outputappAriaHiddenBox = document.getElementById('appAriaHiddenBox');
      if (outputappAriaHiddenBox) { outputappAriaHiddenBox.style.display = 'none'; }

      const outputappCaptureInstructionsBox = document.getElementById('appCaptureInstructionsBox');
      if (outputappCaptureInstructionsBox) { outputappCaptureInstructionsBox.style.display = 'none'; }

      this.aria_hidden = true;
      this.aria_hidden_capture = true;
      this.aria_hidden_capture_mensagem = true;
      this.aria_hidden_success = false;
      this.display_class1 = 'box-none';
      this.display_class2 = '';

      this.zone.run(() => {
        this.display_none_progress = 'display_none_progress';
        this.display_none = 'display_none';
        this.display_none_percentual = 'display_none';
        this.error_message_front = 'tudo certo!';
        this.classProgress = '101';
      });
      this.controlPackRegisterSuccess = 0;
      setInterval(() => {
        this.setProgressSucess();
      }, 4000);

      if (this.infos_os.os === 'iOS') {
        setTimeout(() => {
          document.getElementById('label_register_success')?.focus();
          document.getElementById('label_register_success')?.click();
        }, 4100);
      }

    }
  }

  handleFaceQualityErro() {
    this.error_facequality_conection = 'false';
    const now = new Date();
    const now_milliseconds = now.getMilliseconds();
    this.myFormattedDate = this.pipe.transform(now, 'yyyy-MM-dd HH:mm:ss');
    const erros_facequality = 'Capturando imagem...';
    this.error_message_front = 'Capturando imagem...';
    const message_erro = {
      datetime_mensagem: this.myFormattedDate.toString() + '.' + now_milliseconds,
      mensagem_apresentada: erros_facequality,
    };
    this.error_facequality.push(message_erro);

    document.getElementById('label_capture')?.focus();
    this.code = 200;
    this.status = 'Sucesso';
    this.message = 'Capturando imagem...';
  }

  handleFaceQualityErroTimeout() {
    this.error_facequality_conection = 'true';
    document.getElementById('label_capture')?.focus();

    const modalcapture = document.getElementById('label_capture');
    modalcapture?.focus();

    this.classProgressRegister = '';

    document.getElementById('label_capture')?.focus();

    this.statusPack = false;

    this.zone.run(() => {
      this.error_message_front = 'Capturando imagem...';
    });

    document.getElementById('label_capture')?.focus();
    this.statusPack = false;
  }

  async handleRegister() {
    if (this.control_timeout_register === true) {
      return;
    }

    sessionStorage.setItem('handleRegisterTimeout', 'false');
    sessionStorage.setItem('handleRegister', 'true');

    this.control_register = true;
    this.closeCamera();
    this.handleRegisterComplement();
  }

  async handleRegisterComplement() {
    this.ignore_facequality = true;
    this.register.documentos_cliente.documento = this.client_id;
    this.register.codigo_ipv4 = this.address;
    if ((this.addressipv6) && (this.addressipv6 !== '')) {
      this.register.codigo_ipv6 = this.addressipv6;
      this.register_timeout.codigo_ipv6 = this.addressipv6;
    }

    this.token = sessionStorage.getItem('token');

    const { key, payload } = await this.setCript(this.register);
    if (this.type_liveness !== 'facetec') {
      this.register.id_sessao = this.charonService.generateUUID();
    }

    this.aria_hidden_capture_mensagem = true;
    sessionStorage.setItem('captureKey', this.key_capture);
    this.sessionTimeout = true;

    this.zone.run(() => {
      this.status = 'Awaiting_Registration';
    });
    this.trackEventGA4('envio-foto', 'screen_view');
    this.trackItem('Biometria:EnvioFoto', 'pageLoad', 'EnvioFoto', this.negocio);

    const output = document.getElementById('videoBox');
    if (output) { output.style.display = 'none'; }

    const outputappAriaHiddenBox = document.getElementById('appAriaHiddenBox');
    if (outputappAriaHiddenBox) { outputappAriaHiddenBox.style.display = 'none'; }

    const outputappCaptureInstructionsBox = document.getElementById('appCaptureInstructionsBox');
    if (outputappCaptureInstructionsBox) { outputappCaptureInstructionsBox.style.display = 'none'; }

    if (this.block_register === false) {
      this.block_register = true;
    } else {
      return;
    }
    if ((this.infos_os.os === 'iOS') && (this.type_liveness === 'facetec')) {
      document.getElementById('label_register_success')?.focus();
      document.getElementById('label_register_success')?.setAttribute('aria-label', this.message_sucess_register[0]);
      document.getElementById('label_register_success')?.focus();
    }
    if (this.register.conteudo_analise_sdk.faceScan !== '' && this.register.conteudo_analise_sdk.faceScan !== undefined && this.register.conteudo_analise_sdk.faceScan !== null
      && (this.showPopup === Popup.INITIAL || this.showPopup === Popup.SECONDARY || this.showPopup === Popup.CAPTURE || this.showPopup === undefined)) {
      this.zone.run(() => {

        this.display_class2 = '';
        this.showPopup = Popup.CAPTURE;
      });

      // Reiniciar a barra de carregamento do load de animação
      this.controlPackRegisterSuccess = 0;
      this.classProgressRegister = '0';
      this.statusBar = setInterval(() => {
        this.setProgressSucess();
        if (this.controlPackRegisterSuccess === 4) {
          clearInterval(this.statusBar);
        }
        const rand = Math.floor(Math.random() * 5);
        this.zone.run(() => {
          this.register_success = this.message_sucess_register[rand];
          document.getElementById('label_register_success')?.focus();
          document.getElementById('label_register_success')?.setAttribute('aria-label', this.message_sucess_register[rand]);
        });
      }, 2000);
    }
    this.logsService.handleLogs('ENVIO_CADASTRO', 'Iniciando LOG de cadastro.', 'REGISTRO_CAPTURE_PAGE');

    const callback = (res) => {
      localStorage.setItem('scanResultBlob', res?.scan_result_blob)
      this.logsService.handleLogs('CADASTRO_SUCESSO', 'Retorno da chamada de cadastro.', 'REGISTRO_CAPTURE_PAGE');
      this.logsService.handleLogs('CADASTRO_RETORNADO', `Resposta do register. ${JSON.stringify(res)}`, 'REGISTRO_CAPTURE_PAGE');
      this.handleRegisterComplementRegister(res);
      this.block_register = false;
    }

    const error = () => {

      this.logsService.handleLogs('CADASTRO_ERRO', 'Retorno com ERRO da chamada de cadastro.', 'REGISTRO_CAPTURE_PAGE');
      this.ignore_new_try = false;
      this.error_liveness_qualidade = true;
      this.ignore_new_try = false;
      const tentativas = sessionStorage.getItem('tentativas');
      this.tentativas = tentativas;
      this.tela_dividida = true;
      this.verifyRegisterTrativaTentativa(this.tentativas, this.tela_dividida, this.infos_os.os, false, this.error_liveness_qualidade);
      sessionStorage.setItem('tentativas', this.tentativas);
      this.block_register = false;
    }

    const params = {
      key,
      token: sessionStorage.getItem('token'),
      apikey: this.charonService.randomText(6),
      session: this.caronte_header,
      correlationID: this.charonService.generateUUID(),
      type_liveness: this.type_liveness
    }

    this.httpService.postData(
      this.caronte_register,
      payload,
      params,
      callback,
      error
    );
  }

  async handleRegisterComplementAposTimeout() {
    this.ignore_facequality = true;
    this.register.documentos_cliente.documento = this.client_id;
    this.token = sessionStorage.getItem('token');

    const { key, payload } = await this.setCript(this.register);
    this.aria_hidden_capture_mensagem = true;
    sessionStorage.setItem('captureKey', this.key_capture);
    this.sessionTimeout = true;

    this.zone.run(() => {
      this.status = 'Awaiting_Registration';
    });

    this.trackEventGA4('envio-foto', 'screen_view');
    this.trackItem('Biometria:EnvioFoto', 'pageLoad', 'EnvioFoto', this.negocio);

    const error = () => {
      this.trackItem('Biometria:Captura', 'Clique', 'DadoNa', this.negocio);
      this.redirectUrl('no_aproved');
    }

    const params = {
      key,
      token: sessionStorage.getItem('token'),
      apikey: this.charonService.randomText(6),
      session: this.caronte_header,
      correlationID: this.charonService.generateUUID(),
    }

    this.httpService.postData(
      this.caronte_register,
      payload,
      params,
      () => this.redirectUrl('no_aproved'),
      error
    );
  }

  handleRegisterComplementTimeout(imageBackTimeout: boolean) {
    if (imageBackTimeout === false) {
      this.trackItem('Biometria:Captura', 'Clique', 'DadoNa', this.negocio);
    }
  }

  handleRegisterComplementRegister(respotaRegister: any) {
    this.resposta_register = respotaRegister;
    this.display_class1 = 'box-none';
    this.display_class2 = '';
    this.verifyRegister(this.resposta_register.capturado, this.resposta_register.nova_tentativa, this.showPopup, this.tentativas, this.infos_os.os, this.redirect_url);
  }

  verifyRegister(resposta_register_capturado: boolean, resposta_register_nova_tentativa: boolean, showPopupRegister: any, tentativasRegister: any, infosOsRegister: string, redirectUrlVerify: string) {
    this.redirect_url = redirectUrlVerify;
    if ((resposta_register_capturado) && (resposta_register_capturado === true)) {
      this.error_register = false;
      if (this.infos_os.os === 'iOS') {
        this.showPopup = Popup.CAPTURE;
        this.status = 'Sucesso';
      }
      this.messageRetorno = '&message=capturado_com_sucesso';

      this.capturado = true;
      this.createUrl(this.fields);
      this.display_class1 = '';
      this.display_class2 = 'box-none';
      this.showPopup = Popup.SUCCESS;
      sessionStorage.setItem('tentativas', '0');
      this.control_register = false;

      this.zone.run(() => {
        this.remove_beforeunload = false;
      });
      this.block_register = false;
      this.removeOnbeforeunload();
      this.removeOnbeforeunload();
      const url_sucesso = this.baseurl + '/capture/success?' + this.link_tag;
      let url_final_session = localStorage.getItem('redirect_url');
      if ((url_final_session === '') || (url_final_session === null) || (!url_final_session)) {
        url_final_session = this.redirect_url;
        localStorage.setItem('redirect_url', url_final_session);
      }

      this.logsService.handleLogs(
        'CAPTURE_SUCCESS',
        `O usuário realizou a captura com sucesso. A URL de sucesso é a ${url_sucesso} e a URL de retorno ao canal é a ${this.redirect_url}`,
        'REGISTRO_CAPTURE_PAGE');
      this.trackEventGA4('sucesso', 'screen_view');
      this.trackItem('Biometria:Sucesso', 'pageLoad', 'Sucesso', this.negocio);

      if (this.type_liveness === 'facetec') {
        this.zone.run(() => {
          this.capturado = true;
        });
      } else {
        setTimeout(() => {
          window.document.location.href = url_sucesso;
        }, 1500);
      }
    } else if ((resposta_register_nova_tentativa) && (resposta_register_nova_tentativa === true)) {
      this.error_liveness_qualidade = true;
      this.ignore_new_try = false;
      // aprovado_match
      const tentativas = sessionStorage.getItem('tentativas');
      this.tentativas = tentativas;
      this.tela_dividida = true;
      // infosOsRegister
      this.verifyRegisterTrativaTentativa(this.tentativas, this.tela_dividida, infosOsRegister, resposta_register_capturado, this.error_liveness_qualidade);
      sessionStorage.setItem('tentativas', this.tentativas);
    } else {
      this.error_liveness_qualidade = true;
      this.ignore_new_try = false;
      // aprovado_match
      const tentativas = sessionStorage.getItem('tentativas');
      this.tentativas = tentativas;
      this.tela_dividida = true;
      // infosOsRegister
      this.verifyRegisterTrativaTentativa(this.tentativas, this.tela_dividida, infosOsRegister, resposta_register_capturado, this.error_liveness_qualidade);
      sessionStorage.setItem('tentativas', this.tentativas);
    }

    return false;
  }

  verifyRegisterTrativaTentativa(tratativaTentativa: any, tratativaTentativaTelaDividida: boolean, infosOsRegister: any, resposta_register_capturado: boolean, error_liveness_qualidade: boolean) {
    this.trackEventGA4('captura', 'visualization', 'modal:tente-novamente');
    this.trackItem('Biometria:TenteNovamente', 'pageLoad', 'TirarFoto', this.negocio);
    if ((tratativaTentativa === 'NaN') || (tratativaTentativa === null) || (tratativaTentativa === 0)) {
      this.tentativas = 0;
      this.tentativas = parseInt(this.tentativas, 10) + 1;
      sessionStorage.setItem('tentativas', this.tentativas);
      this.showPopup = Popup.ERROR_NEW_TRY;
      this.trackEventGA4('captura', 'click', 'btn:tentar1');
      this.trackItem('Biometria:TenteNovamente', 'Clique', 'TentarNovamente 1', this.negocio);
    } else if (parseInt(tratativaTentativa, 10) >= 3) {
      this.tela_dividida = false;
      sessionStorage.setItem('tentativas', '0');
      this.tentativas = 0;
      if (resposta_register_capturado === true) {
        this.control_register = false;
        this.removeOnbeforeunload();
        this.trackEventGA4('sucesso', 'screen_view');
        this.trackItem('Biometria:Sucesso', 'pageLoad', 'Sucesso', this.negocio);

        const url_sucesso = this.baseurl + '/capture/success?' + this.link_tag;
        let url_final_session = localStorage.getItem('redirect_url');
        if ((url_final_session === '') || (url_final_session === null) || (!url_final_session)) {
          url_final_session = this.redirect_url;
          localStorage.setItem('redirect_url', url_final_session);
        }
        this.logsService.handleLogs(
          'CAPTURE_SUCCESS',
          `O usuário realizou a captura com sucesso. A URL de sucesso é a ${url_sucesso} e a URL de retorno ao canal é a ${this.redirect_url}`,
          'REGISTRO_CAPTURE_PAGE');
        setTimeout(() => {
          window.document.location.href = url_sucesso;
        }, 1500);
      } else {

        if (this.type_liveness !== 'facetec') {
          this.trackEventGA4('captura', 'click', 'btn:tentar3');
          this.trackItem('Biometria:TenteNovamente', 'Clique', 'TentarNovamente 3', this.negocio);
        }
        if (error_liveness_qualidade === false) {
          this.redirectUrl('no_aproved');
        } else {
          this.redirectUrl('no_aproved_quality');
        }
      }

    } else if (parseInt(tratativaTentativa, 10) === 2) {
      this.tentativas = parseInt(tratativaTentativa, 10) + 1;
      sessionStorage.setItem('tentativas', this.tentativas);
      this.tela_dividida = false;
      if (this.type_liveness !== 'facetec') {
        this.trackEventGA4('captura', 'click', 'btn:tentar2');
        this.trackItem('Biometria:TenteNovamente', 'Clique', 'TentarNovamente 2', this.negocio);
      }
    } else {

      this.showPopup = Popup.ERROR_NEW_TRY;

      this.tentativas = parseInt(tratativaTentativa, 10) + 1;
      sessionStorage.setItem('tentativas', this.tentativas);
    }

    sessionStorage.setItem('tentativas', this.tentativas);

    if (tratativaTentativaTelaDividida === true) {
      this.ignore_facequality = false;
      document.getElementById('btn_tente_novamente')?.focus();

    }
    if ((tratativaTentativa === 'NaN') || (tratativaTentativa === null) || (tratativaTentativa === 0)) {
      this.tentativas = 1;;

      const outputcw = document.getElementById('videoBox');
      if (outputcw) { outputcw.style.display = 'flex'; }
      if ((infosOsRegister === 'iOS') && (outputcw)) {
        outputcw.style.display = 'none';
      }
      const outputcwappAriaHiddenBox = document.getElementById('appAriaHiddenBox');
      if (outputcwappAriaHiddenBox) { outputcwappAriaHiddenBox.style.display = 'block'; }
      const outputcwappCaptureInstructionsBox = document.getElementById('appCaptureInstructionsBox');
      if (outputcwappCaptureInstructionsBox) { outputcwappCaptureInstructionsBox.style.display = 'block'; }
    } else if (parseInt(tratativaTentativa, 10) >= 3) {

      sessionStorage.setItem('tentativas', '0');
      this.verifyRegisterCapturado(resposta_register_capturado);
    } else if (tratativaTentativaTelaDividida === true) {
      const outputcw = document.getElementById('videoBox');
      if (outputcw) { outputcw.style.display = 'flex'; }
      if ((infosOsRegister === 'iOS') && (outputcw)) {
        outputcw.style.display = 'none';
      }
      const outputcwappAriaHiddenBox = document.getElementById('appAriaHiddenBox');
      if (outputcwappAriaHiddenBox) { outputcwappAriaHiddenBox.style.display = 'block'; }

      const outputcwappCaptureInstructionsBox = document.getElementById('appCaptureInstructionsBox');
      if (outputcwappCaptureInstructionsBox) { outputcwappCaptureInstructionsBox.style.display = 'block'; }
      if (tratativaTentativaTelaDividida === true) {
        this.zone.run(() => {
          this.aria_hidden = true;
          this.aria_hidden_capture = true;
          this.aria_hidden_capture_mensagem = true;
          this.aria_hidden_success = true;
          this.showPopup = Popup.ERROR_NEW_TRY;
          if (this.type_liveness === 'facetec' && error_liveness_qualidade === true && this.tentativas < 3) {
            this.isError = this.errorcallsService.fields.showPopupError = true;
            this.aria_hidden = false;
            this.aria_hidden_success = false;
            this.showPopup = Popup.INITIAL;
          }
          this.display_class2 = 'box-none';
          this.display_class1 = '';
          this.classProgress = '';
          this.controlTimeout = false;
          this.display_none = '';
          this.display_none_percentual = '';
          this.display_none_progress = 'display_none';
          this.error_message_front = 'centralize o rosto na moldura';
        });
      }
      if ((this.tentativas >= 3) && (error_liveness_qualidade === false)) {
        this.redirectUrl('no_aproved');
      } else if (this.tentativas >= 3) {
        this.redirectUrl('no_aproved_quality');
      }
    }
  }

  verifyRegisterCapturado(verifyRegisterBoolean: boolean) {
    if (verifyRegisterBoolean === true) {
      this.control_register = false;
      this.removeOnbeforeunload();
      this.trackEventGA4('sucesso', 'screen_view');
      this.trackItem('Biometria:Sucesso', 'pageLoad', 'Sucesso', this.negocio);

      const url_sucesso = this.baseurl + '/capture/success?' + this.link_tag;
      let url_final_session = localStorage.getItem('redirect_url');
      if ((url_final_session === '') || (url_final_session === null) || (!url_final_session)) {
        url_final_session = this.redirect_url;
        localStorage.setItem('redirect_url', url_final_session);
      }

      this.logsService.handleLogs(
        'CAPTURE_SUCCESS',
        `O usuário realizou a captura com sucesso. A URL de sucesso é a ${url_sucesso} e a URL de retorno ao canal é a ${this.redirect_url}`,
        'REGISTRO_CAPTURE_PAGE');

      setTimeout(() => {
        window.document.location.href = url_sucesso;
      }, 1500);
    } else {
      this.redirectUrl('no_aproved');
    }
  }

  async handleRegisterTimeout(controlRegisterTimeout: boolean, redirectTentativa: any) {
    if (controlRegisterTimeout === true) {
      return;
    }

    sessionStorage.setItem('handleRegister', 'false');
    sessionStorage.setItem('handleRegisterTimeout', 'true');
    this.control_timeout_register = true;
    this.closeCamera();
    this.handleRegisterTimeoutInibClose();
    if (redirectTentativa >= 3) {
      this.redirectUrl('no_frame');
    }
  }

  async handleRegisterTimeoutInibClose() {
    this.classProgress = '101';
    document.getElementById('label_register_success')?.focus();

    const outputcw = document.getElementById('videoBox');
    if (outputcw) { outputcw.style.display = 'none'; }

    const outputcwappAriaHiddenBox = document.getElementById('appAriaHiddenBox');
    if (outputcwappAriaHiddenBox) { outputcwappAriaHiddenBox.style.display = 'none'; }

    const outputcwappCaptureInstructionsBox = document.getElementById('appCaptureInstructionsBox');
    if (outputcwappCaptureInstructionsBox) { outputcwappCaptureInstructionsBox.style.display = 'none'; }

    this.aria_hidden = true;
    this.aria_hidden_capture = true;
    this.aria_hidden_capture_mensagem = true;
    this.aria_hidden_success = false;

    this.display_class1 = 'box-none';
    this.display_class2 = '';
    this.zone.run(() => {
      this.display_none_progress = 'display_none_progress';
      this.display_none = 'display_none';
      this.display_none_percentual = 'display_none';
      this.classProgressRegister = '';
    });

    this.controlPackRegisterSuccess = 0;
    setInterval(() => {
      this.setProgressSucess();
    }, 4000);

    this.aria_hidden = true;
    this.aria_hidden_capture = true;
    this.aria_hidden_capture_mensagem = true;
    this.aria_hidden_success = false;

    this.display_class1 = 'box-none';
    this.display_class2 = '';
    this.zone.run(() => {
      this.display_none = 'display_none';
      this.display_none_percentual = 'display_none';
      this.display_none_progress = 'display_none_progress';
      this.classProgressRegister = '';
    });

    this.register_timeout.documentos_cliente.documento = this.client_id;

    const now = new Date();
    const now_milliseconds = now.getMilliseconds();
    const myFormattedDate = this.pipe.transform(now, 'yyyy-MM-dd HH:mm:ss');
    this.register_timeout.datetime_operacao = myFormattedDate?.toString() + '.' + now_milliseconds;

    this.classProgressRegister = '';

    this.ignore_facequality = true;
    this.sessionTimeout = true;
    this.token = sessionStorage.getItem('token');
    sessionStorage.setItem('captureKey', this.key_capture);

    this.tela_dividida = true;
    this.control_timeout_register = false;
    this.ignore_facequality = false;

    const tentativas = sessionStorage.getItem('tentativas');
    this.tentativas = tentativas;
    this.verifyTentativaTimeout(this.tentativas);

    sessionStorage.setItem('tentativas', this.tentativas);
    this.telaDividida(this.tela_dividida);
    this.telaDivididaTimeout(this.tentativas);
  }

  verifyTentativaTimeout(tentativaTimeout: any) {
    if ((tentativaTimeout === 'NaN') || (tentativaTimeout === null) || (tentativaTimeout === 0) || (tentativaTimeout === '0')) {
      this.tentativas = 0;
      this.showPopup = Popup.ERROR_NEW_TRY;
      sessionStorage.setItem('tentativas', this.tentativas);
    } else if (parseInt(tentativaTimeout, 10) >= 3) {
      this.tela_dividida = false;
      sessionStorage.setItem('tentativas', '0');
      this.verifyTentativaTimeoutCapturado(false);
    } else if (parseInt(tentativaTimeout, 10) === 2) {
      this.tela_dividida = false;
      sessionStorage.setItem('tentativas', this.tentativas);
      this.redirectUrl('no_aproved');
    } else {
      this.verifyTentativaTimeoutElseCapturado(this.tentativas);
    }
  }

  verifyTentativaTimeoutCapturado(verifyTentativaCapturado: boolean) {
    if (verifyTentativaCapturado === true) {
      this.control_register = false;
      this.removeOnbeforeunload();
      this.trackEventGA4('sucesso', 'screen-view');
      this.trackItem('Biometria:Sucesso', 'pageLoad', 'Sucesso', this.negocio);

      const url_sucesso = this.baseurl + '/capture/success?' + this.link_tag;
      let url_final_session = localStorage.getItem('redirect_url');
      if ((url_final_session === '') || (url_final_session === null) || (!url_final_session)) {
        url_final_session = this.redirect_url;
        localStorage.setItem('redirect_url', url_final_session);
      }
      this.logsService.handleLogs(
        'CAPTURE_SUCCESS',
        `O usuário realizou a captura com sucesso. A URL de sucesso é a ${url_sucesso} e a URL de retorno ao canal é a ${this.redirect_url}`,
        'REGISTRO_CAPTURE_PAGE');
      setTimeout(() => {
        window.document.location.href = url_sucesso;
      }, 1500);
    } else {
      this.redirectUrl('no_aproved');
    }
  }

  verifyTentativaTimeoutElseCapturado(verifyTentativaCapturadoInt: any) {
    if (parseInt(verifyTentativaCapturadoInt, 10) >= 3) {
      sessionStorage.setItem('tentativas', '0');
      this.redirectUrl('no_aproved');
    } else {
      sessionStorage.setItem('tentativas', this.tentativas);
      this.showPopup = Popup.ERROR_NEW_TRY;
    }
  }

  telaDividida(telaDivididaCadastros: boolean) {
    if (telaDivididaCadastros === true) {
      this.ignore_facequality = false;
      this.error_message_front = 'centralize o rosto na moldura';
      document.getElementById('btn_tente_novamente')?.focus();
      this.zone.run(() => {
        this.aria_hidden = true;
        this.aria_hidden_capture = true;
        this.aria_hidden_capture_mensagem = true;
        this.aria_hidden_success = true;
        this.statusPack = false;
        this.controlPackSuccess = 0;
        this.display_class2 = 'box-none';
        this.display_class1 = '';
        this.classProgress = '';
        this.controlTimeout = false;
        this.display_none = '';
        this.display_none_percentual = '';
        this.display_none_progress = 'display_none';
      });

      const outputcwregister = document.getElementById('videoBox');
      if (outputcwregister) { outputcwregister.style.display = 'flex'; }

      const outputcwregisterappAriaHiddenBox = document.getElementById('appAriaHiddenBox');
      if (outputcwregisterappAriaHiddenBox) { outputcwregisterappAriaHiddenBox.style.display = 'block'; }

      const outputcwregisterappCaptureInstructionsBox = document.getElementById('appCaptureInstructionsBox');
      if (outputcwregisterappCaptureInstructionsBox) { outputcwregisterappCaptureInstructionsBox.style.display = 'block'; }
    }
  }

  telaDivididaTimeout(verifyTentativaTelaDivididaInt: any) {
    if ((verifyTentativaTelaDivididaInt === 'NaN') || (verifyTentativaTelaDivididaInt === null) || (verifyTentativaTelaDivididaInt === 0) || (verifyTentativaTelaDivididaInt === '0')) {
      this.tentativas = 0;
    } else if (parseInt(verifyTentativaTelaDivididaInt, 10) >= 3) {
      sessionStorage.setItem('tentativas', '0');
      this.telaDivididaTimeoutCapturado(false);
      this.handleRegisterComplementTimeout(this.imageBack);
    }
  }

  telaDivididaTimeoutCapturado(telaDivididaCapturado: boolean) {
    if (telaDivididaCapturado === true) {
      this.control_register = false;
      this.removeOnbeforeunload();
      this.trackEventGA4('sucesso', 'screen-view');
      this.trackItem('Biometria:Sucesso', 'pageLoad', 'Sucesso', this.negocio);

      const url_sucesso = this.baseurl + '/capture/success?' + this.link_tag;
      let url_final_session = localStorage.getItem('redirect_url');
      if ((url_final_session === '') || (url_final_session === null) || (!url_final_session)) {
        url_final_session = this.redirect_url;
        localStorage.setItem('redirect_url', url_final_session);
      }

      this.logsService.handleLogs(
        'CAPTURE_SUCCESS',
        `O usuário realizou a captura com sucesso. A URL de sucesso é a ${url_sucesso} e a URL de retorno ao canal é a ${this.redirect_url}`,
        'REGISTRO_CAPTURE_PAGE');
      setTimeout(() => {
        window.document.location.href = url_sucesso;
      }, 1500);
    } else {
      this.redirectUrl('no_aproved');
    }
  }

  allErros(fields_error: any, error_api: string) {
    const errorcalls = this.errorcallsService.allErros(fields_error, error_api);
    const errorcallsFunction = this.allErrosMessageCondition(errorcalls.message);
    this.code = errorcalls.code;
    this.message = errorcallsFunction;
    this.showPopupError = errorcalls.showPopupError;
    this.aria_hidden = true;
    document.getElementById('btn_tente_novamente_sessao')?.click();
    document.getElementById('btn_tente_novamente_sessao')?.focus();
  }

  allErrosMessageCondition(conditionMessagem: string) {
    let errorcallsMessage = conditionMessagem;
    if (conditionMessagem === 'Sua sessão expirou ou não foi encontrada. Retorne ao site e tente novamente.') {
      this.aria_hidden = true;
      errorcallsMessage = 'Error_Session';
      document.getElementById('btn_tente_novamente_sessao')?.focus();
    }
    return errorcallsMessage;
  }

  createUrl(fields_url: any) {
    const redirect_url = localStorage.getItem('redirect_url');
    if ((redirect_url !== 'undefined') || (redirect_url !== null)) {
      this.redirect_url = this.redirect_url + '?&key_capture=' + fields_url.key_capture + '&origin_process=' + this.origin_process + '&cpf_idpessoa=' + this.cpf_idpessoa + '&status=' + this.status + '&code=' + this.code + '&message=' + this.message;
    }
  }

  ngOnDestroy() {
    this.captureSub?.unsubscribe();
  }

  startCam() {
    let redirect_storage = localStorage.getItem('redirect_url');

    if (redirect_storage === null) {
      redirect_storage = 'itau.com.br';
    }
    const permission_cam = localStorage.getItem('permission_cam');

    if (permission_cam === 'clear') {
      // @ts-ignore
      navigator.getUserMedia(constraints(), s => this.play(s), e => {
        this.cam_error = e;
        const ee = this.cam_error;
        if (this.client_id === 'c4d094ea-6e9a-49c3-ad5a-1d26dd63122a') {
          alert('ERRO DE PERMISSAO 1');
          this.logsService.handleLogs('PERMISSION_CAM_DENIED', `A camera não foi permitida. Erro: ${ee}`, 'PERMISSION_CAPTURE_PAGE');
        }
        console.error(e);
        this.verificarPermissionCam(this.cam_error, redirect_storage);

        this.urlRetryPermissionCam = 'https://' + this.redirect_url + '?type=error_no_permission' + this.link_tag;
        if ((this.redirectUrlRetry !== null) && (this.redirectUrlRetry !== '') && (this.redirectUrlRetry !== undefined) && (this.redirectUrlRetry !== 'undefined')) {
          this.urlRetryPermissionCam = 'https://' + this.redirectUrlRetry + '?type=error_no_permission' + this.link_tag;
        }
        this.urlRetryPermissionCam = this.urlRetryPermissionCam.replace('&&', '&');
        this.urlRetryPermissionCam = this.urlRetryPermissionCam.replace('?&', '?');
        this.urlRetryPermissionCam = this.urlRetryPermissionCam.replace('??', '?');
        this.urlRetryPermissionCam = this.urlRetryPermissionCam.replace('?/', '?');
        this.logsService.handleLogs('PERMISSION_CAPTURE_CAM', `O usuário não permitiu o acesso a câmera. A URL de retorno é a ${this.urlRetryPermissionCam}`, 'PERMISSION_CAPTURE_PAGE');
      });
    } else if (permission_cam !== 'false') {
      this.urlRetryPermissionCam = 'https://' + this.redirect_url + '?type=error_no_permission' + this.link_tag;
      if ((this.redirectUrlRetry !== null) && (this.redirectUrlRetry !== '') && (this.redirectUrlRetry !== undefined) && (this.redirectUrlRetry !== 'undefined')) {
        this.urlRetryPermissionCam = 'https://' + this.redirectUrlRetry + '?type=error_no_permission' + this.link_tag;
      }
      this.urlRetryPermissionCam = this.urlRetryPermissionCam.replace('&&', '&');
      this.urlRetryPermissionCam = this.urlRetryPermissionCam.replace('?&', '?');
      this.urlRetryPermissionCam = this.urlRetryPermissionCam.replace('??', '?');
      this.urlRetryPermissionCam = this.urlRetryPermissionCam.replace('?/', '?');
      // @ts-ignore
      navigator.getUserMedia(constraints(), s => this.play(s), e => {
        this.logsService.handleLogs('permission_cam', 'O usuário não permitiu o acesso a câmera. A URL de retorno é a ' + this.urlRetryPermissionCam, 'PERMISSAO_CAMERA_NEGADA'), localStorage.setItem('permission_cam', 'clear'), setTimeout(() => { this.cam_error = e; localStorage.setItem('permission_cam', 'clear'), this.logsService.handleLogs('permission_cam', 'O usuário não permitiu o acesso a câmera. A URL de retorno é a ' + this.urlRetryPermissionCam), console.info(localStorage.setItem('permission_cam', 'clear'), sessionStorage.setItem('redirect_url', this.redirect_url), sessionStorage.setItem('redirectUrlError', this.redirect_error_url), this.redirect_url, this.redirect_error_url); this.removeOnbeforeunload(); window.document.location.href = this.baseurl + '/capture/errorpermission?' + this.link_tag; this.trackEventGA4('captura', 'visualization', 'modal:permissao-negada'); this.trackItem('Biometria:Permissao', 'pageLoad', 'Permissao', this.negocio); }, 2000);
      });
    } else {
      this.urlRetryPermissionCam = 'https://' + this.redirect_url + '?type=error_no_permission' + this.link_tag;
      if ((this.redirectUrlRetry !== null) && (this.redirectUrlRetry !== '') && (this.redirectUrlRetry !== undefined) && (this.redirectUrlRetry !== 'undefined')) {
        this.urlRetryPermissionCam = 'https://' + this.redirectUrlRetry + '?type=error_no_permission' + this.link_tag;
      }
      this.urlRetryPermissionCam = this.urlRetryPermissionCam.replace('&&', '&');
      this.urlRetryPermissionCam = this.urlRetryPermissionCam.replace('?&', '?');
      this.urlRetryPermissionCam = this.urlRetryPermissionCam.replace('??', '?');
      this.urlRetryPermissionCam = this.urlRetryPermissionCam.replace('?/', '?');

      this.logsService.handleLogs('PERMISSION_CAM', `O usuário não permitiu o acesso a câmera. A URL de retorno é a ${this.urlRetryPermissionCam}`, 'PERMISSION_CAPTURE_PAGE');
      setTimeout(() => {
        this.removeOnbeforeunload();
        this.trackEventGA4('captura', 'visualization', 'modal:permissao-negada');
        this.trackItem('Biometria:Permissao', 'pageLoad', 'Permissao', this.negocio);
        if (this.client_id === 'c4d094ea-6e9a-49c3-ad5a-1d26dd63122a') {
          alert('ERRO DE PERMISSAO 6');
          this.logsService.handleLogs('PERMISSION_CAM_DENIED', 'A camera não foi permitida.', 'PERMISSION_CAPTURE_PAGE');
        }
        window.document.location.href = this.baseurl + '/capture/errorpermission?' + this.link_tag;
      }, 1500);
    }

    // tslint:disable-next-line: early-exit
    if (!this.isError) {
      this.btnHabilitado = setInterval(() => {
        if (document.getElementById('status')?.innerHTML !== 'Inicializando...') {
          clearInterval(this.btnHabilitado);
          this.loadingService.finishLoading();
        }
      }, 500);
    }
  }

  verificarPermissionCam(camErrorPermission: any, redirect_storagePermission: any) {
    const camErrorPermissionB = camErrorPermission + '';
    if (this.client_id === 'c4d094ea-6e9a-49c3-ad5a-1d26dd63122a') {
      alert('ERRO DE PERMISSAO 7');
      this.logsService.handleLogs('PERMISSION_CAM_DENIED', 'A camera não foi permitida.', 'PERMISSION_CAPTURE_PAGE');
    }
    if (camErrorPermissionB.toString() === 'NotAllowedError: Permission denied') {
      this.trackEventGA4('captura', 'visualization', 'modal:permissao-negada');
      this.trackItem('Biometria:Permissao', 'pageLoad', 'Permissao', this.negocio);
      this.removeOnbeforeunload();
      window.document.location.href = this.baseurl + '/capture/errorpermission?' + this.link_tag;
    } else {
      localStorage.setItem('permission_cam', 'false');
      this.removeOnbeforeunload();
      window.document.location.href = 'https://' + redirect_storagePermission + '/?&error=' + Popup.ERROR_NO_PERMISSION + this.link_tag + '&re=true';
    }
  }

  play(stream: MediaStream) {
    localStorage.setItem('permission_cam', 'true');
    this.video.nativeElement.srcObject = stream;
    this.video.nativeElement.play();
    this.video.nativeElement.addEventListener('canplay', () => this.onCanPlay(this.isStreaming), false);

    this.classProgressRegister = '';

    this.ignore_facequality = true;
    this.sessionTimeout = true;
    this.token = sessionStorage.getItem('token');
    setTimeout(() => {
      this.getTaticoLogs(captureResponde);
    }, 100);
  }

  async getTaticoLogsInit() {
    await getFieldsCaronte();
  }

  async getTaticoLogs(responseCWT: any) {
    this.register.logs_caronte = responseCWT;
  }

  async onCanPlay(isStreamingOnCan: boolean) {
    if (isStreamingOnCan) { return; }
    this.isStreaming = true;
    if (this.infos_os.os === 'iOS') {
      const bodyHeightTest = document.querySelector('body').offsetHeight;
      const videoHtmlTest = document.getElementById('video');
      if (videoHtmlTest) { videoHtmlTest.style.height = bodyHeightTest + 'px'; }
    }
    const video = this.video.nativeElement;
    const canvas = this.ovalOverlay.nativeElement;

    this.initDrawIndicator(video, canvas);
    await this.initAutoCapture();
  }

  initDrawIndicator(video: any, canvas: any) {
    try {
      drawIndicator(null, video, canvas, this.infos_os.os);
    } catch (e) {
      console.error(`[CamComponent][initDrawIndicator] e: ${e}`);
    }
  }

  async initAutoCapture() {
    try {
      await startAutoCapture();
    } catch (e) {
      console.error(`[CamComponent][initAutoCapture] e: ${e}`);
    }
  }

  async readyFacequality() {
    this.mainCamera = mainCamera;
  }

  setIntervalFrame() {
    const sleep = duration => new Promise(resolve => setTimeout(resolve, duration));
    const poll = (promiseFn, duration) => promiseFn().then(
      sleep(duration).then(() => poll(promiseFn, duration)));

    poll(() => new Promise(() => this.intervalFrame()), 1000);
  }

  intervalFrame() {
    return;
  }

  closeCamera() {
    navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then(mediaStream => {
    });

    this.take_my_picture_on_camera_close = false;
    if (this.infos_os.os === 'iOS') {
      const bodyHeightTest = document.querySelector('body').offsetHeight;
      const videoHtmlTest = document.getElementById('video');
      if (videoHtmlTest) { videoHtmlTest.style.height = bodyHeightTest + 'px'; }
    }
  }

  removeOnbeforeunload() {
    window.onbeforeunload = () => { };
    window.onbeforeunload = () => { };
  }

  hideInstructions() {
    this.logsService.handleLogs('INITIAL', `A URL de acesso atual é a ${window.location.href}`, 'INIT_CAPTURE_PAGE');
    this.trackItem('Biometria:Dicas', 'Clique', 'TudoPronto', this.negocio);
    setTimeout(() => {
      this.trackEventGA4('captura', 'screen-view');
      this.trackItem('Biometria:Captura', 'pageLoad', 'TirarFoto', this.negocio);
    }, 100);

    this.aria_hidden = true;
    this.aria_hidden_capture = true;
    this.aria_hidden_capture_mensagem = false;
    this.aria_hidden_success = true;
    this.aria_hidden_capture_model = false;
    // Caso aware esconde a tela de dicas
    if (this.type_liveness === 'aware') {
      this.showPopup = undefined;
    }
    this.accesstoken = this.verifyToken(this.token, this.status);
    if (this.accesstoken === true) {
      if (this.type_liveness === 'facetec') {
        this.startCamFacetec();
      } else {
        this.startCam();
      }
    }
    this.alterProgress(this.controlPackSuccess);

    document.getElementById('label_capture_fake')?.click();
    document.getElementById('label_capture_fake')?.focus();
  }

  startCamFacetec() {
    // @ts-ignore
    navigator.getUserMedia(constraints(), () => this.onLivenessCheckPressed(), e => {
      if (this.client_id === 'c4d094ea-6e9a-49c3-ad5a-1d26dd63122a') {
        this.logsService.handleLogs('PERMISSION_CAM_DENIED', 'A camera não foi permitida. Erro 8 ', 'PERMISSION_CAPTURE_PAGE');
      }
      this.removeOnbeforeunload();
      window.document.location.href = this.baseurl + '/capture/errorpermission?' + this.link_tag;
    });
  }

  setAria() {
    this.aria_hidden_capture_model = true;
  }

  alterProgress(controlPackSuccessAlter: any) {
    if (controlPackSuccessAlter === 1) {
      this.classProgress = '25';
    } else if (controlPackSuccessAlter === 2) {
      this.classProgress = '50';
    } else if (controlPackSuccessAlter === 3) {
      this.classProgress = '75';
    } else if (controlPackSuccessAlter === 4) {
      this.classProgress = '100';
    }
  }

  alterProgressSuccess(controlPackRegisterSuccessAlter: any) {
    if (controlPackRegisterSuccessAlter === 1) {
      this.classProgressRegister = '25';
    } else if (controlPackRegisterSuccessAlter === 2) {
      this.classProgressRegister = '50';
    } else if (controlPackRegisterSuccessAlter === 3) {
      this.classProgressRegister = '75';
    } else if (controlPackRegisterSuccessAlter === 4) {
      this.classProgressRegister = '100';
    }
    const rand = Math.floor(Math.random() * 5);

    this.zone.run(() => {
      this.register_success = this.message_sucess_register[rand];
      document.getElementById('label_register_success')?.focus();
      document.getElementById('label_register_success')?.setAttribute('aria-label', this.message_sucess_register[rand]);
    });
  }

  verifyToken(tokenVerify: any, statusVerify: string) {
    if ((tokenVerify === '') || (tokenVerify === 'undefined') && (tokenVerify === undefined) && (tokenVerify === null)) {
      if (statusVerify !== 'Error_Session') {
        this.status = 'Error';
      }

      this.code = 502;
      this.message = 'Sua sessão expirou ou não foi encontrada. Retorne ao site e tente novamente.';
      this.aria_hidden = true;
      this.fields = {
        code: 502,
        status: 'Error',
        message: 'Sua sessão expirou ou não foi encontrada. Retorne ao site e tente novamente.',
        key_capture: '',
        cpf_idpessoa: '',
        origin_process: '',
        redirect_url: '',
        captureKey: this.captureKey,
      };
      this.allErros(this.fields, '');
      return false;
    }
    return true;
  }

  verificaVars(verificaToken: boolean) {
    return verificaToken;
  }

  onPackage(pack: string) {
    this.accesstoken = this.verifyToken(this.token, this.status);
    const validaAccesstoken = this.verificaVars(this.accesstoken);
    if (validaAccesstoken !== true) {
      return false;
    }

    const validaTakeMyPicture = this.verificaVars(this.take_my_picture);
    if (validaTakeMyPicture === false) {
      return;
    }

    const validaTakeMyPictureOnCameraClose = this.verificaVars(this.take_my_picture_on_camera_close);
    if (validaTakeMyPictureOnCameraClose === false) {
      return;
    }

    if ((!this.token) || (this.token === '')) { return; }
    if (this.statusPack) { return; }
    this.statusPack = true;
    this.facequality.package = pack;

    if (this.controlTimeout === false) {
      this.controlTimeout = true;
      this.status = 'capturando';
      this.functionTimeout(this.status, this.controlPackSuccess);
    }

    const setOnPackageControl = this.repeteVars(this.controlPackSuccess);
    if (setOnPackageControl <= 4) {
      this.conectCaronte('facequality');
    }
  }

  functionVerificaTimeout(control_timeout_register: boolean) {
    if (control_timeout_register === false) {
      setTimeout(() => {
        this.functionVerificaTimeoutSetTimeout(this.controlTimeout);
      }, 50000);
    }
    return true;
  }

  functionVerificaTimeoutSetTimeout(controlTimeoutSet: boolean) {
    if (controlTimeoutSet === true) {
      let redirect_storage = localStorage.getItem('redirect_url');
      redirect_storage = redirect_storage + '';
      this.redirect_url = redirect_storage;
      sessionStorage.setItem('redirect_url', redirect_storage);
      this.handleRegisterTimeout(this.control_register, this.tentativas);
    } else {
      return true;
    }
  }

  functionTimeoutTentativaZerada(tentativaValidaTimeout: any) {
    if (tentativaValidaTimeout === '3') {
      this.tentativas = 0;
      sessionStorage.setItem('tentativas', this.tentativas);
    } else {
      if ((this.tentativas === 'NaN') || (this.tentativas === 0) || (this.tentativas === '0')) {
        this.tentativas = 0;
      }
      this.tentativas = parseInt(this.tentativas, 10) + 1;
      sessionStorage.setItem('tentativas', this.tentativas);
      document.getElementById('btn_tente_novamente')?.focus();
      this.aria_hidden_capture = true;
      this.aria_hidden_capture_mensagem = true;
      this.zone.run(() => {
        this.showPopup = Popup.ERROR_NEW_TRY;
        this.aria_hidden = true;
      });
    }
  }

  functionTimeout(statusTimeout: string, controlPackSuccess: any) {
    if ((statusTimeout !== 'Awaiting_Registration') && (statusTimeout !== 'capturando')) {
      if (controlPackSuccess < 4) {
        const tentativas = sessionStorage.getItem('tentativas');
        this.tentativas = tentativas;
        const setTentativaValidaFunctionTimeout = this.repeteVarsFunctionTimeout(this.tentativas);
        this.functionTimeoutTentativaZerada(setTentativaValidaFunctionTimeout);
      } else {
        sessionStorage.setItem('tentativas', '');
        this.aria_hidden_capture = true;
        this.aria_hidden_capture_mensagem = true;
        document.getElementById('btn_tente_novamente')?.focus();
        this.zone.run(() => {
          this.showPopup = Popup.ERROR_NEW_TRY;
        });
      }
    }
    this.functionVerificaTimeout(this.control_timeout_register);
  }

  closeShow() {
    if (this.type_liveness === 'facetec') {
      this.trackItem('Biometria:TentarNovamente', 'Clique', 'TentarNovamente', this.negocio);
      this.isError = this.errorcallsService.fields.showPopupError = false;
      document.getElementById('titlespinner')?.click();
      document.getElementById('titlespinner')?.focus();
      this.zone.run(() => {
        this.display_class2 = 'box-none';
        this.showPopup = Popup.CAPTURE;
        this.status = '';
        document.getElementById('titlespinner')?.click();
        document.getElementById('titlespinner')?.focus();
      });

      this.onLivenessCheckPressed();

      this.spinner.show('default');
      const verificaSDKFacetecInterval = setInterval(() => {
        if (!this.sdkFacetecInicializado) {
          return;
        }
        clearInterval(verificaSDKFacetecInterval);
        this.sdkFacetecInicializado = true;
        console.info('# Validado inicialização do SDK da FaceTec, escondido as instruções; Retorno: ', FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus()));
        this.logsService.handleLogs('FaceTec', `Validado inicialização do SDK da FaceTec, escondido as instruções; Retorno: ${FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus())}`, 'FACETEC');
      }, 500);

      const verificaBtnInicioFacetecInterval = setInterval(() => {
        if (document.getElementById('DOM_FT_getReadyActionButton')?.style.display !== 'block') {
          return;
        }
        clearInterval(verificaBtnInicioFacetecInterval);
        this.showPopup = undefined;
        SampleAppUtilities.fadeOutMainUIAndPrepareForSession();
        console.info('# Iniciado o preparo para captura com a FaceTec; Retorno: ', FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus()));
        this.logsService.handleLogs('FaceTec', `Iniciado o preparo para captura com a FaceTec; Retorno: ${FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus())}`, 'FACETEC');
        this.spinner.hide('default');
      }, 500);
      return;
    }

    if ((this.type_liveness === 'aware') && (this.infos_os.os === 'iOS') && ((this.tentativas === 'NaN') || (this.tentativas === null) || (this.tentativas === 0))) {
      const bodyHeightTest = document.querySelector('body').offsetHeight;
      const videoHtmlTest = document.getElementById('video');
      if (videoHtmlTest) { videoHtmlTest.style.height = bodyHeightTest + 'px'; }
    }
    this.trackEventGA4('captura', 'screen-view');
    this.trackItem('Biometria:Captura', 'pageLoad', 'TirarFoto', this.negocio);
    setTimeout(() => {
      this.trackItem('Biometria:TenteNovamente', 'Clique', 'TentarNovamente', this.negocio);
    }, 1500);

    let redirect_storage = localStorage.getItem('redirect_url');
    const setStatusRedirectStorage = this.repeteVarsCloseShow(redirect_storage);
    if ((setStatusRedirectStorage === null) || (setStatusRedirectStorage === 'null') || (setStatusRedirectStorage === '') || (setStatusRedirectStorage === 'NaN')) {
      redirect_storage = 'itau.com.br';
    }

    if ((this.status === 'Error_Session') || (this.token === '')) {
      window.document.location.href = 'https://' + redirect_storage + '/?&type=no_session' + this.link_tag;
    }
    this.functionTimeout(this.status, this.controlPackSuccess);
    this.zone.run(() => {
      this.take_my_picture_on_camera_close = true;
      this.showPopup = Popup.ERROR_GENERAL;
      this.statusPack = false;
      this.controlPackSuccess = 0;
      this.display_class1 = '';
      this.display_class2 = 'box-none';
      this.classProgress = '';
      this.controlTimeout = false;
      this.display_none = '';
      this.display_none_percentual = '';
      this.display_none_progress = 'display_none';
      this.aria_hidden = true;
      this.aria_hidden_capture = false;
      this.aria_hidden_capture_mensagem = false;
      this.aria_hidden_success = true;
      this.sessionTimeout = true;
    });

    // const setCapturado = this.verificaVars(this.capturado);
    if (this.capturado === true) {
      setTimeout(() => {
        this.removeOnbeforeunload();
        this.trackEventGA4('sucesso', 'screen-view');
        this.trackItem('Biometria:Sucesso', 'pageLoad', 'Sucesso', this.negocio);

        const url_sucesso = this.baseurl + '/capture/success?' + this.link_tag;
        let url_final_session = localStorage.getItem('redirect_url');
        if ((url_final_session === '') || (url_final_session === null) || (!url_final_session)) {
          url_final_session = this.redirect_url;
          localStorage.setItem('redirect_url', url_final_session);
        }

        this.logsService.handleLogs('REGISTRO', `O usuário realizou a captura com sucesso. A URL de sucesso é a ${url_sucesso} e a URL de retorno ao canal é a ${this.redirect_url}`, 'REGISTER_CAPTURE_PAGE');
        setTimeout(() => {
          window.document.location.href = url_sucesso;
        }, 1500);
      }, 1000);
    } else {
      setTimeout(() => {
        document.getElementById('label_capture')?.focus();
      }, 1000);

      let verifyOs = false;
      verifyOs = this.verifyIos(this.infos_os.os);
      if (this.infos_os.os === 'iOS') {
        const bodyHeightTest = document.querySelector('body').offsetHeight;
        const videoHtmlTest = document.getElementById('video');
        if (videoHtmlTest) { videoHtmlTest.style.height = bodyHeightTest + 'px'; }
      }
    }
  }

  repeteVars(varAll: any) {
    return varAll;
  }

  repeteVarsCloseShow(varAllStatusRedirect: any) {
    return varAllStatusRedirect;
  }

  repeteVarsFunctionTimeout(varAllFunctionToken: any) {
    return varAllFunctionToken;
  }

  setIosModel(sistema_operacional: string, dadosios: any, deviceModel: string) {
    let deviceModelResult = deviceModel;
    if ((sistema_operacional === 'iOS') && (dadosios !== null) && (dadosios.Identifier !== null)) {
      deviceModelResult = dadosios.Identifier;
    }
    return deviceModelResult;
  }

  verifyIos(modelo_sistema_operacional: string) {
    if (modelo_sistema_operacional === 'iOS') {
      const output = document.getElementById('videoBox');
      if (output) { output.style.display = 'flex'; }
    }
    return false;

  }

  redirectUrl(param: string) {
    this.removeOnbeforeunload();
    this.zone.run(() => {
      this.remove_beforeunload = false;
    });

    let redirect_storage = localStorage.getItem('redirect_url');
    if (redirect_storage === null) {
      redirect_storage = 'itau.com.br';
    }
    if ((this.redirect_error_url === '') || (this.redirect_error_url === ' ')) {
      this.redirect_error_url = redirect_storage;
    }
    if ((param === 'no_session') || (this.token === '')) {
      if (this.redirect_error_url && this.redirect_error_url !== '' && this.redirect_error_url !== 'itau.com.br') {
        const urlRedirect = 'https://' + this.redirect_error_url + '&type=' + Popup.ERROR_NO_SESSION;
        const urlRedirectClear = urlRedirect.replace('https://https://', 'https://').replace('?&', '?').replace('??', '?');
        this.logsService.handleLogs('REGISTRO', `Biometria não capturada. Sessão não encontrada. URL de retorno: ${urlRedirectClear}`, 'SESSION_CAPTURE_PAGE');
        setTimeout(() => {
          window.document.location.href = urlRedirectClear;
        }, 2500);
      } else {
        this.logsService.handleLogs('REGISTRO', 'Biometria não capturada. Sessão não encontrada. URL de retorno: https://www.itau.com.br', 'SESSION_CAPTURE_PAGE');
        setTimeout(() => {
          window.document.location.href = 'https://www.itau.com.br';
        }, 2500);
      }
    } else if ((param === 'no_frame') && (this.token !== '')) {
      this.redirect_error_url = 'https://' + this.redirect_error_url + '&type=' + Popup.ERROR_NO_FRAME + this.link_tag;
      this.redirect_error_url = this.redirect_error_url.replace('?&', '?');
      this.redirect_error_url = this.redirect_error_url.replace('https://https://', 'https://');
      this.redirect_error_url = this.redirect_error_url.replace('??', '?');
      this.logsService.handleLogs('REGISTRO', `Token não encontrado. URL de retorno: ${this.redirect_error_url}`, 'TOKEN_CAPTURE_PAGE');
      setTimeout(() => {
        window.document.location.href = this.redirect_error_url;
      }, 2500);
    } else if ((param === 'no_aproved_quality') && (this.token !== '')) {
      this.redirect_error_url = 'https://' + this.redirect_error_url + '&type=' + Popup.ERROR_NO_FRAME + this.link_tag;
      this.redirect_error_url = this.redirect_error_url.replace('?&', '?');
      this.redirect_error_url = this.redirect_error_url.replace('https://https://', 'https://');
      this.redirect_error_url = this.redirect_error_url.replace('??', '?');
      this.logsService.handleLogs('REGISTRO', `Biometria não capturada. Sem Qualidade ou Liveness. URL de retorno: ${this.redirect_error_url}`, 'LIVENESS_CAPTURE_PAGE');
      setTimeout(() => {
        window.document.location.href = this.redirect_error_url;
      }, 2500);
    } else if ((param === 'no_aproved_error_server') && (this.token !== '')) {
      this.redirect_error_url = 'https://' + this.redirect_error_url + '&type=' + Popup.ERROR_NO_FRAME + this.link_tag;
      this.redirect_error_url = this.redirect_error_url.replace('?&', '?');
      this.redirect_error_url = this.redirect_error_url.replace('https://https://', 'https://');
      this.redirect_error_url = this.redirect_error_url.replace('??', '?');
      this.logsService.handleLogs('REGISTRO', `Biometria não capturada. Erro no servidor. URL de retorno: ${this.redirect_error_url}`, 'SERVER_CAPTURE_PAGE');
      setTimeout(() => {
        window.document.location.href = this.redirect_error_url;
      }, 2500);
    } else {
      this.redirect_error_url = 'https://' + this.redirect_error_url + '&type=' + Popup.ERROR_NO_CAPTURE + this.link_tag;
      this.redirect_error_url = this.redirect_error_url.replace('?&', '?');
      this.redirect_error_url = this.redirect_error_url.replace('https://https://', 'https://');
      this.redirect_error_url = this.redirect_error_url.replace('??', '?');
      this.logsService.handleLogs('REGISTRO', `Biometria não capturada. Erro no servidor. URL de retorno: ${this.redirect_error_url}`, 'CAPTURE_PAGE');
      setTimeout(() => {
        window.document.location.href = this.redirect_error_url;
      }, 2500);
    }

  }

  async setCript(payload_var: any): Promise<IEncrypted> {
    if (environment.disableCripto) {
      return {
        payload: payload_var,
        key: String(''),
      };
    }
    try {
      const encrypted = await encrypt(JSON.stringify(payload_var));
      const encrypted_caronte = await encrypt(JSON.stringify({ key: this.session }));
      this.caronte_key = encrypted.key;
      this.caronte_payload = encrypted_caronte;
      return encrypted;
    } catch (e) {
      const response_pos_crypt = {
        payload: '',
        key: '',
      };
      return response_pos_crypt;
    }
  }

  validarInicializacaoFacetec() {
    this.spinner.show('default');
    this.aria_hidden_spinner = true;
    document.getElementById('spinner-itau')?.click();
    document.getElementById('spinner-itau')?.focus();
    const btnTudoPronto = document.getElementById('liveness-button') as HTMLButtonElement | null;
    btnTudoPronto?.setAttribute('disabled', '');
    btnTudoPronto?.setAttribute('style', 'opacity:0.3');
    this.labelBtnFacetecTudoPronto = 'carregando';

    const divDicas = document.getElementsByClassName('box-initial')[0] as HTMLDivElement | null;
    divDicas?.setAttribute('style', 'opacity: 0.2');

    // Verifica se o SDK da Facetec foi inicializado
    const verificaSDKFacetecInterval = setInterval(() => {
      if (!this.sdkFacetecInicializado) {
        return;
      }
      clearInterval(verificaSDKFacetecInterval);
      this.hideInstructions();
      console.info('* Validado inicialização do SDK da FaceTec, escondido as instruções; Retorno: ', FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus()));
      this.logsService.handleLogs('FaceTec', `Validado inicialização do SDK da FaceTec, escondido as instruções; Retorno: ${FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus())}`, 'FACETEC');
      this.sdkFacetecInicializado = true;
    }, 500);

    const verificaBtnInicioFacetecInterval = setInterval(() => {
      if (document.getElementById('DOM_FT_getReadyActionButton')?.style.display !== 'block') {
        return;
      }
      clearInterval(verificaBtnInicioFacetecInterval);
      this.showPopup = undefined;
      SampleAppUtilities.fadeOutMainUIAndPrepareForSession();
      console.info('* Iniciado o preparo para captura com a FaceTec; Retorno: ', FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus()));
      this.logsService.handleLogs('FaceTec', `Iniciado o preparo para captura com a FaceTec; Retorno: ${FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus())}`, 'FACETEC');
      this.spinner.hide('default');
    }, 1000);

  }

  closeSpinner() {
    this.newSpinner.hide('primary');
  }

  /**
   * Método responsável por enviar o evento de screen-view, alert e visualization para o Google Analytics GA4.
   * @param eventName - Nome do evento do screen-view, alert e visualization.
   * @param detail - Nome do botão clicado.
   */
  trackEventGA4(pagename: string, evento_name: string, detail?: string) {
    let objTrack;
    objTrack = {
      custom_path: this.type_liveness === 'facetec' ? '/biometria/' + pagename + '-ft' : '/biometria/' + pagename,
      event_name: evento_name,
      person_id: this.client_id,
      customer_segment: this.segmento,
      proposal_id: this.idproposta,
      detail: detail || '',
    };
    return this.taggingServiceGA4.trackGA4(objTrack);
  }
}
