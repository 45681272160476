import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {

  @Input() ligado: boolean;
  constructor() { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

}
