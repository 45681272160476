import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoadingService {

  active_request = 0;

  constructor(
  ) {

  }

  loading = new Subject<boolean>();

  startLoading() {
    if (this.active_request === 0) {
      this.loading.next(true);
    }
    this.active_request++;
  }

  finishLoading() {
    this.active_request--;
    if (this.active_request <= 0) {
      this.active_request = 0;
      this.loading.next(false);
    }
  }

  getLoading() {
    console.log('LoadingService - getLoading()');
    return this.loading.pipe(distinctUntilChanged());
  }

}
