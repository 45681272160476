import { UploadComponent } from './upload/upload.component';
import { DoubtComponent } from './doubt/doubt.component';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component.utils';
import { CamComponent } from './cam/cam.component.utils';
import { SuccessComponent } from './success/success.component';
import { ErrorComponent } from './error/error.component';
import { ErrorriskComponent } from './errorrisk/errorrisk.component';
import { ErrorpermissionComponent } from './errorpermission/errorpermission.component.utils';
import { Location } from '@angular/common';
import { NgModule } from '@angular/core';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'cam', component: CamComponent },
  { path: 'doubt', component: DoubtComponent },
  { path: 'upload', component: UploadComponent },
  { path: 'success', component: SuccessComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'errorrisk', component: ErrorriskComponent },
  { path: 'errorpermission', component: ErrorpermissionComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [
    Location,
  ],
  exports: [RouterModule],
})
export class CaptureRoutingModule { }
