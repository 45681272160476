<div class="app-instructions-popup">
    <app-popup>
        <div class="instructions">

            <h2>{{title}}</h2>
            <p *ngIf="description">{{description}}</p>

            <div class="instructions-items">
                <div class="instruction-item" *ngFor="let instruction of instructions">
                    <div class="icon">
                        <app-icon [icon]="instruction.icon"></app-icon>
                    </div>
                    <div class="message">
                        {{instruction.text}}
                    </div>
                </div>
            </div>

            <app-button [label]="buttonLabel" (click)="click()" [fullWidth]="true" id="{{nameId}}">
            </app-button>
        </div>
    </app-popup>
</div>