import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doubt',
  templateUrl: './doubt.component.html',
  styleUrls: ['./doubt.component.scss'],
})
export class DoubtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
