import { Component, ElementRef, ViewChild } from '@angular/core';
import { ItauDigitalAnalyticsView } from '@app-analytics/analytics';
import { LOADING_VALIDATE, SUCCESS_PAGE } from '../../shared/helpers/paramsGa4';
import { MainService } from '../../services/main.service';
import { LOGS_SUCCESS_PAGE } from '../../shared/helpers/stepsToLogs';

@Component({
  selector: 'success-root',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent {
  loading: boolean = true;
  loadingTwo: boolean = false;
  loadingFinish: boolean = false;

  constructor(
    private itauDigitalAnalytics: ItauDigitalAnalyticsView,
    public mainService: MainService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.loading = false;
      this.loadingTwo = true;
    }, 2000);
    setTimeout(() => {
      this.loadingTwo = false;
      this.loadingFinish = true;
      this.itauDigitalAnalytics.track(SUCCESS_PAGE);
    }, 4000);
    setTimeout(() => {
      // Fluxo Bio 2.0
      // const result = this.mainService.matchAPI();
      // // Validar quando houver contrato com o backend, qual valor precisamos ler de resultado.
      // if(result) {
      //   this.callSuccess();
      // } else 
      // this.callError();

      // Usado no endFlow do fluxo antigo, será removido na Bio 2.0
      this.callSuccess();
    }, 6000);
    this.itauDigitalAnalytics.track(LOADING_VALIDATE);
    if (localStorage.getItem('toggle_logs') === 'true') {
      this.mainService.insightsAPI(LOGS_SUCCESS_PAGE);
    }
  }

  callSuccess() {
    const redirectSuccess = sessionStorage.getItem('redirectSuccess');
    window.document.location.href = redirectSuccess || 'https://www.itau.com.br';
  }

  callError() {
    const redirectError = sessionStorage.getItem('redirectError');
    window.document.location.href = redirectError || 'https://www.itau.com.br';
  }
}
