import { InjectionToken } from '@angular/core';

/**
 * A DI Token representing the main rendering context. In a browser this is the Window object.
 *
 * Note: Document might not be available in the Application Context when Application and Rendering
 * Contexts are not the same (e.g. when running the application into a Web Worker).
 *
 * @publicApi
 */
export const WINDOW = new InjectionToken('AcoWindowToken');

export function windowFactory() {
  return window;
}

/**
 * Digital Analytics Framework
 */
export interface IFrameworkDA {
  Track(): void;
}
