import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { LogsService } from 'src/app/services/logs/logs.service';


@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SuccessComponent implements OnInit {

  constructor(
    private loadingService: LoadingService,
    private logsService: LogsService,
  ) {}

  redirect_url = 'www.itau.com.br';
  ambiente = sessionStorage.getItem('ambiente');
  negocio = sessionStorage.getItem('negocio');
  tipoDeCanal = sessionStorage.getItem('tipoDeCanal');
  prefix_pagina = sessionStorage.getItem('prefix_pagina');
  origin_process = localStorage.getItem('origin_process');
  client_id = localStorage.getItem('client_id');
  link_tag = '&ambiente=' + this.ambiente + '&negocio=' + this.negocio + '&tipoDeCanal=' + this.tipoDeCanal + '&prefix-pagina=' + this.prefix_pagina;

  ngOnInit(): void {
    this.loadingService.finishLoading();
    let url_formatada = localStorage.getItem('redirect_url');
    if (url_formatada !== null) {
      url_formatada = url_formatada.replace('??', '?');
      this.redirect_url = url_formatada;
    }
    sessionStorage.setItem('tentativas', '0');
    sessionStorage.setItem('ambiente', '');
    sessionStorage.setItem('negocio', '');
    sessionStorage.setItem('tipoDeCanal', '');
    sessionStorage.setItem('prefix_pagina', '');
    window.onbeforeunload = () => { };
    window.onbeforeunload = () => { };
    let callback_end = '';
    if (this.redirect_url !== null) {
      callback_end = this.redirect_url;
    }

    callback_end = callback_end.replace('//', '/');
    this.redirect_url = callback_end;

    document.getElementById('label_register_success')?.focus();
    this.logsService.conectCaronte();
    setTimeout(() => {
      this.redirectUrl(this.redirect_url);
    }, 5500);
  }

  redirectUrlNull() {
    this.redirectUrl(this.redirect_url);
  }

  redirectUrl(url_mount: string) {
    this.redirect_url = url_mount;
    const newStr = this.redirect_url.split('?');
    let callback_end: string;
    if (newStr.length > 1) {
      const new_init = newStr[0];
      delete newStr[0];
      const join_callback = newStr.join('&');
      callback_end = new_init + '?' + join_callback;
    } else {
      this.redirect_url = this.redirect_url + '/?';
      callback_end = this.redirect_url;
    }

    callback_end = callback_end.replace('https://', '');
    this.redirect_url = callback_end.replace('http://', '');
    this.redirect_url = this.redirect_url.replace('//', '/');
    this.redirect_url = this.redirect_url.replace('&&', '&');
    const redirect_cartoes = this.redirect_url + 'type=success' + this.link_tag;
    const newStrCartoes = redirect_cartoes.split('&type');
    if (newStrCartoes.length <= 1) {
      this.redirect_url = this.redirect_url + '&';
    }

    const REDIRECT_URL = `https://${this.redirect_url}type=success${this.link_tag}`;
    this.logsService.handleLogs('SucessRedirect',
    'O usuário será redirecionado para o Canal. A URL de retorno ao canal é a ' + REDIRECT_URL,
    'SUCESSO_REDIRECT');
    setTimeout(() => {
      window.document.location.href = REDIRECT_URL;
    }, 1000);
  }
}