import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from './environments/environment';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

datadogRum.init({
  applicationId: environment.dataDogID,
  clientToken: environment.dataDogTK,
  env: environment.production ? 'prod' : 'hom',
  site: 'datadoghq.com',
  service: 'ou3-biometria-captura-web',
  version: environment.appVersion,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: environment.dataDogAllowedTracingUrls,
});
