import { DatePipe } from '@angular/common';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { PayloadRegister } from '../../interfaces/Payloads'
import { getCameraInformation, getInfosFromDevice } from './verification';
import { environment } from '../../../environments/environment';
import { Supplier } from '../../interfaces/Suppliers';
import { ErrorsPage } from '../../../app/interfaces/ErrorsPage';
import { MatchContracts, ParametersContracts } from '../../../app/interfaces/Contracts';

export const saveTokens = (supplier: string, token: string): void => {
  sessionStorage.setItem('supplier_session', supplier);
  sessionStorage.setItem('token_session', token);
}

export const saveLinksCharon = (links: any): void => {
  localStorage.setItem('logs_link', links.logs.href);
  localStorage.setItem('session_link', links.session.href);
  localStorage.setItem('register_link', links.register.href);
}

export const saveLinksNewCharon = (data: any): void => {
  localStorage.setItem('new_charon_insights', data.body.links[0].href);
  localStorage.setItem('new_charon_faceanalyzer', data.body.links[1].href);
  localStorage.setItem('new_charon_entrypoint', data.body.links[2].href);
}

export const saveUrlData = () => {
  const token = sessionStorage.getItem('token_session') || '';
  const decoded = jwtDecode(token);
  const result = decoded as any;

  const cn = sessionStorage.getItem('cn');
  const ambiente = sessionStorage.getItem('ambiente');
  const negocio = sessionStorage.getItem('negocio');
  const tipoDeCanal = sessionStorage.getItem('tipoDeCanal');
  const prefix_pagina = sessionStorage.getItem('prefix_pagina');
  const tid = sessionStorage.getItem('tid');
  const customer_segment = sessionStorage.getItem('customer_segment');

  const params = '&cn=' + cn + '&ambiente=' + ambiente + '&negocio=' + negocio + '&customer_segment' + customer_segment + '&tipoDeCanal=' + tipoDeCanal + '&prefix_pagina=' + prefix_pagina + '&tid=' + tid;
  sessionStorage.setItem('paramsUrl', params);

  let redirectUrl = result.redirectUrl;
  if (redirectUrl) {
    redirectUrl = redirectUrl.replace('https://', '');
    redirectUrl = redirectUrl.replace('http://', '');
    redirectUrl = redirectUrl.replace('&&', '&');
    redirectUrl = redirectUrl.replace('?&', '?');
    sessionStorage.setItem('redirectUrl', redirectUrl);
  }

  let redirectUrlError = result.redirectUrlError;
  if (redirectUrlError) {
    redirectUrlError = redirectUrlError.replace('https://', '');
    redirectUrlError = redirectUrlError.replace('http://', '');
    redirectUrlError = redirectUrlError.replace('&&', '&');
    redirectUrlError = redirectUrlError.replace('?&', '?');
    sessionStorage.setItem('redirectUrlError', redirectUrlError);
  }

  const paramsFromURL = sessionStorage.getItem('paramsUrl');

  sessionStorage.setItem('redirectSuccess', `https://${sessionStorage.getItem('redirectUrl')}/?&type=success${paramsFromURL}`);
  sessionStorage.setItem('redirectError', `https://${sessionStorage.getItem('redirectUrlError')}/?&type=${ErrorsPage.ERROR_SERVER}${paramsFromURL}`);
}

export const saveToggleReturn = (data: any): void => {
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (item.chave == 'ou3_logs_feature_toggle' && item.valor == 'true') {
      localStorage.setItem('toggle_logs', item.valor);
    }
  }
}

// Necessária para o antigo Register
export const dataToRegister = () => {
  const token = sessionStorage.getItem('token_session') || '';
  const decoded = jwtDecode(token);
  const result = decoded as any;
  const captureKey = localStorage.getItem('route_token');

  let payloadRegister: PayloadRegister = {};
  payloadRegister.documentos_cliente = {};

  payloadRegister.captureKey = captureKey;

  payloadRegister.consulta_bureau = result.consultaBureau;

  payloadRegister.documentos_cliente.documento = result.sub;

  const now = new Date();
  const pipe = new DatePipe('en-US');
  const now_milliseconds = now.getMilliseconds();
  const myFormattedDate = pipe.transform(now, 'yyyy-MM-dd HH:mm:ss') || now;
  const formmatdate = myFormattedDate.toString() + '.' + now_milliseconds;
  payloadRegister.datetime_operacao = formmatdate;

  payloadRegister.modelo_dispositivo = getInfosFromDevice().device_model;
  payloadRegister.fabricante_dispositivo = getInfosFromDevice().device_fac;
  payloadRegister.sistema_operacional = getInfosFromDevice().os;
  payloadRegister.versao_sistema_operacional = getInfosFromDevice().os_version;
  payloadRegister.codigo_ipv4 = getInfosFromDevice().ipv4;

  const iHeight = window.screen.height;
  const iWidth = window.screen.width;
  payloadRegister.resolucao_camera = iWidth + ' X ' + iHeight;

  payloadRegister.versao_app_canal = '0';
  payloadRegister.versao_sdk_fornecedor = '0';
  payloadRegister.versao_sdk_mobile = '0';

  payloadRegister.id_processo_origem = sessionStorage.getItem('rout_params_origin_process');

  payloadRegister.id_sessao = uuidv4();

  payloadRegister.cod_tipo_operacao = '1',
    payloadRegister.id_tipo_captura = 2;
  payloadRegister.numero_tentativa = localStorage.getItem('number_try') || '0';
  payloadRegister.mensagem_orientacao = [];

  payloadRegister.localizacao = {
    latitude: "00",
    longitude: "00",
    timezone: "Unknow",
    cidade: "Unknow",
    uf: "Unknow",
    pais: "Unknow"
  }

  return payloadRegister;
}

// Necessária para o antigo Logs
export const dataToLogs = () => {
  const captureKey = localStorage.getItem('route_token');
  const supplier = sessionStorage.getItem('supplier_session');
  const versionSDK = supplier === Supplier.FACETEC ? environment.sdkFacetecVersion : environment.sdkAwareVersion;
  const { os, device_model, os_version, userAgent } = getInfosFromDevice();
  return {
    data: {
      captureKey: captureKey,
      dispositivo: {
        deviceModel: device_model,
        os: os,
        userAgent: userAgent,
        versionApp: environment.appVersion,
        versionOS: os_version,
        versionSDK: versionSDK
      },
      idJornada: 'AppSample',
      canalOrigem: sessionStorage.getItem('cn') || 'AppSample',
      steps: []
    }
  }
}

// Necessário para o NOVA Sessão (API Parameters)
export const dataToParameters = async (): Promise<ParametersContracts> => {
  const { os, os_version, browser, browserVersion } = getInfosFromDevice();
  const cam = await getCameraInformation();

  return {
    origem: 'WEB',
    resolucao_camera: cam,
    so: os,
    versao_sistema_operacional: os_version,
    web_payload: {
      navegador: browser,
      versao_navegador: browserVersion,
      versao_app: "2.0"
    }
  }
}

// Necessário para API Match
// Ainda precisa ser integrado com o backend, atualmente não temos contrato
export const dataToMatch = async (): Promise<MatchContracts> => {
  return {
    data: ''
  }
}

