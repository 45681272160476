import { CaptureInstructionsComponent } from './capture-instructions/capture-instructions.component';
import { InstructionsPopupComponent } from './instructions-popup/instructions-popup.component';
import { VoxelButtonModule, VoxelIconModule, VoxelLinkModule } from '@voxel/internet';
import { CaptureIconComponent } from './capture-icon/capture-icon.component';
import { LockerComponent } from './locker/locker.component';
import { ButtonComponent } from './button/button.component';
import { PopupComponent } from './popup/popup.component';
import { IconComponent } from './icon/icon.component';
import { BarComponent } from './bar/bar.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutoFocusDirective } from './autofocus/auto-focus';

@NgModule({
  declarations: [
    CaptureInstructionsComponent,
    InstructionsPopupComponent,
    LockerComponent,
    PopupComponent,
    BarComponent,
    IconComponent,
    ButtonComponent,
    CaptureIconComponent,
    AutoFocusDirective,
  ],
  imports: [
    VoxelButtonModule,
    VoxelIconModule,
    VoxelLinkModule,
    CommonModule,
  ],
  exports: [
    CaptureInstructionsComponent,
    InstructionsPopupComponent,
    CaptureIconComponent,
    VoxelButtonModule,
    VoxelIconModule,
    VoxelLinkModule,
    ButtonComponent,
    LockerComponent,
    PopupComponent,
    IconComponent,
    BarComponent,
    CommonModule,
  ],
})
export class ComponentModule { }
