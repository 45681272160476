<div class="main-content" [attr.aria-hidden]="permitionModal ? 'true' : null">
  <ids-mobile-header [scrollElement]="true">
    <button idsMobileHeaderAction idsIconButton variant="neutral" title="voltar" aria-label="voltar"
      onclick="history.back()"> seta_esquerda</button>
    <button idsMobileHeaderControls idsIconButton variant="neutral" title="ajudar" aria-label="ajudar"
      (click)="callHelpPage()"> ajuda_outline</button>
  </ids-mobile-header>
  <div class="header">
    <h1 class="ids-title -small" tabindex="-1" id="main-title">Siga as dicas para fazer seu reconhecimento facial</h1>
    <div class="container-centralized" aria-hidden="true">
      <img class="main-gif" alt="gif animado de usuario com celular" aria-hidden="true" src="assets/images/lamp.svg" />
    </div>
  </div>
  <div class="list-tips">
    <ol idsList>
      <li idsLi>
        <ids-icon idsLiIcon>usuario_outline</ids-icon>
        <span idsLiText class="list-item">Prefira fazer sua foto em um ambiente tranquilo e seguro</span>
      </li>
      <li idsLi>
        <ids-icon idsLiIcon>mode_light_base</ids-icon>
        <span idsLiText class="list-item">Garanta uma boa iluminação</span>
      </li>
      <li idsLi>
        <ids-icon idsLiIcon>visivel_outline</ids-icon>
        <span idsLiText class="list-item">Mantenha o celular na altura do rosto</span>
      </li>
      <li idsLi>
        <ids-icon idsLiIcon>sorriso_outline</ids-icon>
        <span idsLiText class="list-item">Retire óculos, boné ou outros acessórios</span>
      </li>
    </ol>
  </div>
  <div class="main-footer">
    <button idsMainButton class="ids-main-button" (click)="startCam()" [disabled]="showButton" title="continuar"
      aria-label="continuar" full>
      Continuar
      <ids-icon>seta_direita</ids-icon>
    </button>
  </div>
</div>
<div *ngIf="permitionModal" class="container-modal">
  <div class="content-modal">
    <div class="header-content-modal">
      <h2 class="ids-overline-text --bold title-content-modal" tabindex="-1" id="main-title">“Itaú” Deseja ter acesso à
        sua Câmera</h2>
      <button class="close-modal" idsMobileHeaderAction idsIconButton variant="neutral" title="fechar"
        aria-label="fechar" (click)="callErrorPage()">
        fechar
      </button>
    </div>
    <div class="ids-overline-text -small text-content-modal"> Permite realizar o reconhecimento facial para sua
      segurança, a leitura
      de código de barras para pagamentos e a leitura de cheques para depósito através do aplicativo.</div>
    <div class="footer-content-modal">
      <button idsMainButton variant="secondary" class="button-secondary-content-modal" (click)="callErrorPage()"
        title="nao-permitir" aria-label="nao-permitir" full>
        Não Permitir
      </button>
      <button idsMainButton class="button-content-modal" (click)="verifyCamera()" title="permitir" aria-label="permitir"
        full>
        Permitir
      </button>
    </div>
  </div>
</div>
<loading *ngIf="this.showLoading" [showFooter]="false"></loading>
<div class="element-to-support-facetec-sdk" id="status"></div>