<div class="app-doubt">
    <app-bar title="dúvida reconhecimento facial" [backButton]="true"></app-bar>
    <div class="app-doubt-body">
        <div class="orientations">
            <p>Coletaremos e usaremos o seu reconhecimento facial para sua segurança, inclusive para confirmar sua identidade com outras instituições.</p>
            <p>Siga as dicas e depois continue com o processo de reconhecimento facial, deixe o celular na altura dos olhos e tire a foto em um local iluminado, sem óculos, boné ou outro acessório.</p>
            <p>Qualquer dúvida entrar em contato com nossa central de atendimento:</p>
            <p><strong>0300 100 1213</strong></p>
        </div>
        <div class="opening-hours">
            <p>Horários de atendimento:</p>
            <p>Dias úteis</p>
            <p><strong>das 07h às 22h</strong></p>
            <p>Fins de semana e feriados</p>
            <p><strong>das 08h às 22h</strong></p>
        </div>
    </div>
</div>