export const environment: any = {
  production: false,
  apiUrl: 'http://localhost:3000',
  name: 'homol environment',
  publicKey: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5OE51RONA9/3krgwit97M9/MAVKnL5flJrviAIh02VsPJM2AJ5gX9w/jLgB2RgawpsK0xRSF+ZIS8oTDm36shZJI6cfoR3E1vqlDi68k3ffPTmQ7UQ6tknV/xMAEFK9WMwQcbho8VaBU73E++Vxr8MooApw7yM6+IyrShAYu0hNY9CabV8s87Q3xqCHmAqucn8qUO3in/lHlm6VVDnbpODJrkqS5TDv+gHDEPGI5wL1CUhUmI6C6YmH902bzf4nqC65385VNCUFW46VgS8jYfF87efLjtORjDZWBZM/KNToMqC3OscN7uCnvk3Y83tmnPttKyHqrxixBqYaANsWJFwIDAQAB',
  baseUrl: 'https://facialwebcapture.hom.cloud.itau.com.br',
  charonUrl: 'https://apicd.hom.cloud.itau.com.br/charon/biofacw',
  newCharonID: '6idar4se',
  newCharonUrl: "https://apicd.hom.cloud.itau.com.br/charon/6idar4se",
  disableCripto: false,
  dataDogID: '63583956-79e7-424a-9581-8d5f1570ec3f',
  dataDogTK: 'pub4fa705beb6a1e1df006bd70a0cf9ef51',
  isProd: false,
  tryQuantity: 5,
  appVersion: "2.0.2",
  sdkFacetecVersion: "9.7.27",
  sdkAwareVersion: "3.1",
};

