import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QualityPage } from '../../interfaces/ResponsesServices';
import { ItauDigitalAnalyticsView } from '@app-analytics/analytics';
import { LOADING_ANALISE, LOADING_PAGE } from '../../shared/helpers/paramsGa4';

@Component({
  selector: 'quality-root',
  templateUrl: './quality.component.html',
  styleUrls: ['./quality.component.scss'],
})
export class QualityComponent {
  loading: boolean = true;
  qualityLoadingExtra: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private itauDigitalAnalytics: ItauDigitalAnalyticsView
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.loading = false;
      this.qualityLoadingExtra = true;
      this.itauDigitalAnalytics.track(LOADING_ANALISE);
      this.callResult()
    }, 2000);
    this.itauDigitalAnalytics.track(LOADING_PAGE);
  }

  callResult() {
    switch (this.route.snapshot?.queryParams?.result) {
      case QualityPage.SUCCESS:
        this.router.navigate(['redirect/success'], { skipLocationChange: true });
        break;
      case QualityPage.ERROR:
        const redirectError = localStorage.getItem('redirectError');
        window.document.location.href = redirectError || 'https://www.itau.com.br';
        break;
    }
  }
}
