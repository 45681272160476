import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { delay, distinctUntilChanged } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { LoadingService } from './services/loading/loading.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
  text = '123';
  loading: boolean;
  private subs1: Subscription;

  constructor(
    private loadingService: LoadingService,
    private liveAnnouncer: LiveAnnouncer,
    private newSpinner: NgxSpinnerService,
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.subs1 = this.loadingService.loading.pipe(distinctUntilChanged(), delay(100)).subscribe((isloading: boolean) => {
      if (isloading) {
        this.newSpinner.show('first');
        this.liveAnnouncer.announce('Carregando', 'assertive', 3500);
      } else {
        this.newSpinner.hide('first');
        this.liveAnnouncer.announce('Carregado', 'assertive', 3500);
      }
      this.loading = isloading;
    });
  }

  ngOnDestroy() {
    this.subs1.unsubscribe();
  }

  getLoading() {
    return this.loading;
  }
}
