import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  type = 'general';
  errortype = '';
  message = 'desculpe, a biometria facial está indisponível no nomento';
  submessage = 'não foi possível carregar as informações';
  date = '';
  nome = '';
  textobotao = 'tentar novamente';
  page: any;
  key_capture: any;
  cpf_idpessoa: any;
  origin_process: any;
  link_redirect: any;
  tentativas_old: any;

  caronte_session = sessionStorage.getItem('caronte_session');
  caronte_header = sessionStorage.getItem('caronte_header');
  captureKey = sessionStorage.getItem('captureKey');
  token = sessionStorage.getItem('token');
  redirect_url = sessionStorage.getItem('redirect_url');
  baseurl = sessionStorage.getItem('baseurl');
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.verificaDados(params.type, params.errortype);
    });
  }

  redirectUrl() {
    window.document.location.href = 'https://' + this.redirect_url + '/?&type=error_no_capture';
  }

  go() {
    window.document.location.href = 'https://' + this.redirect_url + '/?&type=error_no_capture';
  }

  go2(to: string) {
    this.router.navigateByUrl(to);
  }

  verificaDados(p_result: string, p_errortype: string) {
    this.type = p_result;
    if ((this.type !== 'error_no_capture') && (this.type !== 'error_no_indication') && (this.type !== 'error_risk_a') && (this.type !== 'error_risk_b') && (this.type !== 'error_risk_ab') && (this.type !== 'error_no_capture')) {
      this.type = 'general';
      this.textobotao = 'tentar de novo';
    }

    this.errortype = p_errortype;
    this.page = sessionStorage.getItem('page');
    this.key_capture = sessionStorage.getItem('key_capture');
    this.cpf_idpessoa = sessionStorage.getItem('cpf_idpessoa');
    this.origin_process = sessionStorage.getItem('origin_process');

    if ((this.type !== '') && (this.type !== 'undefined') && (this.type !== undefined) && (this.type !== null) && (this.type === 'error_no_capture') && (this.errortype !== '') && (this.errortype !== 'undefined') && (this.errortype !== undefined) && (this.errortype !== null) && (this.errortype === 'type1')) {
      this.message = 'desculpe, algo deu errado';
      this.submessage = 'Não foi possível carregar as informações neste momento.';
      this.date = '';
      this.textobotao = 'tentar novamente';
    } else if ((this.type !== '') && (this.type !== 'undefined') && (this.type !== undefined) && (this.type !== null) && (this.type === 'error_no_capture') && (this.errortype !== '') && (this.errortype !== 'undefined') && (this.errortype !== undefined) && (this.errortype !== null) && (this.errortype === 'type2')) {
      this.message = 'desculpe, algo deu errado';
      this.submessage = 'Não foi possível carregar as informações neste momento.';
      this.date = '';
      this.textobotao = 'retornar ao site';
    } else if ((this.type !== '') && (this.type !== 'undefined') && (this.type !== undefined) && (this.type !== null) && (this.type === 'error_no_capture') && (this.errortype !== '') && (this.errortype !== 'undefined') && (this.errortype !== undefined) && (this.errortype !== null) && (this.errortype === 'type3')) {
      this.message = 'ops, sua câmera não está respondendo';
      this.submessage = 'Sem sua câmera, não é possível tirar sua foto para o reconhecimento facial.';
      this.date = '';
      this.textobotao = 'ok, entendi';
    }

    if (this.errortype === 'type2') {
      sessionStorage.setItem('tentativas', '');
    }
  }
}
