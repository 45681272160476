
<div class="app-cam opacity-initial">
    <div [attr.aria-hidden]="!close_modal" class="box-initial" [ngClass]="{'disabled': !close_modal}">
        <div class="opacity{{ opacity_in }}">
            <h1 id="tipsPhoto" class="title" aria-label="dicas para sua foto" tabindex="0">dicas para sua foto</h1>
            
            <div class="content-image-position">
                <img aria-hidden="true" src="assets/Group_10.png" />
            </div>
            <div class="content-fixed-botton">
                <div class="list-tips">
                    <ul>

                        <li>
                            <ul class="line">
                                <li class="image"><img aria-hidden="true" src="assets/lamb.png" /></li>
                                <li class="text">esteja em um local iluminado;</li>
                            </ul>
                            <br clear="all" />                     
                        </li>


                        <li>
                            <ul class="line">
                                <li class="image"><img aria-hidden="true" src="assets/view.png" /></li>
                                <li class="text">olhe diretamente para a câmera. </li>
                            </ul>
                            <br clear="all" />                     
                        </li>

                        <li>
                            <ul class="line">
                                <li class="image"><img aria-hidden="true" src="assets/attention.png" /></li>
                                <li class="text">retire boné, óculos ou outros objetos; </li>
                            </ul>
                            <br clear="all" />                     
                        </li>                
                        
                    </ul>
                </div>
            </div>
            <div class="content-fixed-botton">
                <button id="inicio" class="voxel-button" type="button" (click)="go()"><span >voltar para o ínicio</span></button>
            </div>
            
        </div>
    </div>
    <div class="box-permission" [ngClass]="{'close-modal-true': close_modal}">
        <div class="fechar" (click)="closeModal('Fechar')">
            <button class="close-modal-background" data-message="Fechar" aria-label="Fechar"><img aria-hidden="true" src="assets/fechar.png" /></button>
        </div>
        <!--
        <h2 tabindex="1">Libere o acesso à câmera nas configurações do seu navegador</h2>
    
        <p>Você negou o acesso à sua câmera anteriormente. Atualize as configurações do seu navegador para continuar.</p>
        -->
        <div *ngIf="tipo_mobile != 'ios'">
            <h2 tabindex="1">Libere o acesso à câmera no seu navegador</h2>
            <p>1. Acesse o ícone <img aria-hidden="true" src="assets/menu_mobile.png" class="browser_top" /> ao lado da sua URL.</p>
            <p>2. Clique no menu <b>Configurações</b>.</p>
            <p>3. Selecione a opção <b>Configurações do Site</b>.</p>
            <p>4. Clique no ícone de <b>Câmera</b>.</p>
            <p>5. Selecione dentro da opção <b>Bloqueado</b> o Link <b>https://facialwebcapture.cloud.itau.com.br</b>.</p>
            <p>6. Clique na opção <b>Remover</b>.</p>
            <p>7. Volte para a tela de Captura.</p>
            <button class="voxel-button" type="button" (click)="closeModal('OK')" data-message="ok, entendi">ok, entendi</button>
        </div>
        <div *ngIf="tipo_mobile == 'ios'">
            <h2 tabindex="1">Libere o acesso à câmera no seu navegador</h2>
            <p>1. Clique no ícone "aA" ao lado do endereço do site.</p>
            <p>2. Clique em "Ajustes do Site".</p>
            <p>3. Clique na Opção <b>Câmera</b> e selecione a Opção Permitir.</p>
            <p>4. Volte para a tela de Captura.</p>
            <button class="voxel-button" type="button" (click)="closeModal('OK')" data-message="ok, entendi">ok, entendi</button>
        </div>
    </div>    
    
</div>

        
