import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ItauDigitalAnalyticsView } from '@app-analytics/analytics';
import { LOADING_FIRST } from '../../shared/helpers/paramsGa4';
import { ErrorsPage } from '../../interfaces/ErrorsPage';
import { TypesMessages } from '../../interfaces/ErrorsServices';
import { InitFactoryService } from '../../factories/initfactory.service';

@Component({
  selector: 'main-loading-root',
  templateUrl: './main-loading.component.html',
  styleUrls: ['./main-loading.component.scss'],
})
export class MainLoadingComponent {
  loading: boolean = true;
  loadingTwo: boolean = false;
  loadingFinish: boolean = false;

  constructor(
    private router: Router,
    public factory: InitFactoryService,
    private itauDigitalAnalytics: ItauDigitalAnalyticsView
  ) { }

  ngOnInit() {
    this.itauDigitalAnalytics.track(LOADING_FIRST);
    this.init();
  }

  async init() {
    const inicialization = await this.factory.initializeFactory();
    if (inicialization !== TypesMessages.SUCCESS) {
      this.router.navigate(['redirect/error'], { queryParams: { errorCode: ErrorsPage.FAIL_KEY }, skipLocationChange: true });
    } else {
      this.router.navigate(['redirect/home'], { queryParams: { type: false }, skipLocationChange: true });
    }
  }
}
