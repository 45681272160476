<div class="app-error">

    <app-bar title="&nbsp;" *ngIf="type == 'error_risk_ab'">
        
    </app-bar>

    <div class="icon-content">
        <div class="icon-box" *ngIf="type != 'error_risk_ab'">
            <img aria-hidden="true" src="assets/fechar.png" />
        </div>
    </div>
    
    <div class="app-error-body">
        <div class="box-error">
            <div class="content box-content-position">
                <div class="animation" *ngIf="type != 'error_no_capture' && type != 'error_risk_ab' && type != 'error_no_indication'">
                    <img aria-hidden="true" src="assets/celular_roxo.png" />
                </div>
                <div class="animation" *ngIf="type == 'error_risk_ab'">
                    <img aria-hidden="true" src="assets/celular_roxo.png" />
                </div>
                <div class="animation" *ngIf="type == 'error_no_capture2'">
                    <img aria-hidden="true" src="assets/Triangle.png" />
                </div>
                <div class="animation" *ngIf="type == 'error_no_capture' || type == 'error_no_indication'">
                    <div>
                        <img aria-hidden="true" src="assets/celular_roxo.png" />
                    </div>
                </div>
            </div>
            <div class="content box-content-position-two">
                <div class="box-margin">
                    <h2 autofocus>{{ message }}</h2>
                    <div>
                        <div class="message">
                            {{ submessage }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-fixed-botton">
                <div class="box-margin">
                    <div class="action-buttons">
                        <div class="action-button">
                            <div *ngIf="errortype == 'type2'">
                                <app-button [fullWidth]="true" label='tentar novamente' (click)="redirectUrl()">
                                </app-button>
                            </div>
                            <div *ngIf="errortype != 'type2'">
                                <app-button [fullWidth]="true" label='{{ textobotao }}' (click)="go()">
                                </app-button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>