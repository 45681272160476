import { RouterModule, Routes } from '@angular/router';
import { Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { VoxelLinkModule, VoxelButtonModule, VoxelAlertModule } from '@voxel/internet';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'capture',
    pathMatch: 'full',
  },
  {
    path: 'capture',
    loadChildren: () => import('./pages/capture.module').then(m => m.CaptureModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    HttpClientModule,
    VoxelLinkModule,
    VoxelButtonModule,
    VoxelAlertModule,
  ],
  providers: [
    Location,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
