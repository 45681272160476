<div class="wrapper">
  <ids-mobile-header [scrollElement]="true">
    <button idsMobileHeaderAction idsIconButton variant="neutral" title="voltar" aria-label="voltar"
      onclick="history.back()"> seta_esquerda</button>
    <button idsMobileHeaderControls idsIconButton variant="neutral" title="ajudar" aria-label="ajudar"
      (click)="callHelpPage()"> ajuda_outline</button>
  </ids-mobile-header>
  <div class="header">
    <h1 class="ids-title -small" tabindex="-1" id="main-title">Tente novamente seguindo essas dicas para tirar sua foto</h1>
    <div class="container-centralized" aria-hidden="true">
      <img class="main-gif" alt="gif animado de usuario com celular" aria-hidden="true" src="assets/images/lamp.svg" />
    </div>
  </div>
  <div class="list-tips">
    <ol idsList>
      <li idsLi>
        <ids-icon idsLiIcon>usuario_outline</ids-icon>
        <span idsLiText class="list-item">Prefira fazer sua foto em um ambiente tranquilo e seguro</span>
      </li>
      <li idsLi>
        <ids-icon idsLiIcon>mode_light_base</ids-icon>
        <span idsLiText class="list-item">Garanta uma boa iluminação</span>
      </li>
      <li idsLi>
        <ids-icon idsLiIcon>visivel_outline</ids-icon>
        <span idsLiText class="list-item">Mantenha o celular na altura do rosto</span>
      </li>
      <li idsLi>
        <ids-icon idsLiIcon>sorriso_outline</ids-icon>
        <span idsLiText class="list-item">Retire óculos, boné ou outros acessórios</span>
      </li>
    </ol>
  </div>
  <div class="main-footer">
    <button id="main-button" idsMainButton (click)="startCam()" title="continuar" aria-label="continuar" full>
      Continuar
      <ids-icon>seta_direita</ids-icon>
    </button>
  </div>
</div>