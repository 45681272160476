import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CatchError } from '../types/error';

@Injectable({
	providedIn: 'root',
})
export class HttpService {
	constructor(
		private http: HttpClient,
	) { }

	public postData(url, data, params, callback?: (e: any) => void, errorCallback?: (e: any) => void, afterCallback?: () => void) {
		let additionalHeaders: any = {};

		if (params?.type_liveness === 'facetec') {
			additionalHeaders = {
				'liveness-engine': 'facetec',
			}
		}

		if (params?.logCode) {
			additionalHeaders = {
				'log-code': params.logCode,
			}
		}

		const headers = new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Credentials': 'true',
			'Access-Control-Allow-Methods': 'GET, POST, PUT',
			'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
			'x-itau-apikey': params?.apikey,
			'Content-Type': 'application/json',
			'X-Charon-Session': params?.session,
			'x-itau-flowID': params?.apikey,
			'x-itau-correlationID': params?.correlationID,
			'aes': params?.key,
			'Authorization': 'Bearer ' + params?.token,
			...additionalHeaders,
		});

		const options = { headers: headers };



		try {
			this.http.post(url, data, options).subscribe(
				(res: any) => {
					if (callback) callback(res);
				},
				(error: any) => {
					if (error) errorCallback(error);
				},
				() => {
					if (afterCallback) afterCallback();
				},
			);
		} catch (error) {
			console.error(CatchError.sendLogsFail, `Error: ${error}`)
		}
	}
}