import { Component } from '@angular/core';
import { AngularSampleApp } from 'src/assets/facetec/angular-sample-controller.utils';

@Component({
  selector: 'app-home-root',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  title = 'poc-liveness';

  constructor() {
  }

  onLivenessCheckPressed() {
    console.log('teste-1');
    AngularSampleApp.resetResponseObservable(undefined, this);
  }

  homeTest(json: string) {
    console.log('json-home:', json);
  }
}
