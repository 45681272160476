import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-locker',
  templateUrl: './locker.component.html',
  styleUrls: ['./locker.component.scss'],
})
export class LockerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
