import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
// import { VoxelLoadingCircleModule } from '@voxel/components';
import { SegmentTypes, VoxelConfigModule } from '@voxel/internet';
import { WINDOW, windowFactory } from 'src/assets/lib/tag/window.token';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component.utils';
import { CaptureModule } from './pages/capture.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderComponent } from './components/loader/loader.component.utils';
import { ErrorcallsService } from './services/errorcalls/errorcalls.service';
import { LoadingService } from './services/loading/loading.service';
import { ItauSdkCoreAnalyticsModule } from '@app-analytics/analytics';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    VoxelConfigModule.forRoot({
      production: environment.production,
      segment: SegmentTypes.Varejo,
    }),
    ReactiveFormsModule,
    CaptureModule,
    // VoxelLoadingCircleModule,
    NgxSpinnerModule,
    ItauSdkCoreAnalyticsModule.forRoot({ dual_tagging: true })
  ],
  providers: [
    {
      provide: WINDOW,
      useFactory: windowFactory,
    },
    LoadingService,
    ErrorcallsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
