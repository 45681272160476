import { Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { MainComponent } from './main/main.component';
import { Routes, RouterModule } from '@angular/router';
import { AwareComponent } from './aware/aware.component';
import { ErrorComponent } from './error/error.component';
import { SuccessComponent } from './success/success.component';
import { NewTryComponent } from './new-try/new-try.component';
import { HelpComponent } from './help/help.component';
import { QualityComponent } from './quality/quality.component';
import { MainLoadingComponent } from './main-loading/main-loading.component';

const routes: Routes = [
  { path: 'cam', component: MainLoadingComponent },
  { path: 'redirect/home', component: MainComponent },
  { path: 'redirect/error', component: ErrorComponent },
  { path: 'redirect/success', component: SuccessComponent },
  { path: 'redirect/quality', component: QualityComponent },
  { path: 'redirect/aware', component: AwareComponent },
  { path: 'redirect/new_try', component: NewTryComponent },
  { path: 'redirect/help', component: HelpComponent },
  { path: '', redirectTo: 'cam', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [
    Location,
  ],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
