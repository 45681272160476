import { Component, OnInit } from '@angular/core';
import { ErrorcallsService } from 'src/app/services/errorcalls/errorcalls.service';

@Component({
  selector: 'app-error-content',
  templateUrl: './error-content.component.html',
  styleUrls: ['./error-content.component.scss'],
})
export class ErrorContentComponent implements OnInit {

  constructor(private errorContent: ErrorcallsService) { }

  ngOnInit(): void {
  }

  isDefaultError(): boolean {
    return this.isFacetecError();
  }

  isFacetecError(): boolean {
    return this.errorContent.isFacetecError();
  }

}
