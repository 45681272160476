import { Config } from '../Config';
import { FaceTecSessionResult, FaceTecIDScanResult } from '../core-sdk/FaceTecSDK.js/FaceTecPublicApi';
import { FaceTecSDK } from '../core-sdk/FaceTecSDK.js/FaceTecSDK';
import { SampleAppUIFunctions } from './SampleAppUIFunctions';
// import { UI } from './UIFunctions.utils';

export let SampleAppUtilities = (() => {

  const vocalGuidanceSoundFilesDirectory = '../../sample-app-resources/Vocal_Guidance_Audio_Files/';

  // const enum VocalGuidanceMode {
  //   MINIMAL,
  //   FULL,
  //   OFF,
  // }

  // const vocalGuidanceOnPlayer = new Audio(vocalGuidanceSoundFilesDirectory + 'vocal_guidance_on.mp3');
  // const vocalGuidanceOffPlayer = new Audio(vocalGuidanceSoundFilesDirectory + 'vocal_guidance_off.mp3');
  const vocalGuidanceOnPlayer = new Audio(vocalGuidanceSoundFilesDirectory);
  const vocalGuidanceOffPlayer = new Audio(vocalGuidanceSoundFilesDirectory);
  vocalGuidanceOnPlayer.volume = 0.0;
  vocalGuidanceOffPlayer.volume = 0.0;
  // vocalGuidanceOffPlayer.onended = () => {
  //   enableVocalGuidanceButtons();
  // };
  // vocalGuidanceOnPlayer.onended = () => {
  //   enableVocalGuidanceButtons();
  // };

  let vocalGuidanceMode = 2;

  // tslint:disable-next-line: early-exit
  // tslint:disable-next-line: no-unused-expression
  function setVocalGuidanceMode() {
    // disableVocalGuidanceButtons();

    vocalGuidanceMode = vocalGuidanceMode;

    (document.getElementById('vocal-guidance-icon-minimal') as HTMLElement).style.display = 'none';
    (document.getElementById('vocal-guidance-icon-full') as HTMLElement).style.display = 'none';
    (document.getElementById('vocal-guidance-icon-off') as HTMLElement).style.display = 'block';

    vocalGuidanceOffPlayer.play();
    Config.currentCustomization.vocalGuidanceCustomization.mode = vocalGuidanceMode;

    FaceTecSDK.setCustomization(Config.currentCustomization);
  }

  function downloadURI(uri: string, name: string) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function displayStatus(message: string) {
    (document.getElementById('status') as HTMLElement).innerHTML = message;
  }

  function fadeInMainUIContainer() {
    SampleAppUIFunctions('.loading-session-token-container').fadeOut(1);
    SampleAppUIFunctions('#theme-transition-overlay').fadeOut(800);
    SampleAppUIFunctions('.wrapping-box-container').fadeIn(800);
  }

  function fadeInMainUIControls(callback?: () => void) {
    if (isLikelyMobileDevice()) {
      SampleAppUIFunctions('footer').fadeIn(800);
      SampleAppUIFunctions('#custom-logo-container').fadeIn(800);
      SampleAppUIFunctions('#vocal-icon-container').fadeIn(800);
    }
    SampleAppUIFunctions('#controls').fadeIn(800, () => {
      enableControlButtons();
      // enableVocalGuidanceButtons();
      if (callback) {
        callback();
      }
    });
  }

  // Disable buttons to prevent hammering, fade out main interface elements, and reset the Session Review Screen data.
  function fadeOutMainUIAndPrepareForSession() {
    disableControlButtons();
    if (isLikelyMobileDevice()) {
      SampleAppUIFunctions('footer').fadeOut(800);
      SampleAppUIFunctions('#custom-logo-container').fadeOut(800);
      SampleAppUIFunctions('#vocal-icon-container').fadeOut(800);

      // disableVocalGuidanceButtons();
    }
    SampleAppUIFunctions('#controls').fadeOut(800);
    SampleAppUIFunctions('.wrapping-box-container').fadeOut(800);
    SampleAppUIFunctions('#theme-transition-overlay').fadeIn(800);


    (document.getElementById('DOM_FT_accessibilityDefaultFocusElement') as HTMLElement).style.fontSize = '60px';
    (document.getElementById('DOM_FT_feedbackStringMeasurementSpan1') as HTMLElement).style.fontSize = '60px';
    (document.getElementById('DOM_FT_feedbackStringMeasurementSpan2') as HTMLElement).style.fontSize = '80px';
    (document.getElementById('DOM_FT_feedbackStringMeasurementSpan3') as HTMLElement).style.fontSize = '100px';
      // Hide status label text background and decrease label font size
      if (document.getElementById('controls') != null) {
        (document.getElementById('status') as HTMLElement).style.fontSize = '40px';
      }


  }

  function showLoadingSessionToken() {
    SampleAppUIFunctions('.loading-session-token-container').fadeIn(300);
  }

  function hideLoadingSessionToken() {
    SampleAppUIFunctions('.loading-session-token-container').fadeOut(800);
  }

  function disableControlButtons() {
    document.querySelectorAll('#controls > button').forEach(button => {
      button.setAttribute('disabled', 'true');
    });
  }

  function enableControlButtons() {
    document.querySelectorAll('#controls > button').forEach(button => {
      button.removeAttribute('disabled');
    });

    document.querySelectorAll('.vocal-icon').forEach(icon => {
      icon.removeAttribute('disabled');
    });
  }

  function showMainUI() {
    fadeInMainUIContainer();
    fadeInMainUIControls();
  }

  function handleErrorGettingServerSessionToken() {
    showMainUI();
    displayStatus('Session could not be started due to an unexpected issue during the network request.');
  }

  // Substituir essa função para o BFF - UUID.random()
  // GUID
  function generateUUId() {
    // @ts-ignore
    // tslint:disable-next-line: no-bitwise
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16),
    );
  }

  function newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      // tslint:disable: no-bitwise
      // tslint:disable-next-line: one-variable-per-declaration
      const r = Math.random() * 16 | 0,
        v = c === 'x' ? r : (r & 0x3 | 0x8);
      // tslint:enable: no-bitwise
      return v.toString(16);
    });
  }

  function formatUIForDevice() {
    // tslint:disable-next-line: early-exit
    if (isLikelyMobileDevice()) {
      const windowWidth = window.innerWidth;

      // Adjust button sizing
      document.querySelectorAll('button').forEach(element => {
        if (element.className === 'big-button') {
          (element as HTMLElement).style.height = '40px';
          if (windowWidth <= 320) {
            (element as HTMLElement).style.fontSize = '16px';
          } else {
            (element as HTMLElement).style.fontSize = '18px';
          }
        } else if (element.className === 'medium-button') {
          (element as HTMLElement).style.height = '30px';
          (element as HTMLElement).style.fontSize = '14px';
        }

        // (element as HTMLElement).style.width = '60%';
      });
      // Adjust main interface display
      if (document.getElementById('main-interface') != null) {
        (document.getElementById('main-interface') as HTMLElement).style.display = 'contents';
        (document.getElementById('main-interface') as HTMLElement).style.backgroundColor = 'transparent';

      }

      // Hide border around control panel and adjust height
      if (document.getElementById('controls') != null) {
        (document.getElementById('controls') as HTMLElement).style.height = 'auto';
        (document.getElementById('controls') as HTMLElement).style.borderColor = 'transparent';
        (document.getElementById('controls') as HTMLElement).style.backgroundColor = 'transparent';

      }



      // Hide status label text background and decrease label font size
      if (document.getElementById('controls') != null) {

        (document.getElementById('status') as HTMLElement).style.backgroundColor = 'transparent';
        (document.getElementById('status') as HTMLElement).style.fontSize = '12px';
        (document.getElementById('status') as HTMLElement).style.position = 'inherit';
        (document.getElementById('status') as HTMLElement).style.width = '90%';
        (document.getElementById('status') as HTMLElement).style.margin = '0 auto';
        (document.getElementById('status') as HTMLElement).style.bottom = 'unset';


      }

      // Move and update vocal guidance icon
      if (document.getElementById('vocal-icon-container') != null) {
        (document.getElementById('vocal-icon-container') as HTMLElement)!.parentNode!.parentNode!.parentNode!.parentNode!.insertBefore(document.getElementById('vocal-icon-container')!,
          (document.getElementById('vocal-icon-container') as HTMLElement)!.parentNode!.parentNode!.parentNode!.parentNode!.firstChild);
        document.querySelectorAll('.vocal-icon').forEach(icon => {
          (icon as HTMLElement).style.height = '30px';
          (icon as HTMLElement).style.margin = '20px';
          (icon as HTMLElement).style.transform = 'translateX(calc(-100% - 40px))';
        });

      }

      // Move logo above buttons
      if (document.getElementById('custom-logo-container') != null) {
        (document.getElementById('custom-logo-container') as HTMLElement)!.parentNode!.insertBefore(document.getElementById('custom-logo-container')!, document.getElementById('custom-logo-container')!.parentNode!.firstChild);
        (document.getElementById('custom-logo-container') as HTMLElement)!.style.margin = '0px 0px 20px 0px';
        (document.querySelector('#custom-logo-container img') as HTMLElement).style.height = '40px';

      }
      
      // Center control interface on screen
      if (document.getElementById('wrapping-box-container') != null) {
        (document.getElementsByClassName('wrapping-box-container')[0] as HTMLElement).style.top = '50%';
        (document.getElementsByClassName('wrapping-box-container')[0] as HTMLElement).style.left = '50%';
        (document.getElementsByClassName('wrapping-box-container')[0] as HTMLElement).style.transform = 'translate(-50%, -50%)';

      }
      
      // Adjust button margins
      if (document.getElementById('liveness-button') != null) {
        (document.getElementById('liveness-button') as HTMLElement).style.marginTop = 'unset';
        (document.getElementById('design-showcase-button') as HTMLElement).style.marginBottom = 'unset';
      }
      
      // Setup footer sizing
      let footerFontSize = '100%';
      if (windowWidth < 768) {
        footerFontSize = '9px';
      }
      if (windowWidth < 415) {
        footerFontSize = '8px';
      }
      if (windowWidth <= 360) {
        footerFontSize = '7px';
      }

      SampleAppUIFunctions('footer').css({
        'font-size': footerFontSize,
        'line-height': '9px',
      });
      SampleAppUIFunctions('footer span p').css({ 'font-size': 'inherit' });
      SampleAppUIFunctions('footer span, footer span p').css({ margin: 0 });
      if (document.querySelector('hr') != null) {
        (document.querySelector('hr') as HTMLElement).classList.remove('display-none');
      }
      
      // const computedFooterFontSize = window.getComputedStyle((document.querySelector('footer span p') as HTMLElement)).fontSize;
      // SampleAppUIFunctions('#copy-right-length').css({ 'font-size': computedFooterFontSize });
      // const copyRightStringLength = (document.getElementById('copy-right-length') as HTMLElement).clientWidth;
      // SampleAppUIFunctions('hr').css({ width: copyRightStringLength + 'px' });

      // Allow time for the UI to fully load before fading in the body
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          displayElementsAfterStyling();
        });
      });
    } else {
      displayElementsAfterStyling();
    }
  }

  function displayElementsAfterStyling() {
    document.querySelectorAll('button').forEach(element => {
      element.classList.add('button-transitions');
    });
    SampleAppUIFunctions('footer').fadeIn(800);
    SampleAppUIFunctions('body').fadeIn(800);
  }

  // function disableVocalGuidanceButtons() {
  //   document.querySelectorAll('.vocal-icon').forEach(button => {
  //     (button as HTMLButtonElement).setAttribute('disabled', 'true');
  //   });
  // }

  // function enableVocalGuidanceButtons() {
  //   document.querySelectorAll('.vocal-icon').forEach(button => {
  //     (button as HTMLButtonElement).removeAttribute('disabled');
  //   });
  // }

  function isLikelyMobileDevice() {
    let isMobileDeviceUA = !!(/Android|iPhone|iPad|iPod|IEMobile|Mobile|mobile/i.test(navigator.userAgent || ''));
    // ChromeOS/Chromebook detection.
    // tslint:disable-next-line: triple-equals
    if (isMobileDeviceUA && ((navigator.userAgent.indexOf('CrOS') != -1) || (navigator.userAgent.indexOf('Chromebook') != -1))) {
      isMobileDeviceUA = false;
    }
    // Mobile device determination based on portrait / landscape and user agent.
    if (screen.width < screen.height || isMobileDeviceUA) {
      // Assume mobile device when in portrait mode or when determined by the user agent.
      return true;
    }
    return false;
  }

  function disableAllButtons() {
    (document.getElementById('enroll-button') as HTMLElement).setAttribute('disabled', 'true');
    (document.getElementById('id-scan-button') as HTMLElement).setAttribute('disabled', 'true');
    (document.getElementById('liveness-button') as HTMLElement).setAttribute('disabled', 'true');
    (document.getElementById('authenticate-button') as HTMLElement).setAttribute('disabled', 'true');
    (document.getElementById('audit-trail-button') as HTMLElement).setAttribute('disabled', 'true');
    (document.getElementById('design-showcase-button') as HTMLElement).setAttribute('disabled', 'true');
  }

  function enableAllButtons() {
    (document.getElementById('enroll-button') as HTMLElement).removeAttribute('disabled');
    (document.getElementById('id-scan-button') as HTMLElement).removeAttribute('disabled');
    (document.getElementById('liveness-button') as HTMLElement).removeAttribute('disabled');
    (document.getElementById('authenticate-button') as HTMLElement).removeAttribute('disabled');
    (document.getElementById('audit-trail-button') as HTMLElement).removeAttribute('disabled');
    (document.getElementById('design-showcase-button') as HTMLElement).removeAttribute('disabled');
  }

  function fadeInBlurOverlay() {
    (document.getElementById('controls') as HTMLElement).classList.add('blur-content');
  }

  function fadeOutBlurOverlay() {
    if ((document.getElementById('controls') as HTMLElement).classList.contains('blur-content')) {
      (document.getElementById('controls') as HTMLElement).classList.remove('blur-content');
    }
  }

  function showAuditTrailImages(sessionResult: FaceTecSessionResult | null, idScanResult: FaceTecIDScanResult | null) {
    const auditTrailImages: string[] = [];
    // tslint:disable-next-line: early-exit
    if (sessionResult != null && sessionResult.auditTrail != null && sessionResult.auditTrail.length > 0) {
      disableAllButtons();
      fadeInBlurOverlay();
      // Add the regular audit trail images
      sessionResult.auditTrail.forEach((image: string) => {
        auditTrailImages.push('data:image/jpeg;base64,' + image);
      });
      if (idScanResult != null && idScanResult.frontImages != null && idScanResult.frontImages.length > 0) {
        // Add ID Scan front images
        auditTrailImages.unshift('data:image/jpeg;base64,' + idScanResult.frontImages[0]);
      }
      auditTrailImages.forEach(img => {
        addDismissibleImagePopUp(img);
      });
    } else {
      displayStatus('No Audit Trail Images');
    }
  }

  function addDismissibleImagePopUp(img: string) {
    const auditTrailOverlay = document.createElement('div');
    const auditTrailImage = new Image();
    auditTrailImage.src = img;
    auditTrailImage.classList.add('audit-trail-image');
    auditTrailOverlay.classList.add('audit-trail-overlay');
    auditTrailOverlay.onclick = () => {
      // tslint:disable-next-line: early-exit
      if (document.querySelectorAll('.audit-trail-overlay').length === 1) {
        fadeOutBlurOverlay();
        auditTrailOverlay.style.transition = '0.3s';
        auditTrailOverlay.style.opacity = '0';
        const _this = this;

        setTimeout(() => {
          enableAllButtons();
          (_this as HTMLElement).remove();
        }, 500);
      } else {
        (this as HTMLElement).remove();
      }
    };
    auditTrailOverlay.append(auditTrailImage);
    (document.getElementById('controls') as HTMLElement).append(auditTrailOverlay);
  }

  return {
    setVocalGuidanceMode,
    downloadURI,
    displayStatus,
    fadeInMainUIContainer,
    fadeInMainUIControls,
    fadeOutMainUIAndPrepareForSession,
    disableControlButtons,
    enableControlButtons,
    generateUUId,
    newGuid,
    formatUIForDevice,
    showMainUI,
    hideLoadingSessionToken,
    showLoadingSessionToken,
    isLikelyMobileDevice,
    UI: SampleAppUIFunctions,
    showAuditTrailImages,
    handleErrorGettingServerSessionToken,
  };
})();
