import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-errorrisk',
  templateUrl: './errorrisk.component.html',
  styleUrls: ['./errorrisk.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorriskComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    console.log('error_risk');
  }
}
