import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../../services/main.service';
import { LOGS_NEW_TRY_PAGE } from '../../shared/helpers/stepsToLogs';
import { InitFactoryService } from '../../factories/initfactory.service';

@Component({
  selector: 'app-new-try-root',
  templateUrl: './new-try.component.html',
  styleUrls: ['./new-try.component.scss'],
})
export class NewTryComponent {
  permitionModal: boolean = false;

  constructor(
    private router: Router,
		public mainService: MainService,
    public factory: InitFactoryService,
  ) { }

  ngOnInit() {
    if(localStorage.getItem('toggle_logs') === 'true') {
      this.mainService.insightsAPI(LOGS_NEW_TRY_PAGE);
    }
  }

  startCam() {
    this.factory.startCam(true);
  }

  callHelpPage() {
    this.router.navigate(['redirect/help'], { skipLocationChange: true });
  }
}
