export const DEFAULT_PARAMS = {
  analytics: {
    parameters: {
      business: 'pf',
      environment: 'ENVIRONMENT',
      business_channel: 'default'
    }
  }
};

export const TIPS_PAGE = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/dica-lista-reconhecimento",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const TIPS_PAGE_CLICK = {
  analytics: {
    "event_name": "click",
    "parameters": {
      "custom_path": "/biometria/dica-lista-reconhecimento",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "detail": "voltar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const HELP_PAGE = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/duvidas-frequentes-reconhecimento",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const HELP_PAGE_CLICK = {
  analytics: {
    "event_name": "click",
    "parameters": {
      "custom_path": "/biometria/duvidas-frequentes-reconhecimento",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "detail": "inicio-da-pergunta-faq",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const ERROR_CAM_PERMISSION_PAGE = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/autorize-acesso-camera-reconhecimento",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const ERROR_CAM_NOT_RESPOND_PAGE = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/camera-sem-resposta-reconhecimento",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const ERROR_LIMIT_EXCEED = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/tela-erro-quarentena",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const ERROR_BLOCKED = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/tela-erro-bloqueio",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const ERROR_GENERIC = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/tela-erro-generico",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const LOADING_PAGE = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/loading",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const AWARE_PAGE = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/camera-aware",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const AWARE_CAPTURE_SUCCESS = {
  analytics: {
    "event_name": "success",
    "parameters": {
      "custom_path": "/biometria/camera-aware",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "detail": "sucesso-captura-foto",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const AWARE_REGISTER_SUCCESS = {
  analytics: {
    "event_name": "conversion",
    "parameters": {
      "custom_path": "/biometria/camera-aware",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "detail": "sucesso-registro-foto",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const AWARE_TRY_AGAIN = {
  analytics: {
    "event_name": "conversion",
    "parameters": {
      "custom_path": "/biometria/aware-tente-novamente-reconhecimento",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const FACETEC_PAGE = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/camera-facetec",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const FACETEC_CAPTURE_SUCCESS = {
  analytics: {
    "event_name": "success",
    "parameters": {
      "custom_path": "/biometria/camera-facetec",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "detail": "sucesso-captura-foto",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const FACETEC_REGISTER_SUCCESS = {
  analytics: {
    "event_name": "conversion",
    "parameters": {
      "custom_path": "/biometria/camera-facetec",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "detail": "sucesso-registro-foto",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const FACETEC_TRY_AGAIN = {
  analytics: {
    "event_name": "conversion",
    "parameters": {
      "custom_path": "/biometria/facetec-tente-novamente-reconhecimento",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const LOADING_ANALISE = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/loading-analise-foto",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const LOADING_VALIDATE = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/loading-validacao-risco",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const LOADING_FIRST = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/loading-main",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};

export const SUCCESS_PAGE = {
  analytics: {
    "event_name": "screen_view",
    "parameters": {
      "custom_path": "/biometria/sucesso-biometria",
      "implementation_team": "capturar-biometria:identificar-e-autenticar",
      "customer_id": localStorage.getItem('route_token')
    }
  }
};
