import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { generateApiKey } from '../shared/helpers/encryption';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { IHttpOptions, CharonService } from '@quickweb/connect-angular';
import { Router } from '@angular/router';
import { ErrorsPage } from '../interfaces/ErrorsPage';
import { catchError, firstValueFrom, tap } from 'rxjs';
import { dataToLogs, dataToMatch, dataToParameters, dataToRegister, saveToggleReturn } from '../shared/helpers/dataAggregator';
import { v4 as uuidv4 } from 'uuid';
import { EndFactoryService } from '../factories/endfactory.service';


@Injectable({
  providedIn: 'root',
})
export class MainService {
  // Esta classe é a antiga NewCarontService, hoje é a classe principal de serviços do projeto - Fluxo Bio 2.0 
  constructor(
    private charon: CharonService,
    public router: Router,
    public factory: EndFactoryService
  ) {
    // @ts-ignore
    window.faceanalyzerAPI = this.faceanalyzerAPI.bind(this);
    // @ts-ignore
    window.livenessAPI = this.livenessAPI.bind(this);
  }

  // API para Busca os links das APIS usadas na aplicação no caronte.
  async carontLinks() {
    const params = {
      charonID: environment.newCharonID,
      entryPoint: environment.newCharonUrl,
      headers: new HttpHeaders({
        'x-itau-apikey': generateApiKey(6),
        'Content-Type': 'application/json',
      }),
    };
    if (environment.disableCripto) {
      this.charon.setCryptography(false);
    } else {
      this.charon.setCryptography(true);
    }
    try {
      const response: any = await firstValueFrom(
        this.charon.initialize(params)
          .pipe(
            tap((res) => {
              if (res) this.charon.saveEntrypointData(params, res as HttpResponse<any>);
              return res;
            }),
            catchError((error) => {
              this.redirectToError();
              throw error;
            })
          )
      );
      return response?.status;
    } catch (error) {
      this.redirectToError();
    }
  }

  // API usada para iniciar a sessão do usuário, busca pelos parametros necessários para o inicio do fluxo.
  async parametersAPI(callback, callbackError) {
    const token = localStorage.getItem('route_token');

    const options: IHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-itau-flowid': uuidv4(),
        'x-itau-correlationid': uuidv4(),
        'x-itau-authorization': `Bearer ${token}`
      }),
      charonID: environment.newCharonID
    };

    const bodyParams = await dataToParameters();

    const response = await firstValueFrom(
      this.charon.post('parameters', bodyParams, options)
        .pipe(
          tap((res: any) => {
            if (callback) callback(res);
            return res;
          }),
          catchError((error: any) => {
            if (callbackError) callbackError(error);
            this.redirectToError();
            throw error;
          }),
        )
    );
    return response.body;
  }

  // API usada pela Aware, pooling para captura de foto.
  async faceanalyzerAPI(bodyParams?: any) {
    const options: IHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      charonID: environment.newCharonID
    };

    const response = await firstValueFrom(
      this.charon.post('faceanalyzer', bodyParams, options)
        .pipe(
          tap((res: any) => {
            return res;
          }),
          catchError((error: any) => {
            this.redirectToError();
            throw error;
          }),
        )
    );
    return response.body;
  }

  // API usada para verificar se a API de logs (insightsAPI) está ligada para uso. 
  async togglesAPI() {
    const options: IHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      charonID: environment.newCharonID
    };

    const response = await firstValueFrom(
      this.charon.get('feature-toggles', options)
        .pipe(
          tap((res: any) => {
            if (res) saveToggleReturn(res.body.data.feature_toggles);
          })
        )
    );
    return response.body;
  }

  // API usada para enviar logs internos ao nosso novo backend.
  async insightsAPI(steps) {
    const options: IHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      charonID: environment.newCharonID
    };

    const bodyParams = dataToLogs();
    bodyParams.data.steps = steps;

    const response = await firstValueFrom(
      this.charon.post('insights', bodyParams, options)
        .pipe(
          tap((res: any) => {
            return res;
          }),
          catchError((error: any) => {
            this.redirectToError();
            throw error;
          }),
        )
    );
    return response.body;
  }

  // API usada para validação do usuário, busca pelos parametros necessários validação biometrica do mesmo.
  async livenessAPI(payloadFromLib: any) {

    const token = localStorage.getItem('route_token');

    const options: IHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-itau-flowid': uuidv4(),
        'x-itau-correlationid': uuidv4(),
        'x-itau-authorization': `Bearer ${token}`
      }),
      charonID: environment.newCharonID
    };

    const payloadValue = JSON.parse(payloadFromLib)
    let bodyParams: any = dataToRegister();
    bodyParams.conteudo_analise_sdk = { ...payloadValue };

    // validar estrutura com backend
    const data = {
      captura_timeout: true,
      pacote: bodyParams
    }

    const response = await firstValueFrom(
      this.charon.post('liveness', data, options)
        .pipe(
          tap((res: any) => {
            this.factory.resolveFactory(res);
          }),
          catchError((error: any) => {
            this.router.navigate(['redirect/error'], { queryParams: { errorCode: ErrorsPage.ERROR_SERVER }, skipLocationChange: true });
            throw error;
          }),
        )
    );
    return response.body;
  }

  // Metodo usado para garantir conversao correta dos dados vindo da AWARE - Chama a livenessAPI
  public async prelivenessAPI(payloadFromLib) {
    const payloadValue = JSON.parse(payloadFromLib)
    let payloadRegisterPlus: any = dataToRegister();
    payloadRegisterPlus.conteudo_analise_sdk = { ...payloadValue };
    this.livenessAPI(payloadRegisterPlus);
  }

  // API usada para validação de risco do usuário.
  async matchAPI() {

    const token = localStorage.getItem('route_token');

    const options: IHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-itau-flowid': uuidv4(),
        'x-itau-correlationid': uuidv4(),
        'x-itau-authorization': `Bearer ${token}`
      }),
      charonID: environment.newCharonID
    };

    let bodyParams: any = dataToMatch();

    const response = await firstValueFrom(
      this.charon.post('liveness', bodyParams, options)
        .pipe(
          tap((res: any) => {
            return res;
          }),
          catchError((error: any) => {
            this.router.navigate(['redirect/error'], { queryParams: { errorCode: ErrorsPage.ERROR_SERVER }, skipLocationChange: true });
            throw error;
          }),
        )
    );
    return response.body;
  }

  redirectToError() {
    this.router.navigate(['redirect/error'], { queryParams: { errorCode: ErrorsPage.ERROR_SERVER }, skipLocationChange: true });
  }
}
