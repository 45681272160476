import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { WINDOW } from 'src/assets/lib/tag/window.token';
import { AngularDeviceInformationService } from 'angular-device-information';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { LogsService } from 'src/app/services/logs/logs.service';
import { TaggingServiceGA4 } from 'src/app/services/tagging/taggingGA4.service';
declare const window: any;

@Component({
  selector: 'app-errorpermission',
  templateUrl: './errorpermission.component.html',
  styleUrls: ['./errorpermission.component.scss'],
})
export class ErrorpermissionComponent implements OnInit {

  constructor(
    @Inject(WINDOW) private readonly win: any,
    private loadingService: LoadingService,
    private zone: NgZone,
    private deviceInformationService: AngularDeviceInformationService,
    @Inject(LogsService)
    private logsService: LogsService,
    private taggingServiceGA4: TaggingServiceGA4,
  ) { }
  tipo_mobile: any;
  caronte_header: any;
  close_modal = false;
  opacity = 'opacity';
  opacity_in = '';
  key_capture: any;
  infos_logs = {
    title: '',
    description: '',
    captureKey: '',
  };
  cn: any;
  ambiente: any;
  negocio: any;
  tipoDeCanal: any;
  segmento: string;
  client_id: any;
  prefix_pagina: any;
  tid: any;
  link_tag: any;
  idproposta: any;

  type_liveness: string;

  ngOnInit(): void {
    this.loadingService.finishLoading();
    sessionStorage.setItem('permission_cam', 'clear');
    this.zone.run(() => {
      console.log('width', window.innerWidth);
    });
    this.cn = sessionStorage.getItem('cn');
    this.ambiente = sessionStorage.getItem('ambiente');
    this.negocio = sessionStorage.getItem('negocio');
    this.tipoDeCanal = sessionStorage.getItem('tipoDeCanal');
    this.segmento = sessionStorage.getItem('segmento');
    this.client_id = sessionStorage.getItem('client_id');
    this.prefix_pagina = sessionStorage.getItem('prefix_pagina');
    this.tid = sessionStorage.getItem('tid');
    this.idproposta = sessionStorage.getItem('idproposta');
    this.tipo_mobile = 'android';
    const deviceInfo = this.deviceInformationService.getDeviceInfo();
    if ((deviceInfo.os === 'iOS') || (deviceInfo.os === 'ios') || (deviceInfo.os === 'IOS') || (deviceInfo.os === 'ioS') || (deviceInfo.os === 'IoS') || (deviceInfo.os === 'IOs') || (deviceInfo.os === 'Ios')) {
      this.tipo_mobile = 'ios';
    }
    this.link_tag = '&cn=' + this.cn + '&ambiente=' + this.ambiente + '&negocio=' + this.negocio + '&tipoDeCanal=' + this.tipoDeCanal + '&prefix_pagina=' + this.prefix_pagina + '&tid=' + this.tid;
    this.logsService.conectCaronte();
    setTimeout(() => {
      this.trackEventGA4('permissao-negada', 'visualization', 'modal:permissao-negada');
      this.trackItem('DicasPermissaoNegada', 'pageLoad', 'DicasPermissaoNegada', this.negocio);
      this.logsService.handleLogs('ErrorPermission',
      'Loading ErrorPermission Page',
      'ERROR_PERMISSION');
    }, 1000);
  }

  /**
   * Método responsável por enviar o evento de screen-view, alert e visualization para o Google Analytics GA4.
   * @param eventName - Nome do evento do screen-view, alert e visualization.
   * @param detail - Nome do botão clicado.
   */
    trackEventGA4(pagename: string, evento_name: string, detail?: string) {
      const type_liveness = sessionStorage.getItem('facetec');
      let objTrack;
      objTrack = {
        custom_path: type_liveness === 'facetec' ? '/biometria/' + pagename + '-ft' : '/biometria/' + pagename,
        event_name: evento_name,
        person_id: this.client_id,
        customer_segment: this.segmento,
        proposal_id: this.idproposta,
        detail: detail || '',
      };
      return this.taggingServiceGA4.trackGA4(objTrack);
    }

  trackItem(eventNameRename: string, typeTag: string, pageName: string, tiNegocio: string): void {
    let eventName = eventNameRename;
    if (tiNegocio === 'NCC') {
      this.cn = 'AC';
    } else if (tiNegocio === 'NCC-RV') {
      this.cn = 'IT';
    }
    if (this.cn !== 'AC') {
      eventName = eventNameRename.replace('Biometria:', '');
    }
    let customgeral = {};
    if ((this.idproposta) && ((this.idproposta !== ''))) {
      customgeral = {
        idproposta: this.idproposta,
      };
    }
    const type_liveness = sessionStorage.getItem('facetec');
    if (type_liveness && type_liveness === 'facetec') {
      eventName = 'CameraSemAcesso';
    }
    if (typeTag === 'pageLoad') {

      window.analyticsData = {
        rule: 'pageLoad',
        page: {
          nome: this.cn + ':' + this.ambiente + ':' + this.negocio + ':' + this.prefix_pagina + ':' + eventName,
        },
        custom: customgeral,
      };
    } else {
      const custom = {
        itemClicado: eventName,
        events: ['Clique'],
      };

      if (pageName === 'OK') {
        let action = 'Biometria:DicasPermissaoNegada';
        let label = 'BTN:Biometria:DicasPermissaoNegada:Ok';

        if (type_liveness && type_liveness === 'facetec') {
          action = 'Biometria:CameraSemAcesso';
          label = 'BTN:Biometria:CameraSemAcesso:OK';
        }
        window.analyticsData = {
          rule: 'customLink',
          page: {
            nome: this.cn + ':' + this.ambiente + ':' + this.negocio + ':' + this.prefix_pagina + ':' + eventName,
          },
          events: {
            category: 'Clique',
            action,
            label,
          },
          custom: customgeral,
        };

      } else if (pageName === 'Fechar') {
        window.analyticsData = {
          rule: 'customLink',
          page: {
            nome: this.cn + ':' + this.ambiente + ':' + this.negocio + ':' + this.prefix_pagina + ':' + eventName,
          },
          events: {
            category: 'Clique',
            action: 'Biometria:DicasPermissaoNegada',
            label: 'BTN:Biometria:DicasPermissaoNegada:Fechar',
          },
          custom: customgeral,
        };

      } else if (pageName === 'Voltar') {
        window.analyticsData = {
          rule: 'customLink',
          page: {
            nome: this.cn + ':' + this.ambiente + ':' + this.negocio + ':' + this.prefix_pagina + ':' + eventName,
          },
          events: {
            category: 'Clique',
            action: 'Biometria:DicasPermissaoNegada',
            label: 'BTN:Biometria:DicasPermissaoNegada:Voltar',
          },
          custom: customgeral,
        };
      }
    }
    setTimeout(() => {
      let alteranome = window.analyticsData.page.nome;
      alteranome = alteranome.replace(':Biometria:Biometria', ':Biometria');
      window.analyticsData.page.nome = alteranome;
      if (window._frameworkDA) {
        if ((this.tid) && (this.tid !== false) && (this.tid !== '') && (this.ambiente) && (this.ambiente !== false) && (this.ambiente !== '')) {
          window._frameworkDA.Track();
        }
      }
    }, 100);
  }

  ignoreFrameworkDa(varDa: any) {
    return varDa;
  }

  go() {
    window.onbeforeunload = () => { };
    window.onbeforeunload = () => { };
    let redirect_storage = sessionStorage.getItem('redirect_url');
    const redirectUrlError = sessionStorage.getItem('redirectUrlRetryPermission');
    if ((redirectUrlError !== '') && (redirectUrlError !== null) && ((redirectUrlError)) && ((redirectUrlError !== 'undefined'))) {
      redirect_storage = redirectUrlError;
    } else if (redirect_storage === null) {
      redirect_storage = 'www.itau.com.br';
    }
    setTimeout(() => {
      window.document.location.href = 'https://' + redirect_storage + '&type=error_no_permission' + this.link_tag;
    }, 1500);
  }

  closeModal(type: any) {
    document.getElementById('tipsPhoto')?.focus();
    this.close_modal = true;
    this.opacity = '';
    this.opacity_in = 'clicked';
    sessionStorage.setItem('permission_cam', 'clear');
    this.trackItem('DicasPermissaoNegada', 'Clique', type, this.negocio);
    this.logsService.handleLogs('ErrorPermission',
    'Close modal clicked on error permission page',
    'ERROR_PERMISSION');
    this.go();
  }
}