import { NgModule } from '@angular/core';
import { MainComponent } from './main/main.component';
import { PagesRoutingModule } from './pages-routing.module';
import { ComponentsModule } from '../shared/components/components.module';
import { AwareComponent } from './aware/aware.component';
import { CommonModule } from '@angular/common';
import { IdsModule } from '../shared/shared.module';
import { ErrorComponent } from './error/error.component';
import { SuccessComponent } from './success/success.component';
import { NewTryComponent } from './new-try/new-try.component';
import { HelpComponent } from './help/help.component';
import { QualityComponent } from './quality/quality.component';
import { MainLoadingComponent } from './main-loading/main-loading.component';

@NgModule({
  declarations: [
    MainComponent,
    AwareComponent,
    ErrorComponent,
    SuccessComponent,
    QualityComponent,
    NewTryComponent,
    HelpComponent,
    MainLoadingComponent
  ],
  imports: [
    PagesRoutingModule,
    CommonModule,
    ComponentsModule,
    IdsModule
  ],
})
export class PagesModule { }
