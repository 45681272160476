import { CamComponent } from 'src/app/pages/cam/cam.component.utils';
import { HomeComponent } from '../../app/pages/home/home.component.utils';
import { FaceTecIDScanResult, FaceTecSessionResult } from './core-sdk/FaceTecSDK.js/FaceTecPublicApi';
import { LivenessCheckProcessor } from './processors/LivenessCheckProcessor.utils';
import { SampleAppUtilities } from './utilities/SampleAppUtilities.utils';

export const AngularSampleApp = (() => {
  let latestEnrollmentIdentifier = '';
  let latestSessionResult: FaceTecSessionResult | null = null;
  let latestIDScanResult: FaceTecIDScanResult | null = null;
  let latestProcessor: LivenessCheckProcessor;
  let camComponent: CamComponent;
  let homeComponent: HomeComponent;

  // Clear previous session results;
  function initializeResultObjects(): void {
    latestSessionResult = null;
    latestIDScanResult = null;
  }

  // Initiate a 3D Liveness Check.
  function onLivenessCheckPressed(sessionToken: string) {
    initializeResultObjects();
    let returnLiveness = setLivenessCheck(sessionToken);
    if (returnLiveness == false) {
      return;
    }
  }

  function setLivenessCheck(sessionToken: string) {
    let returnCheck = true;
    try{
      latestProcessor = new LivenessCheckProcessor(
        sessionToken as string,
        AngularSampleApp as any,
      );
      if ((latestProcessor.sessionResultStatus == undefined) || (latestProcessor.sessionResultStatus == 'undefined') || (latestProcessor.sessionResultStatus == '0') || (latestProcessor.sessionResultStatus == '3')) {
        return returnCheck;
      }
    } catch (e) {
      returnCheck = false;
      return returnCheck;
    }
  }

  function onServerSessionTokenError(): void {
    SampleAppUtilities.handleErrorGettingServerSessionToken();
  }



  // Show the final result and transition back into the main interface.
  function onComplete() {
    SampleAppUtilities.showMainUI();
    if (!latestProcessor.isSuccess()) {
      // Reset the enrollment identifier.
      latestEnrollmentIdentifier = '';

      // Show early exit message to screen.  If this occurs, please check logs.
      SampleAppUtilities.displayStatus(
        'Sessão encerrada antes do tempo, consulte os logs para mais detalhes.',
      );
      return;
    }

    const parameters = {
      faceScan: latestProcessor.latestSessionResult.faceScan,
      auditTrailImage: latestProcessor.latestSessionResult.auditTrail[0],
      lowQualityAuditTrailImage: latestProcessor.latestSessionResult.lowQualityAuditTrail[0],
      livenessCheckSucess: latestProcessor.success,
    };

    if (!!camComponent) {
      camComponent.conectCaronte('register', JSON.stringify(parameters));
    } else {
      homeComponent.homeTest(JSON.stringify(parameters));
    }

    // Show successful message to screen
    SampleAppUtilities.displayStatus('Sucesso');
  }

  // DEVELOPER NOTE:  This is a convenience function for demonstration purposes only so the Sample App can have access to the latest session results.
  // In your code, you may not even want or need to do this.
  function setLatestSessionResult(sessionResult: FaceTecSessionResult) {
    latestSessionResult = sessionResult;
  }

  function setLatestIDScanResult(idScanResult: FaceTecIDScanResult) {
    latestIDScanResult = idScanResult;
  }

  function getLatestEnrollmentIdentifier() {
    return latestEnrollmentIdentifier;
  }

  function setLatestServerResult(responseJSON: any) {
  }

  function clearLatestEnrollmentIdentifier(responseJSON: any) {
    latestEnrollmentIdentifier = '';
  }

  function resetResponseObservable(componentCam?, componeteHom?, token?) {
    if (!!componentCam) {
      camComponent = componentCam;
    } else {
      homeComponent = componeteHom;
    }
    onLivenessCheckPressed(token);
  }

  return {
    onLivenessCheckPressed,
    onComplete,
    setLatestSessionResult,
    setLatestIDScanResult,
    getLatestEnrollmentIdentifier,
    setLatestServerResult,
    clearLatestEnrollmentIdentifier,
    resetResponseObservable,
  };
})();
