// UI Convenience Functions
export let SampleAppUIFunctions = (elementString: string) => {

  // Get the element(s) for ui operations from the elementString;
  const currentElements: NodeListOf<Element> | [Element] = document.querySelectorAll(elementString);

  // Save the original display property of the element before hiding it
  const saveDisplayForElement = (el: HTMLElement) => {
    const display = window.getComputedStyle(el).display;
    if (display && display !== 'none') {
      el.setAttribute('displaytype', display);
    }
  };

  // Set the display of the element to either block or restore it's original value
  const setDisplayForElement = (el: HTMLElement) => {
    let display = 'block';
    if (el.getAttribute('displaytype')) {
      display = el.getAttribute('displaytype')!;
    }
    el.style.display = display;
  };

  // Fade in the element to opacity over duration ms with an optional callback
  const fadeIn = (el: HTMLElement, opacity?: string, duration?: number, callback?: () => void) => {
    if (!el) {
      return;
    }
    const inputOpacity = opacity || '1';
    const inputDuration = duration || 1;
    const computedStyle = window.getComputedStyle(el);
    if (computedStyle.display === 'none' && computedStyle.opacity === '1') {
      el.style.opacity = '0';
    }
    el.style.visibility = 'visible';
    saveDisplayForElement(el);
    setDisplayForElement(el);
    // @ts-ignore
    el.style['-webkit-transition'] = 'opacity ' + inputDuration + 'ms';
    // @ts-ignore
    el.style['-moz-transition'] = 'opacity ' + inputDuration + 'ms';
    // @ts-ignore
    el.style['-o-transition'] = 'opacity ' + inputDuration + 'ms';
    el.style['transition'] = 'opacity ' + inputDuration + 'ms';
    // Allow JS to clear execution stack
    window.setTimeout(() => {
      requestAnimationFrame(() => {
        el.style.opacity = inputOpacity!;
      });
    });
    window.setTimeout(() => {
      setDisplayForElement(el);
      if (callback) {
        callback();
      }
    }, inputDuration);
  };

  // Fade out the element to opacity over duration ms with an optional callback
  const fadeOut = (el: HTMLElement, opacity?: string, duration?: number, callback?: () => void) => {
    if (!el) {
      return;
    }
    saveDisplayForElement(el);
    const inputOpacity = opacity || '0';
    const inputDuration = duration || 1;
    // @ts-ignore
    el.style['-webkit-transition'] = 'opacity ' + inputDuration + 'ms';
    // @ts-ignore
    el.style['-moz-transition'] = 'opacity ' + inputDuration + 'ms';
    // @ts-ignore
    el.style['-o-transition'] = 'opacity ' + inputDuration + 'ms';
    el.style['transition'] = 'opacity ' + inputDuration + 'ms';
    // Allow JS to clear execution stack
    window.setTimeout(() => {
      requestAnimationFrame(() => {
        el.style.opacity = inputOpacity!;
      });
    });
    window.setTimeout(() => {
      el.style.display = 'none';
      if (callback) {
        callback();
      }
    }, inputDuration);
  };
  return {
    fadeOut: (duration?: any, callback?: any) => {
      currentElements.forEach(element => {
        fadeOut(element as HTMLElement, '0', duration, callback);
      });
    },
    fadeIn: (duration?: any, callback?: any) => {
      currentElements.forEach(element => {
        fadeIn(element as HTMLElement, '1', duration, callback);
      });
    },
    show: () => {
      currentElements.forEach(element => {
        (element as HTMLElement).style.opacity = '1';
        setDisplayForElement(element as HTMLElement);
      });
    },
    hide: () => {
      currentElements.forEach(element => {
        (element as HTMLElement).style.opacity = '0';
        setDisplayForElement(element as HTMLElement);
      });
    },
    scrollTop: (value: number) => {
      currentElements.forEach(element => {
        (element as HTMLElement).scrollTop = value;
      });
    },
    css: (styleProperTies: any) => {
      if (typeof styleProperTies !== 'object') {
        throw new Error('UI.css must be called with an object');
        return;
      }
      currentElements.forEach(element => {
        Object.keys(styleProperTies).map(style => {
          // @ts-ignore
          (element as HTMLElement).style[style] = styleProperTies[style];
        });
      });
    },
  };
};
