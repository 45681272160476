<div class="aware">
    <div id="spinner-itau" [attr.aria-hidden]="aria_hidden_spinner">Carregando</div>
    <div class="app-cam opacity-{{ showPopup }}">
        <div [attr.aria-hidden]="aria_hidden" class="box-{{ showPopup }}" *ngIf="showPopup == 'initial'">

            <div class="opacity" [attr.aria-hidden]="aria_hidden" *ngIf="!isError; else errorcontent">
                <h1  id="title_tips" tabindex="1">dicas para tirar sua foto</h1>  
                <div class="content-image-position">
                    <button [attr.aria-hidden]="true" id="refresh_page" class="voxel-button" type="button" style="display: none;" (click)="setRegresh()">tudo pronto</button>
                    <img aria-hidden="true" src="assets/icons/face.gif" id="Group_10" />
                </div>

                <div class="content-fixed-botton">
                    <div class="list-tips">
                        <ul>
                            <li>
                                <div class="image">
                                    <img aria-hidden="true" src="assets/lamb.png" />
                                </div>
                                <div class="text">
                                    esteja em um local iluminado.
                                </div>
                                <br clear="all" />
                            </li>
                            <li>
                                <div class="image">
                                    <img aria-hidden="true" src="assets/view.png" />
                                </div>
                                <div class="text">
                                    olhe diretamente para a câmera.
                                </div>
                                <br clear="all" />
                            </li>
                            <li>
                                <div class="image">
                                    <img aria-hidden="true" src="assets/attention.png" />
                                </div>
                                <div class="text">
                                    retire boné, óculos ou outros objetos.
                                </div>
                                <br clear="all" />
                            </li>
                        </ul>
                    </div>
                    <div class="wrapping-box-container">
                        <div id="controls" class="controls">
                            <div *ngIf="type_liveness != 'aware'" id="hidden-btn-space"></div>
                            <div *ngIf="type_liveness != 'aware'" id="hidden-btn-facetec" style="display: none">
                                <button type="button" id="closeSpinner" (click)="closeSpinner()">Fecha Spinner</button>
                            </div>
                            <button *ngIf="type_liveness != 'aware'" id="liveness-button" (click)="validarInicializacaoFacetec()" [attr.aria-hidden]="aria_hidden" class="voxel-button" type="button" onclick="setFocusFacetec()">{{labelBtnFacetecTudoPronto}}</button>
                            <button *ngIf="type_liveness == 'aware'" id="liveness-button-aware" (click)="hideInstructions()" [attr.aria-hidden]="aria_hidden" class="voxel-button" type="button">tudo pronto</button>
                            <p hidden id="status">Inicializando...</p>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #errorcontent>
                <app-error-content>
                    <button class="voxel-button" [ngStyle]="isSafari == true && {'margin-bottom':'15%'}" type="button" (click)="closeShow()" id="errorFacetec">tentar novamente</button>
                </app-error-content>
            </ng-template>            
        </div>
        <app-success *ngIf="capturado"></app-success>


        <div [attr.aria-hidden]="aria_hidden_capture" class="{{ display_none_progress }}" id="appAriaHiddenBox"></div>
        <div *ngIf="type_liveness == 'aware'">
            <div class="app-capture-instructions {{ display_none_percentual }}" id="appCaptureInstructionsBox" *ngIf="tentativas < 3 && tela_dividida==true">
                <label [attr.aria-hidden]="aria_hidden_capture_mensagem" class="label_capture_fake" id="label_capture_fake" tabindex="0" [ngClass]="(aria_hidden_capture_mensagem_h1_show!=true) ? 'label_capture_fake_none': ''" [hidden]="aria_hidden_capture_mensagem_h1_show != true" aria-live="assertive">centralize o rosto na moldura</label>
                <label [attr.aria-hidden]="aria_hidden_capture" id="label_capture" [hidden]="aria_hidden_capture_label!=true" aria-live="polite" tabindex="1">{{ error_message_front }}</label>
                <div [attr.aria-hidden]="aria_hidden_capture" class="loading-bar">
                    <div class="progress{{ classProgress }}"></div>
                </div>
            </div>

            <div [attr.aria-hidden]="aria_hidden_capture" class="video {{ display_class1 }}" id="videoBox" *ngIf="tentativas < 3 && tela_dividida==true">
                <div class="element">
                    <video [attr.aria-hidden]="true" autoplay playsinline #video class="ratio bodyAware" id="video" [ngClass]="{'ratio-3x4': isPortrait, 'ratio-4x3': !isPortrait}"></video>
                    <canvas [attr.aria-hidden]="true" #ovalOverlay class="ovalOverlay" id="ovalOverlay"></canvas>
                </div>
            </div>
            <div [attr.aria-hidden]="aria_hidden_capture_mensagem" class="btn-foto" *ngIf="take_my_picture!=true">
                <button class="voxel-button" type="button" (click)="takeMyPicture()" id="takeMyPictureButton" data-message="tirar minha foto">tirar minha foto</button>
            </div>
        </div>
        <div [attr.aria-hidden]="aria_hidden_success" class="{{ display_class2 }}" *ngIf="showPopup != 'Error_New_Try'">
            <div class="app-upload-body">
                <div class="content">
                    <div class="animation">
                        <img aria-hidden="true" src="assets/icons/face_sem_upload.png" width="200" height="200" id="face_sem_upload" />
                        <br clear="all" />
                        <img aria-hidden="true" src="assets/icons/face_upload.gif" width="70" height="70" />
                    </div>

                    <div class="register-progress">
                        <div class="box-message-sucesso">
                            <div class="progress-success">
                                <div class="prog{{ classProgressRegister }}"></div>
                            </div>
                        </div>

                        <div class="message-sucess">
                            <label id="label_register_success" aria-live="polite" tabindex="1">{{ register_success }}</label>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>        
    </div>
</div>

<app-instructions-popup *ngIf="showPopup == 'secondary' && status != 'Error' && status != 'Error_Session'" title="A imagem não ficou boa" buttonLabel="tentar de novo" nameId="btn_dica_geral" [instructions]="[
        {icon: 'inovacao_outline', text: 'O local deve estar iluminado.'},
        {icon: '&#xea8c;', text: 'Retire os acessórios como boné, óculos ou algo que cubra seu rosto.'},
        {icon: 'fatura_outline', text: 'Siga as orientações da tela de captura.'}
    ]" (didClick)="hideInstructions()"></app-instructions-popup>

<app-instructions-popup *ngIf="showPopup == 'error_no_indication' && status != 'Error' && status != 'Error_Session'" title="" buttonLabel="tirar minha foto" (click)="go('capture/error?type=error_no_indication&errortype=type1')"></app-instructions-popup>

<app-instructions-popup *ngIf="status == 'Error1'" title="" buttonLabel="tentar novamente" nameId="btn_nao_possivel" onclick="window.location.reload()" [instructions]="[
{icon: 'aviso_outline', text: 'Não foi possível carregar as informações neste momento.' }
]"></app-instructions-popup>

<app-instructions-popup *ngIf="status == 'Error_Session'" title="" buttonLabel="tentar de novo" (didClick)="redirectUrl('no_session')" tabindex="2" id="btn_tente_novamente_sessao" [instructions]="[
{icon: 'aviso_outline', text: 'O tempo para tirar sua foto acabou. Retorne ao site e tente novamente.' }
]"></app-instructions-popup>


<app-instructions-popup *ngIf="(type_liveness !== 'facetec' && (showPopup == 'Error_New_Try' || status == 'Error') && status != 'Error_Session' && tentativas < 3 && ignore_new_try != true)" title="por favor, tente de novo" nameId="btn_tente_novamente" description="" buttonLabel="tentar de novo" (didClick)="closeShow()" [instructions]="[
{icon: 'inovacao_outline', text: 'o local deve estar iluminado;'},
{icon: 'aviso_outline', text: 'não use boné, óculos ou algo que cubra seu rosto.'}
]" aria-live="polite"></app-instructions-popup>


<app-instructions-popup *ngIf="showPopup == 'Attempts_Error' && status != 'Error_Session'" title="por favor, tente de novo" nameId="btn_tente_novamente_no_aproved" description="" buttonLabel="tentar de novo" (didClick)="redirectUrl('no_aproved')" [instructions]="[
{icon: 'inovacao_outline', text: 'o local deve estar iluminado;'},
{icon: 'aviso_outline', text: 'não use boné, óculos ou algo que cubra seu rosto.'}
]" aria-live="polite"></app-instructions-popup>
