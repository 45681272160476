import { Router } from '@angular/router';
import { generateApiKey, setCript } from '../../../../../../app/shared/helpers/encryption';
import { v4 as uuidv4 } from 'uuid';

import type { FaceTecSessionResult, FaceTecFaceScanResultCallback, FaceTecFaceScanProcessor } from "../../../core-sdk/FaceTecSDK.js/FaceTecPublicApi";
import { SampleAppControllerReference } from "../sampleAppControllerReference/SampleAppControllerReference";

import { dataToRegister } from '../../../../../../app/shared/helpers/dataAggregator';
import { QualityPage } from '../../../../../../app/interfaces/ResponsesServices';
import { ErrorsPage } from '../../../../../../app/interfaces/ErrorsPage';
import { environment } from '../../../../../../environments/environment';


export class LivenessCheckProcessor implements FaceTecFaceScanProcessor {

  latestNetworkRequest: XMLHttpRequest = new XMLHttpRequest();
  public latestSessionResult: FaceTecSessionResult | null;

  success: boolean;
  sampleAppControllerReference: SampleAppControllerReference;
  response: any;
  router: any;

  constructor(sessionToken: string, sampleAppControllerReference: any, router?: Router) {
    this.success = false;
    this.sampleAppControllerReference = sampleAppControllerReference;
    this.latestSessionResult = null;
    this.router = router ? router : null;
    sessionStorage.setItem('setStatusOfReturn', '');

    // @ts-ignore
    new FaceTecSDK.FaceTecSession(
      this,
      sessionToken
    );
  }

  public processSessionResultWhileFaceTecSDKWaits = async (sessionResult: FaceTecSessionResult, faceScanResultCallback: FaceTecFaceScanResultCallback): Promise<void> => {
    this.latestSessionResult = sessionResult;

    // @ts-ignore
    if (sessionResult.status !== FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully) {
      this.callRedirectCallback('error_no_capture');
      this.latestNetworkRequest.abort();
      faceScanResultCallback.cancel();
      return;
    }

    let payloadRegisterPlus: any = dataToRegister();
    payloadRegisterPlus.conteudo_analise_sdk = {};
    payloadRegisterPlus.conteudo_analise_sdk.faceScan = sessionResult.faceScan;
    payloadRegisterPlus.conteudo_analise_sdk.auditTrailImage = sessionResult.auditTrail[0];
    payloadRegisterPlus.conteudo_analise_sdk.lowQualityAuditTrailImage = sessionResult.lowQualityAuditTrail[0];
    payloadRegisterPlus.conteudo_analise_sdk.livenessCheckSucess = this.success;

    // Usado no endFlow do fluxo antigo, será removido na Bio 2.0
    // @ts-ignore
    const result = await window.register(payloadRegisterPlus);
    const scanResultBlob = result.scan_result_blob;
    const count = localStorage.getItem('number_try') || "1";
    localStorage.setItem('number_try', `${parseInt(count) + 1}`);

    // Fluxo Bio 2.0
    // const result = await window.livenessAPI(payloadRegisterPlus);
    // const scanResultBlob = result.fornecedor.parametros.scan_result_blob;

    this.response = result;
    if (result) {
      // @ts-ignore
      FaceTecSDK.FaceTecCustomization.setOverrideResultScreenSuccessMessage("Face Scanned\n3D Liveness Proven");

      // Usado no endFlow do fluxo antigo, será removido na Bio 2.0
      // @ts-ignore
      window.endFlowFacetec(result, faceScanResultCallback, scanResultBlob)
    }

    window.setTimeout(() => {
      if (this.response) {
        return;
      }
      faceScanResultCallback.uploadMessageOverride("Still Uploading...");
    }, 6000);
  };

  public onFaceTecSDKCompletelyDone = (): void => {
    this.success = this.latestSessionResult!.isCompletelyDone;
    const isError = sessionStorage.getItem('setStatusOfReturn');

    switch (isError) {
      case 'error':
        this.callErrorCallback(ErrorsPage.LIMIT_ERROR);
        break;
      case 'error_block':
        this.callErrorCallback(ErrorsPage.LIMIT_BLOCK);
        break;
      default:
        if (this.latestSessionResult.status === 0) {
          this.callSuccessCallback();
        } else {
          this.callRedirectCallback('error_no_frame');
        }
        break;
    }

    this.sampleAppControllerReference.onComplete(this.latestSessionResult, null, this.latestNetworkRequest.status);
  };

  public isSuccess = (): boolean => {
    return this.success;
  };

  public callRedirectCallback = (type: string): void => {
    const params = sessionStorage.getItem('paramsUrl');

    window.document.location.href = `https://${sessionStorage.getItem('redirectUrlError')}/?&type=${type}${params}`;
  }

  public callSuccessCallback = (): void => {
    const params = sessionStorage.getItem('paramsUrl');
    sessionStorage.setItem('redirectSuccess', `https://${sessionStorage.getItem('redirectUrl')}/?&type=success${params}`);

    this.router.navigate(['redirect/quality'], { queryParams: { result: QualityPage.SUCCESS }, skipLocationChange: true });
  }

  public callErrorCallback = (type: string): void => {
    const params = sessionStorage.getItem('paramsUrl');
    sessionStorage.setItem('redirectError', `https://${sessionStorage.getItem('redirectUrlError')}/?&type=${type}${params}`);

    this.router.navigate(['redirect/error'], { queryParams: { errorCode: type }, skipLocationChange: true });
  }

}
