import { Params } from '@angular/router';
import DeviceDetector from "device-detector-js";

export const verifyRoute = (activatedRoute) => {
	activatedRoute?.queryParams?.subscribe((params: Params) => {
		if (params.key_capture) localStorage.setItem('route_token', params.key_capture);
		if (params.page) sessionStorage.setItem('rout_params_page', params.page);
		if (params.cpf_idpessoa) sessionStorage.setItem('rout_params_cpf_idpessoa', params.cpf_idpessoa);
		if (params.origin_process) sessionStorage.setItem('rout_params_origin_process', params.origin_process);
		if (params.cn) sessionStorage.setItem('cn', params.cn);
		if (params.ambiente) sessionStorage.setItem('ambiente', params.ambiente);
		if (params.negocio) sessionStorage.setItem('negocio', params.negocio);
		if (params.tipoDeCanal) sessionStorage.setItem('tipoDeCanal', params.tipoDeCanal);
		if (params.prefix_pagina) sessionStorage.setItem('prefix_pagina', params.prefix_pagina);
		if (params.tid) sessionStorage.setItem('tid', params.tid);
		if (params.customer_segment) sessionStorage.setItem('customer_segment', params.customer_segment);
	});
}

export const verifyUrlOfAgent = () => {
	const userAgent = navigator.userAgent.toLowerCase();
	if (userAgent.includes('chrome')) {
		return 'https://support.google.com/chrome/answer/2693767?hl=pt-BR&co=GENIE.Platform%3DAndroid';
	} else if (userAgent.includes('firefox')) {
		return 'https://support.mozilla.org/pt-BR/kb/como-gerenciar-permissoes-camera-microfone-firefox';
	} else if (userAgent.includes('safari')) {
		return 'https://support.apple.com/pt-br/guide/mac-help/mchlf6d108da/mac';
	} else if (userAgent.includes('edge')) {
		return 'https://support.microsoft.com/pt-br/windows/windows-c%C3%A2mera-microfone-e-privacidade-a83257bc-e990-d54a-d212-b5e41beba857';
	} else {
		return 'https://support.google.com/chrome/answer/2693767?hl=pt-BR&co=GENIE.Platform%3DAndroid';
	}
}

export const verifyDeviceInfoOfAgent = () => {
	const userAgent = navigator.userAgent.toLowerCase();
	let browser = '';

	if (userAgent.includes('chrome')) {
		browser = 'Chrome';
	} else if (userAgent.includes('firefox')) {
		browser = 'Firefox';
	} else if (userAgent.includes('safari')) {
		browser = 'Safari';
	} else if (userAgent.includes('edge')) {
		browser = 'Edge';
	}
	return { browser };
}

export const getIPV4 = () => {
	// TODO
	return "";
}

export const getInfosFromDevice = () => {
	const userAgent = navigator.userAgent.toLowerCase();
	const ipv4 = getIPV4();

	const deviceDetector = new DeviceDetector();
	const device = deviceDetector.parse(userAgent);

	const os = device?.os?.name || "";
	const os_version = device?.os?.version || "";
	const device_model = device?.device?.model || "";
	const device_fac = device?.device?.brand || "";
	const browser = device?.client?.name || "";
	const browserVersion = device?.client?.version || "";
	const type = device?.device?.type || "";
	const brand = device?.device?.brand || "";

	return { os, device_model, device_fac, os_version, ipv4, browser, browserVersion, userAgent, type, brand }
}

export const getCameraInformation = async (): Promise<string> => {
	let resolution;

	await navigator.mediaDevices.getUserMedia({
		video: true
	}).then((stream) => {
		const videoTrack = stream.getVideoTracks()[0];
		const settings = videoTrack.getSettings();
		resolution = `${settings.width}x${settings.height}`
		videoTrack.stop();
	})
		.catch(() => {
			console.error('Error to get cam params');
			resolution = "640x480";
		})

	return resolution;
}
