import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IEncrypted } from 'src/app/types/domain';
import { encrypt } from 'src/app/helper/crypt';
import { CatchError } from 'src/app/types/error';
import { CharonService } from '../charon/charon.service';
import { HttpService } from '../http.service';

@Injectable({
	providedIn: 'root',
})
export class LogsService {
	constructor(
		private charonService: CharonService,
		private httpService: HttpService,
	) { }

	charonLinks: any;
	caronte_logs: any;
	caronte_register: any;
	caronte_parameters: any;
	caronte_facequality: any;
	caronte_session = sessionStorage.getItem('caronte_session');
	caronte_header = sessionStorage.getItem('caronte_header');

	conectCaronte(callback?: any, errorCalback?: any) {
		this.charonService.getLinks().subscribe(resultado => {
			this.charonLinks = resultado;
			this.caronte_session = resultado.session;
			this.caronte_header = resultado.charon_session;
			this.caronte_logs = resultado.logs;
			this.caronte_register = resultado.register;
			this.caronte_facequality = resultado.facequality;
			this.caronte_parameters = resultado.parameters;

			sessionStorage.setItem('caronte_session', this.caronte_session);
			sessionStorage.setItem('caronte_register', this.caronte_register);
			sessionStorage.setItem('caronte_header', this.caronte_header);
			if (callback) callback();
		}, error => {
			console.error(CatchError.requestFail, `Error: ${error}`);
			if (errorCalback) errorCalback();
		});
	}

	async handleLogs(title: any, textmessage: any, logCode?: string) {
		const infos_logs = {
			chave_captura: sessionStorage.getItem('captureKey'),
			nom_titulo: title,
			mensagem: textmessage,
			id_pessoa: localStorage.getItem('client_id'),
			canal_origem: localStorage.getItem('origin_process'),
		}
		const { key, payload } = await this.setCript(infos_logs);

		const error = (errosession) => {
			console.error(CatchError.requestFail, `Error: ${errosession}`);
		}

		const params = {
			key,
			token: sessionStorage.getItem('token'),
			apikey: this.charonService.randomText(6),
			session: this.caronte_header,
			correlationID: this.charonService.generateUUID(),
			logCode: logCode ? `CW_FRONT_${logCode}` : null
		}

		try {
			this.httpService.postData(
				this.caronte_logs,
				payload,
				params,
				() => { },
				error,
			)
		} catch (error) {
			console.error(CatchError.sendLogsFail, `Error: ${error}`)
		}
	}

	async setCript(payload_var: any): Promise<IEncrypted> {
		if (environment.disableCripto) {
			return {
				payload: payload_var,
				key: '',
			};
		}
		try {
			return await encrypt(JSON.stringify(payload_var));
		} catch (error) {
			console.error(CatchError.requestFail, `Error: ${error}`);
			return {
				payload: '',
				key: '',
			};
		}
	}

}