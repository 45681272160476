<div class="app-upload">
    <div class="app-upload-bar">
        <app-bar title="reconhecimento facial"></app-bar>
    </div>
    <div class="app-upload-body">
        <div class="content">
            <div class="animation">
                <img aria-hidden="true" src="assets/upload.png" />
            </div>
            <h2>enviando sua foto</h2>
        </div>
    </div>
</div>