import { Injectable } from '@angular/core';
import { LivenessCheckProcessor } from '../../assets/libs/facetec/FaceTecAngularSampleApp/src/processors/LivenessCheckProcessor';
import { Config } from '../../assets/libs/facetec/Config';
import { facetecLabels } from '../../assets/libs/facetec/facetec-labels';
import { SampleApp } from '../../../src/assets/libs/facetec/FaceTecAngularSampleApp/src/sampleAppController';
import { Router } from '@angular/router';

@Injectable({
  providedIn: "root",
})
export class FacetecService {

  constructor(
    public router: Router
  ) { }

  token = '';
  showButton = true;
  loading: boolean = true;

  async initFacetec(facetecLicenseKey: string, dateExpiryKey: string, currentToken: string, publicKey?: string, deviceKey?: string) {
    this.token = currentToken;
    // @ts-ignore
    FaceTecSDK.setResourceDirectory('assets/libs/facetec/core-sdk/FaceTecSDK.js/resources');
    // @ts-ignore
    FaceTecSDK.setImagesDirectory('assets/libs/facetec/core-sdk/FaceTec_images');
    // @ts-ignore
    FaceTecSDK.setCustomization(Config.retrieveConfigurationWizardCustomization(FaceTecSDK));
    // @ts-ignore
    FaceTecSDK.setDynamicDimmingCustomization(Config.retrieveLowLightConfigurationWizardCustomization(FaceTecSDK));

    const keys = "{  \"domains\": \"\",  \"expiryDate\": \"" + dateExpiryKey + "\",  \"key\": \"" + facetecLicenseKey + "\"}"
    // @ts-ignore
    Config.initializeFromAutogeneratedConfig(FaceTecSDK, keys,
      (initializedSuccessfully: boolean) => {
        if (initializedSuccessfully) {
          // @ts-ignore
          FaceTecSDK.configureLocalization(facetecLabels);
          this.showButton = false
        }
        // @ts-ignore
        console.info('$ Inicializado o módulo FaceTec; Retorno: ', FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus()));
      },
      publicKey, deviceKey
    );

  }

  startCam(): void {
    try {
      this.onLivenessCheckPressed();
    } catch (e) {
      console.error(e);
    }
  }

  async onLivenessCheckPressed(): Promise<void> {
    new Promise(async () => {
      let liveness = new LivenessCheckProcessor(this.token as unknown as string, SampleApp as any, this.router);
      this.loading = true;
      let startCamLoad = setInterval(() => {
        if (liveness.latestSessionResult?.status) {
          clearInterval(startCamLoad);
          this.loading = false;
        }
      }, 1000);
    });
  }
}