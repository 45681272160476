<main class="main-container">
  <ngx-spinner name='default' bdColor = "#ffffff80" size = "medium" color = "#ec7000" type = "ball-clip-rotate" [fullScreen] = "false">
    <p style="color: whitesmoke" id="titlespinner"> Carregando... </p>
  </ngx-spinner>
  <ngx-spinner name='primary' bdColor="white" size="medium" color="#ec7000" type="ball-clip-rotate" [fullScreen]="false">
  </ngx-spinner>
  <ngx-spinner [hidden]="false" name='first' bdColor="white" size="medium" color="#ec7000" type="ball-clip-rotate" [fullScreen]="false">
  </ngx-spinner>
  <app-loader [ligado]="getLoading()"></app-loader>
  <router-outlet></router-outlet> 
</main>