import { Subject } from 'rxjs';
declare const window: any;
declare const CW: any;
export const capture$ = new Subject();

window.getServerPackage = (pack: string) => capture$.next(pack);

export const startAutoCapture = () => new Promise((resolve, reject) => {
  const start = () => {
    try {
      return resolve(CW.startAutoCapture());
    } catch (e) {
      console.log('aqui_17', e);
      return reject(e);
    }
  };
  if (CW.startAutoCapture) { return start(); }
  const timeout = setTimeout(() => reject(new Error(`auto capture not available`)), 1000);
  CW.onAutoCaptureReady = () => {
    clearTimeout(timeout);
    return start();
  };
});

export const isMobile = (): boolean => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const cameraPortrait = {
  audio: false,
  video: true,
};

let width_body = Math.max(
  document.body.scrollWidth,
  document.documentElement.scrollWidth,
  document.body.offsetWidth,
  document.documentElement.offsetWidth,
  document.documentElement.clientWidth,
);

const height_body = Math.max(
  document.body.scrollHeight,
  document.documentElement.scrollHeight,
  document.body.offsetHeight,
  document.documentElement.offsetHeight,
  document.documentElement.clientHeight,
);

if ((screen.width < 0) || (screen.width === 375)) {
  width_body = 390;
}

const cameraLandscape = {
  audio: false,
  video: {
    width: { min: width_body, ideal: width_body, max: 1920 },
    height: { min: height_body, ideal: height_body, max: 1080 },
  },
};
export const constraints = () => isMobile() ? cameraPortrait : cameraLandscape;

let isResizeEventSet = false;

const lowerLimit = (x: number, limits: number[]): number => {
  const less = limits.filter(limit => x < limit);
  return less.length === 0 ? x : Math.max.apply(null, less);
};

const upperLimit = (x: number, limits: number[]): number => {
  const greater = limits.filter(limit => x > limit);
  return greater.length === 0 ? x : Math.min.apply(null, greater);
};

const limitIt = (value: number, lowerLimits: number[], upperLimits: number[]) => {
  return upperLimit(lowerLimit(value, lowerLimits), upperLimits);
};

export const drawIndicator = (
  _: any,
  video: any,
  canvas: any,
  os: any,
) => {
  if (!isResizeEventSet) {
    isResizeEventSet = true;
    window.addEventListener('resize', (a: any) => drawIndicator(a, video, canvas, os), true);
  }

  const ctx = canvas.getContext('2d');
  const black = 'rgba(0,0,0,1)';
  const grey = 'rgba(40, 48, 69, 0.3)';

  // config >>
  const canvasWidth = screen.width;
  const canvasHeight = document.getElementsByTagName('body')[0].offsetHeight - 64;
  // Quanto menor, maior a elipse
  let ellipseMargin = 120;
  if ((canvasWidth < 0) || (canvasWidth === 375)) {
    ellipseMargin = 100;
  } else if ((canvasWidth < 0) || (canvasWidth <= 320)) {
    ellipseMargin = 100;
  } else if ((canvasWidth === 414) && canvasHeight === 500) {
    ellipseMargin = 100;
  }
  let ellipseWidth = Math.min(screen.width - ellipseMargin, 600, video.videoWidth);
  if ((canvasWidth === 414) && canvasHeight === 500) {
    ellipseWidth = 290;
  }
  const ellipseHeight = limitIt(ellipseWidth * 2.4, [0], [canvasHeight - ellipseMargin]);
  // config <<

  // derivations >>
  const circleRadius = Math.min(ellipseWidth / 2, ellipseHeight / 2);
  const circleDiameter = 2 * circleRadius;

  // Variavel de controle para altura da área de captura sem as bordas arredondadas, levando em conta a resolução do UX 320 x 540, quanto maior o numero da variavel, menor o retangulo, valor padrão: 98
  const rectangleHeightCompensation = 88;
  let rectangleHeightCompensationCalculated = (rectangleHeightCompensation * canvasWidth) / 320;

  // Variavel de controle para posicionamento do Eixo Y, levando em conta a resolução do UX 320 x 540, quanto maior, mais perto do topo
  let rectangleYCompensation = 70;

  // Arrumando para iPhone 8
  if ((canvasWidth < 0) || (canvasWidth <= 320)) {
    ellipseMargin = 130;
    rectangleYCompensation = 1;
    rectangleHeightCompensationCalculated = 58;
  }
  // rectangleHeightCompensationCalculated = 120;
  // Altura da área de captura sem as bordas arredondadas.
  let rectangleHeight = lowerLimit(ellipseHeight - circleDiameter, [0]) - rectangleHeightCompensationCalculated;
  if ((rectangleHeight < 0) || (rectangleHeight === -8.4375)) {
    rectangleHeight = 102.75;
    rectangleYCompensation = 50;
  }

  // ellipseMargin - Quanto menor, maior a elipse
  // rectangleHeight - Altura da área de captura sem as bordas arredondadas.
  // rectangleYCompensation - Variavel de controle para posicionamento do Eixo Y,
  // levando em conta a resolução do UX 320 x 540, quanto maior,
  // mais perto do topo

  // Arrumando para iPhone 8

  if ((canvasWidth < 0) || (canvasWidth <= 320)) {
    ellipseMargin = 130;
    // rectangleHeight = 98.75;
    rectangleHeight = 112;
    rectangleYCompensation = 40;
  } else if ((canvasWidth === 375) && ((canvasHeight + 64) <= 667)) {
    // Apple iPhone SE (2020)
    console.log('Apple iPhone SE (2020)');
    // ellipseMargin = 130;
    rectangleHeight = 132;
    rectangleYCompensation = 30;
  } else if ((canvasWidth === 360) && ((canvasHeight + 64) <= 540)) {
    // BlackBerry Keyone
    console.log('BlackBerry Keyone');
    // ellipseMargin = 130;
    rectangleHeight = 116;
    rectangleYCompensation = 26;
  } else if ((canvasWidth === 411) && ((canvasHeight + 64) <= 731)) {
    // Motorola Nexus 6
    console.log('Motorola Nexus 6');
    // ellipseMargin = 130;
    rectangleHeight = 166;
    rectangleYCompensation = 46;
  } else if ((canvasWidth === 480) && ((canvasHeight + 64) <= 853)) {
    // One Plus 3
    console.log('One Plus 3');
    // ellipseMargin = 130;
    // rectangleHeight = 177;
    rectangleYCompensation = 46;
  } else if ((canvasWidth === 360) && ((canvasHeight + 64) <= 740)) {
    // Samsung Galaxy 9
    console.log('Samsung Galaxy 9');
    // ellipseMargin = 130;
    // rectangleHeight = 177;
    rectangleYCompensation = 58;
  } else if ((canvasWidth === 360) && ((canvasHeight + 64) <= 760)) {
    // Samsung S10
    console.log('Samsung S10');
    // ellipseMargin = 130;
    // rectangleHeight = 177;
    rectangleYCompensation = 50;
  } else if ((canvasWidth === 393) && ((canvasHeight + 64) <= 851)) {
    // Xiomi Mi 10 Pro
    console.log('Xiomi Mi 10 Pro');
    // ellipseMargin = 130;
    // rectangleHeight = 177;
    rectangleYCompensation = 50;
  } else if ((canvasWidth === 414) && ((canvasHeight + 64) <= 896)) {
    // iPhone 11 Pro Max
    console.log('iPhone 11 Pro Max');
    // ellipseMargin = 130;
    // rectangleHeight = 177;
    rectangleYCompensation = 50;
  } else if ((canvasWidth === 390) && ((canvasHeight + 64) <= 844)) {
    // iPhone 13
    console.log('iPhone 13');
    // ellipseMargin = 130;
    // rectangleHeight = 177;
    rectangleYCompensation = 50;
  } else if ((canvasWidth === 428) && ((canvasHeight + 64) <= 926)) {
    // iPhone 13 Pro Max
    console.log('iPhone 13 Pro Max');
    // ellipseMargin = 130;
    // rectangleHeight = 177;
    rectangleYCompensation = 50;
  } else if ((canvasWidth === 375) && ((canvasHeight + 64) <= 812)) {
    // iPhone 13 Mini
    console.log('iPhone 13 Mini');
    // ellipseMargin = 130;
    // rectangleHeight = 177;
    rectangleYCompensation = 50;
  } else if ((canvasWidth === 476) && ((canvasHeight + 64) <= 847)) {
    // iPhone 7 plus
    console.log('iPhone 7 plus');
    // ellipseMargin = 130;
    // rectangleHeight = 177;
    rectangleYCompensation = 50;
  } else if ((canvasWidth === 400) && ((canvasHeight + 64) <= 700)) {
    // ellipseMargin = 130;
    // rectangleHeight = 177;
    rectangleYCompensation = 50;
  }

  if (rectangleHeight < 0) {
    rectangleHeight = rectangleHeight * -1;
  }

  if ((canvasWidth === 414) && canvasHeight === 500) {
    console.log('Verifica_223');
    rectangleHeight = 88;
    rectangleYCompensation = 30;
  } else if ((canvasWidth === 414) && canvasHeight === 564) {
    console.log('Verifica_232');
    rectangleHeight = 88;
    rectangleYCompensation = 30;
  } else if ((canvasWidth === 375) && canvasHeight === 489) {
    console.log('Verifica_236');
    rectangleHeight = 68;
    rectangleYCompensation = 22;
  } else if ((canvasWidth === 375) && canvasHeight === 425) {
    console.log('Verifica_240');
    rectangleHeight = 68;
    rectangleYCompensation = 22;
  } else if ((os === 'iOS') && (canvasWidth < 375)) {
    console.log('Verifica_244');
    rectangleHeight = 68;
    rectangleYCompensation = 22;
    // video.videoWidth = canvasWidth;
  }
  console.log('Verifica_227');
  console.log('C: ' + canvasWidth + ' H: ' + canvasHeight + '__RH: ' + rectangleHeight + '__RHC: ' + rectangleHeightCompensation + '__EL: ' + ellipseHeight);
  const rectangleX = (canvasWidth / 2) - (ellipseWidth / 2);
  // Variavel de controle para posicionamento do Eixo Y, levando em conta a resolução do UX 320 x 540, quanto maior, mais perto do topo
  const rectangleYCompensationCalculated = (rectangleYCompensation * canvasWidth) / 320;
  if (os === 'iOS') {
    // alert('canvasWidth: ' + canvasWidth + '_canvasHeight: ' + canvasHeight + '_canvasHeight+64: ' + canvasHeight + 64 + '_rectangleYCompensation: ' + rectangleYCompensation + '_RHC: ' + rectangleHeight);
  }
  // rectangleHeight = 130;
  // Altura da elipse no eixo Y
  const rectangleY = (canvasHeight / 2) - (rectangleHeight / 2) - rectangleYCompensationCalculated;
  const topCircleX = rectangleX + (ellipseWidth / 2);
  const topCircleY = rectangleY;
  const bottomCircleX = rectangleX + (ellipseWidth / 2);
  const bottomCircleY = rectangleY + rectangleHeight;
  // derivations <<
  // alert('C: ' + canvasWidth + '__RH: ' + rectangleHeight + '__RHC: ' + rectangleHeightCompensation + '__EL: ' + ellipseHeight);
  console.log(`[cam][drawIndicator] canvasWidth_new: ${canvasWidth}`);
  console.log(`[cam][drawIndicator] canvasHeight_new: ${canvasHeight + 64}`);
  // logs
  console.log(`[cam][drawIndicator] rectangleHeight: ${rectangleHeight}`);
  console.log(`[cam][drawIndicator] canvasWidth: ${canvasWidth}`);
  console.log(`[cam][drawIndicator] canvasHeight: ${canvasHeight}`);
  console.log(`[cam][drawIndicator] ellipseMargin: ${ellipseMargin}`);
  console.log(`[cam][drawIndicator] ellipseWidth: ${ellipseWidth}`);
  console.log(`[cam][drawIndicator] ellipseHeight: ${ellipseHeight}`);
  console.log(`[cam][drawIndicator] rectangleX: ${rectangleX}`);
  console.log(`[cam][drawIndicator] rectangleY: ${rectangleY}`);
  console.log(`[cam][drawIndicator] rectangleYCompensation: ${rectangleYCompensation}`);
  console.log(`[cam][drawIndicator] rectangleHeightCompensationCalculated: ${rectangleHeightCompensationCalculated}`);
  console.log(`[cam][drawIndicator] rectangleYCompensationCalculated: ${rectangleYCompensationCalculated}`);

  // canvas size
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  // background
  ctx.clearRect(0, 0, canvasWidth, canvasHeight);
  ctx.fillStyle = grey;
  ctx.fillRect(0, 0, canvasWidth, canvasHeight);

  // crop mode
  ctx.globalCompositeOperation = 'destination-out';

  // ellipse top (1/ circle)
  ctx.beginPath();
  ctx.fillStyle = black;
  ctx.arc(topCircleX, topCircleY, circleRadius, Math.PI, 2 * Math.PI);
  ctx.fill();

  // ellipse body (rectangle)
  ctx.fillRect(rectangleX, rectangleY, ellipseWidth, rectangleHeight);

  // ellipse bottom (1/2 circle)
  ctx.beginPath();
  ctx.fillStyle = black;
  ctx.arc(bottomCircleX, bottomCircleY, circleRadius, 0, Math.PI);
  ctx.fill();
};
