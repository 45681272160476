import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorsPage } from '../../interfaces/ErrorsPage'
import { verifyUrlOfAgent } from '../../../app/shared/helpers/verification';
import { ItauDigitalAnalyticsView } from '@app-analytics/analytics';
import { ERROR_BLOCKED, ERROR_CAM_NOT_RESPOND_PAGE, ERROR_CAM_PERMISSION_PAGE, ERROR_GENERIC, ERROR_LIMIT_EXCEED } from '../../shared/helpers/paramsGa4';
import { MainService } from '../../services/main.service';
import {
  LOGS_ERROR_PAGE_BLOCK, LOGS_ERROR_PAGE_GENERIC, LOGS_ERROR_PAGE_NO_RESPOSE, LOGS_ERROR_PAGE_PERMISSION, LOGS_ERROR_PAGE_TRIES
} from '../../shared/helpers/stepsToLogs';

const ERROR_URL = 'www.itau.com.br/';

@Component({
  selector: 'error-root',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  title: string;
  description: string;
  button: string;
  icon: string;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private itauDigitalAnalytics: ItauDigitalAnalyticsView,
    public mainService: MainService
  ) { }

  ngOnInit() {
    this.handleRoute();
    this.setRedirectUrlError();
  }

  handleRoute() {
    const toggle = localStorage.getItem('toggle_logs');

    switch (this.route.snapshot?.queryParams?.errorCode) {
      case ErrorsPage.CAMERA_CONFIG:
        this.title = 'Autorize o acesso à câmera para confirmar sua identidade';
        this.icon = 'aviso_outline';
        this.description = 'Por favor, autorize o acesso à câmera para continuar com o reconhecimento facial';
        this.button = 'Abrir configurações';
        this.itauDigitalAnalytics.track(ERROR_CAM_PERMISSION_PAGE);
        if (toggle === 'true') {
          this.mainService.insightsAPI(LOGS_ERROR_PAGE_PERMISSION);
        }
        break;
      case ErrorsPage.CAMERA_ERROR:
        this.title = 'A câmera não respondeu, por isso não conseguimos fazer seu reconhecimento facial';
        this.icon = 'maquina_fotografica_outline';
        this.description = 'Sem sua câmera, não é possível tirar sua foto para o reconhecimento facial';
        this.button = 'Fechar';
        this.itauDigitalAnalytics.track(ERROR_CAM_NOT_RESPOND_PAGE);
        if (toggle === 'true') {
          this.mainService.insightsAPI(LOGS_ERROR_PAGE_NO_RESPOSE);
        }
        break;
      case ErrorsPage.LIMIT_ERROR:
        this.title = 'Você atingiu o limite de tentativas de captura';
        this.icon = 'aviso_outline';
        this.description = 'Por segurança, não conseguimos seguir com a solicitação sem confirmar sua identidade. Tente novamente mais tarde';
        this.button = 'Fechar';
        this.itauDigitalAnalytics.track(ERROR_LIMIT_EXCEED);
        if (toggle === 'true') {
          this.mainService.insightsAPI(LOGS_ERROR_PAGE_TRIES);
        }
        break;
      case ErrorsPage.LIMIT_BLOCK:
        this.title = 'Você atingiu o limite de tentativas de captura';
        this.icon = 'aviso_outline';
        this.description = 'Por segurança, não conseguimos seguir com a solicitação sem confirmar sua identidade. Tente novamente amanhã';
        this.button = 'Fechar';
        this.itauDigitalAnalytics.track(ERROR_BLOCKED);
        if (toggle === 'true') {
          this.mainService.insightsAPI(LOGS_ERROR_PAGE_BLOCK);
        }
        break;
      case ErrorsPage.FAIL_KEY:
        this.title = 'O tempo para tirar sua foto acabou';
        this.icon = 'aviso_outline';
        this.description = 'Por segurança, não conseguimos seguir com a solicitação. Tente novamente mais tarde';
        this.button = 'Fechar';
        this.itauDigitalAnalytics.track(ERROR_GENERIC);
        if (toggle === 'true') {
          this.mainService.insightsAPI(LOGS_ERROR_PAGE_GENERIC);
        }
        break;
      case ErrorsPage.ERROR_SERVER:
        this.title = 'Desculpe, algo deu errado';
        this.icon = 'aviso_outline';
        this.description = 'Não foi possível carregar as informações neste momento. Por favor, tente outra vez';
        this.button = 'Fechar';
        this.itauDigitalAnalytics.track(ERROR_GENERIC);
        if (toggle === 'true') {
          this.mainService.insightsAPI(LOGS_ERROR_PAGE_GENERIC);
        }
        break;
      default:
        this.title = 'Desculpe, algo deu errado';
        this.icon = 'aviso_outline';
        this.description = 'Não foi possível carregar as informações neste momento. Por favor, tente outra vez';
        this.button = 'Fechar';
        this.itauDigitalAnalytics.track(ERROR_GENERIC);
        if (toggle === 'true') {
          this.mainService.insightsAPI(LOGS_ERROR_PAGE_GENERIC);
        }
        break;
    }
  }

  handleButton() {
    if (this.route.snapshot?.queryParams?.errorCode === ErrorsPage.CAMERA_CONFIG) {
      window.open(verifyUrlOfAgent(), '_blank');
    } else {
      this.callRedirect();
    }
  }

  callRedirect() {
    const params = sessionStorage.getItem('paramsUrl');
    let type;
    switch (this.route.snapshot?.queryParams?.errorCode) {
      case ErrorsPage.CAMERA_CONFIG:
        type = 'error_no_permission';
        break;
      case ErrorsPage.CAMERA_ERROR:
        type = 'error_no_permission';
        break;
      case ErrorsPage.LIMIT_ERROR:
        type = 'error_no_frame'; // Aqui precisamos de um erro especifico
        break;
      case ErrorsPage.LIMIT_BLOCK:
        type = 'error_no_frame'; // Aqui precisamos de um erro especifico
        break;
      case ErrorsPage.FAIL_KEY:
        type = 'no_session';
        break;
      case ErrorsPage.ERROR_SERVER:
        type = 'no_session';
        break;
    }
    window.document.location.href = `https://${sessionStorage.getItem('redirectUrlError')}/?&type=${type}${params}`;
  }

  setRedirectUrlError() {
    const url = sessionStorage.getItem('redirectUrlError');
    if(!url) {
      sessionStorage.setItem('redirectUrlError', ERROR_URL);
    }
  }
}
