<style type="text/css">
  #liveness-button { width: 100% !important; }
  #DOM_FT_PRIMARY_TOPLEVEL_mainContainer { border: 0 !important; width: 100% !important; }
  #DOM_FT_PRIMARY_TOPLEVEL_mainContainer { border: 0 !important; width: 100% !important; }
  #DOM_FT_mainInterfaceNonOverlayContainer { width: 100% !important;  border-color: none !important; border:0 !important; }
  .DOM_FT_feedbackStringMeasurementSpan { border: 0 !important; width: 100% !important; }
  #DOM_FT_videoElement { border: 0 !important; width: 100% !important; }
  #DOM_FT_frameContainer { border: 0 !important; width: 100% !important; }
  #DOM_FT_feedbackBarContainer { border: 0 !important; width: 100% !important; top: 0px !important; }
  #DOM_FT_feedbackBarElement { border: 0 !important; width: 100% !important; }
  #DOM_FT_getReadyAndRetryScreenContainer { border: 0 !important; width: 100% !important; }
</style>
<div class="wrapping-box-container">
    <div id="controls" class="controls">
        <!-- Button that will begin a Liveness Check -->
        <button id="liveness-button" disabled (click)="onLivenessCheckPressed()" class="big-button">Capturar Imagem</button>
        <!-- The status container that will be updated appropriately based on current state of FaceTecSDK -->
        <p id="status">Inicializando...</p>
    </div>
  </div>
