import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class ErrorcallsService {
  fields = {
    code: 0,
    status: '',
    message: '',
    showPopupError: false,
  };
  constructor() { }

  // Função de tratamento de erros
  allErros(fields_error: any, error_api: string) {
    console.log(error_api);
    const resposta = error_api;
    console.log('resposta', resposta);
    this.fields.code = fields_error.status;
    if (fields_error.status === 400) {
      this.fields.message = 'Servidor fora do Ar.';
      // if(fields_error.statusText)
    } else if (fields_error.status === 401) {
      this.fields.message = 'Autenticação não encontrada.';
    } else if (fields_error.status === 404) {
      this.fields.message = 'O Servidor não pode encontrar o recurso solicitado.';
    } else if (fields_error.status === 500) {
      this.fields.message = 'O servidor encontrou uma situação com a qual não consegue lidar.';
    } else if (fields_error.status === 502) {
      this.fields.message = 'O Gateway retornou uma resposta inválida.';
    } else if (fields_error.status === 504) {
      this.fields.message = 'O Gateway demorou a responder.';
    }
    this.fields.message = this.allErrosMessages(error_api);

    this.fields.showPopupError = true;
    return this.fields;
  }

  // Função de tratamento de mensagem de erros
  allErrosMessages(messagem_param: string) {
    let resposta_param = messagem_param;
    if ((messagem_param !== '') && (messagem_param !== 'undefined') && (messagem_param !== undefined) && (messagem_param !== null)) {
      if (messagem_param === 'Missing required request parameters: [x-itau-apikey]') {
        resposta_param = 'Parâmetros de solicitação obrigatórios estão ausentes: [x-itau-apikey]';
      } else if (messagem_param === 'Route not found') {
        resposta_param = 'Rota não encontrada, possivelmente o Header [x-itau-apikey] e/ou X-Charon-Session não foram encontrados.';
      } else if (messagem_param === 'Missing request header \'x-itau-flowID\' for method parameter of type String') {
        resposta_param = 'Cabeçalho de solicitação ausente \'x-itau-flowID\' para parâmetro de método do tipo String.';
      } else if (messagem_param === 'feature CREATE_SESSION failed') {
        resposta_param = 'Sua sessão expirou ou não foi encontrada. Retorne ao site e tente novamente.';
        this.fields.status = 'Error_Session';
      }
    }
    return resposta_param;
  }

  isFacetecError(): boolean {
    // this.fields.showPopupError = true;
    // this.fields.message = 'teste facetec'
    return this.fields.showPopupError;
  }
}
