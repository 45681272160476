import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-instructions-popup',
  templateUrl: './instructions-popup.component.html',
  styleUrls: ['./instructions-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InstructionsPopupComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() buttonLabel: string;
  @Input() instructions: { icon: string, text: string };
  @Output() didClick = new EventEmitter();
  @Input() nameId: string;

  constructor() { }

  ngOnInit(): void {
  }

  click() {
    this.didClick.emit();
  }
}
