import { environment } from '../../environments/environment';

export var Config = (function () {
    // -------------------------------------
    // REQUIRED
    // Available at https://dev.facetec.com/account
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    var DeviceKeyIdentifier = "dvGH6mawVRC7xlVk6Fxl4LwACtYo4ush";

    // -------------------------------------
    // REQUIRED
    // The URL to call to process FaceTec SDK Sessions.
    // In Production, you likely will handle network requests elsewhere and without the use of this variable.
    // See https://dev.facetec.com/security-best-practices?link=facetec-server-rest-endpoint-security for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    var BaseURL = "https://prod-core-api.bioservices.certisign.com.br/api/v1";
    // var BaseURL = "https://dev-api.bioservices.certisign.com.br/api/v1";

    // -------------------------------------
    // REQUIRED
    // The FaceScan Encryption Key you define for your application.
    // Please see https://dev.facetec.com/facemap-encryption-keys for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    /*var PublicFaceScanEncryptionKey =
        "-----BEGIN PUBLIC KEY-----\n" +
        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvDePe9bwQcZ4B/pfxOX/\n" +
        "Tjg4CsJiEudtBkbuQE8IJ4Xi2SAOMTYBMQpH1KhopuKo2HCtcLuG92ezy6NlycSq\n" +
        "csXiKsD+AWiSpd2q7dhzf0N8yRlFYP/2SPwh6gC90h+AYm1O4phHn2X6Q5WdEL1A\n" +
        "i0Yawc+UJnUN/jm/I3DPwqyKS4sRXEN3dBkkV9cvg4srxqfLjAHQWDWDMZHucdXh\n" +
        "oP+5eqzAB+WFDVfT9QAsp0wMxRx1bqWdMiIWxlF895mvrDRUVu7fqHA9MiTAzmF9\n" +
        "rqESM6gUaZ3EL+6jfTkZacMMR+gIkhNiAKGPm5tQOGy9kr0ziumLSYpHuvFcsw2A\n" +
        "ywIDAQAB\n" +
        "-----END PUBLIC KEY-----";
        
        var PublicFaceScanEncryptionKey =
        "-----BEGIN PUBLIC KEY-----\n" +
        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5PxZ3DLj+zP6T6HFgzzk\n" +
        "M77LdzP3fojBoLasw7EfzvLMnJNUlyRb5m8e5QyyJxI+wRjsALHvFgLzGwxM8ehz\n" +
        "DqqBZed+f4w33GgQXFZOS4AOvyPbALgCYoLehigLAbbCNTkeY5RDcmmSI/sbp+s6\n" +
        "mAiAKKvCdIqe17bltZ/rfEoL3gPKEfLXeN549LTj3XBp0hvG4loQ6eC1E1tRzSkf\n" +
        "GJD4GIVvR+j12gXAaftj3ahfYxioBH7F7HQxzmWkwDyn3bqU54eaiB7f0ftsPpWM\n" +
        "ceUaqkL2DZUvgN0efEJjnWy5y1/Gkq5GGWCROI9XG/SwXJ30BbVUehTbVcD70+ZF\n" +
        "8QIDAQAB\n" +
        "-----END PUBLIC KEY-----"    
        
        */
  
    var PublicFaceScanEncryptionKey =
        "-----BEGIN PUBLIC KEY-----\n" +
        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5PxZ3DLj+zP6T6HFgzzk\n" +
        "M77LdzP3fojBoLasw7EfzvLMnJNUlyRb5m8e5QyyJxI+wRjsALHvFgLzGwxM8ehz\n" +
        "DqqBZed+f4w33GgQXFZOS4AOvyPbALgCYoLehigLAbbCNTkeY5RDcmmSI/sbp+s6\n" +
        "mAiAKKvCdIqe17bltZ/rfEoL3gPKEfLXeN549LTj3XBp0hvG4loQ6eC1E1tRzSkf\n" +
        "GJD4GIVvR+j12gXAaftj3ahfYxioBH7F7HQxzmWkwDyn3bqU54eaiB7f0ftsPpWM\n" +
        "ceUaqkL2DZUvgN0efEJjnWy5y1/Gkq5GGWCROI9XG/SwXJ30BbVUehTbVcD70+ZF\n" +
        "8QIDAQAB\n" +
        "-----END PUBLIC KEY-----";

    // -------------------------------------
    // REQUIRED IF: You have had a Production Key generated for you.
    // Production Keys are generated for your account once all steps for Going Production have been completed.
    // More information on Going Production can be found at https://dev.facetec.com/going-production-overview.
    //
    // NOTE:  DO NOT hard code this into your actual App. This variable is coded into the App for demonstration purposes only.
    // Your App MUST serve down the key data via your own API. This allows you to change/update keys without making a client-side change.
    // Please see https://dev.facetec.com/licensing?link=integrating-production-licenses for more information.
        // var ProductionKeyText =
        // "{  \"domains\": \"\",  \"expiryDate\": \"2023-04-10\",  \"key\": \"00304402207505c426d73ef16ec76cde2c362acc07f9952f4aaf5403f598ec81488c87b84c022021cf5ba21e18e1f4641d6915e3324ae86f18fd3f8d5d9d7094ed30eb15c3f1e6\"}"
    

    // -------------------------------------
    // Convenience method to initialize the FaceTec SDK.
    // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.

    //     function initializeFromAutogeneratedConfig(FaceTecSDK, callback) {
    //         FaceTecSDK.initializeInProductionMode(ProductionKeyText, this.DeviceKeyIdentifier, this.PublicFaceScanEncryptionKey,
    //             function (initializedSuccessfully) {
    //                 callback(initializedSuccessfully);
    //             });
    //     };


    //     // This app can modify the customization to demonstrate different look/feel preferences
    //     // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
    //     function retrieveConfigurationWizardCustomization(FaceTecSDK) {
    //         var sdkImageDirectory = "../../core-sdk/FaceTec_images/";

    //         // For Color Customization
    //         var outerBackgroundColor = "#ffffff";
    //         var frameColor = "#ffffff";
    //         var borderColor = "#c4c4c4";
    //         var ovalColor = "#878a8c";
    //         var dualSpinnerColor = "#f24f00";
    //         var textColor = "#666666";
    //         var buttonAndFeedbackBarColor =  "#9d9e9f";
    //         var buttonAndFeedbackBarTextColor = "#ffffff";
    //         var buttonColorHighlight = "#bababa";
    //         var buttonColorDisabled = "#ffffff";

    //         // For Frame Corner Radius Customization
    //         let frameCornerRadius = "20px";

    //         // For Cancel Button Customization
    //         var cancelButtonImage = sdkImageDirectory + "FaceTec_cancel.png";
    //         var cancelButtonLocation = FaceTecSDK.FaceTecCancelButtonLocation.TopLeft;

    //         // For image Customization
    //         var yourAppLogoImage = sdkImageDirectory + "FaceTec_your_app_logo.png";
    //         var securityWatermarkImage = FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec_ZoOm;


    //         // Set a default customization
    //         var defaultCustomization = new FaceTecSDK.FaceTecCustomization();


    //         // Set Frame Customization
    //         defaultCustomization.frameCustomization.borderCornerRadius = frameCornerRadius;
    //         defaultCustomization.frameCustomization.backgroundColor = frameColor;
    //         defaultCustomization.frameCustomization.borderColor = borderColor;

    //         // Set Overlay Customization
    //         defaultCustomization.overlayCustomization.brandingImage = yourAppLogoImage;
    //         defaultCustomization.overlayCustomization.backgroundColor = outerBackgroundColor;

    //         // Set Guidance Customization
    //         defaultCustomization.guidanceCustomization.backgroundColors = frameColor;
    //         defaultCustomization.guidanceCustomization.foregroundColor = textColor;
    //         defaultCustomization.guidanceCustomization.buttonBackgroundNormalColor = buttonAndFeedbackBarColor;
    //         defaultCustomization.guidanceCustomization.buttonBackgroundDisabledColor = buttonColorDisabled;
    //         defaultCustomization.guidanceCustomization.buttonBackgroundHighlightColor = buttonColorHighlight;
    //         defaultCustomization.guidanceCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor;
    //         defaultCustomization.guidanceCustomization.buttonTextDisabledColor = buttonAndFeedbackBarTextColor;
    //         defaultCustomization.guidanceCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor;
    //         defaultCustomization.guidanceCustomization.retryScreenImageBorderColor = borderColor;
    //         defaultCustomization.guidanceCustomization.retryScreenOvalStrokeColor = borderColor;

    //         // Set Oval Customization
    //         defaultCustomization.ovalCustomization.strokeColor = ovalColor;
    //         defaultCustomization.ovalCustomization.progressColor1 = dualSpinnerColor;
    //         defaultCustomization.ovalCustomization.progressColor2 = dualSpinnerColor;

    //         // Set Feedback Customization
    //         defaultCustomization.feedbackCustomization.backgroundColor = buttonAndFeedbackBarColor;
    //         defaultCustomization.feedbackCustomization.textColor = buttonAndFeedbackBarTextColor;

    //         // Set Cancel Customization
    //         defaultCustomization.cancelButtonCustomization.customImage = cancelButtonImage;
    //         defaultCustomization.cancelButtonCustomization.location = cancelButtonLocation;

    //         // Set Security Watermark Customization
    //         defaultCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(securityWatermarkImage);

    //         // Set Result Screen Customization
    //         defaultCustomization.resultScreenCustomization.backgroundColors = frameColor;
    //         defaultCustomization.resultScreenCustomization.foregroundColor = textColor;
    //         defaultCustomization.resultScreenCustomization.activityIndicatorColor = buttonAndFeedbackBarColor;
    //         defaultCustomization.resultScreenCustomization.resultAnimationBackgroundColor = buttonAndFeedbackBarColor;
    //         defaultCustomization.resultScreenCustomization.resultAnimationForegroundColor = buttonAndFeedbackBarTextColor;
    //         defaultCustomization.resultScreenCustomization.uploadProgressFillColor = buttonAndFeedbackBarColor;

    //         // Set ID Scan Customization
    //         defaultCustomization.idScanCustomization.selectionScreenBackgroundColors = frameColor;
    //         defaultCustomization.idScanCustomization.selectionScreenForegroundColor = textColor;
    //         defaultCustomization.idScanCustomization.reviewScreenBackgroundColors = frameColor;
    //         defaultCustomization.idScanCustomization.reviewScreenForegroundColor = buttonAndFeedbackBarTextColor;
    //         defaultCustomization.idScanCustomization.reviewScreenTextBackgroundColor = buttonAndFeedbackBarColor;
    //         defaultCustomization.idScanCustomization.captureScreenForegroundColor = buttonAndFeedbackBarTextColor;
    //         defaultCustomization.idScanCustomization.captureScreenTextBackgroundColor = buttonAndFeedbackBarColor;
    //         defaultCustomization.idScanCustomization.buttonBackgroundNormalColor = buttonAndFeedbackBarColor;
    //         defaultCustomization.idScanCustomization.buttonBackgroundDisabledColor = buttonColorDisabled;
    //         defaultCustomization.idScanCustomization.buttonBackgroundHighlightColor = buttonColorHighlight;
    //         defaultCustomization.idScanCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor;
    //         defaultCustomization.idScanCustomization.buttonTextDisabledColor = buttonAndFeedbackBarTextColor;
    //         defaultCustomization.idScanCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor;
    //         defaultCustomization.idScanCustomization.captureScreenBackgroundColor = frameColor;
    //         defaultCustomization.idScanCustomization.captureFrameStrokeColor = borderColor;

    //         // Set Initial Loading Customization
    //         defaultCustomization.initialLoadingAnimationCustomization.backgroundColor = buttonAndFeedbackBarTextColor;
    //         defaultCustomization.initialLoadingAnimationCustomization.foregroundColor = buttonAndFeedbackBarColor;


    //         return defaultCustomization;
    //     };


    //     function retrieveLowLightConfigurationWizardCustomization(FaceTecSDK) {
    //         var defaultCustomization = retrieveConfigurationWizardCustomization(FaceTecSDK);
    //         this.currentLowLightCustomization = defaultCustomization;
    //         return defaultCustomization;
    //     }


    //     function retrieveDynamicDimmingConfigurationWizardCustomization(FaceTecSDK) {
    //         var defaultCustomization = retrieveConfigurationWizardCustomization(FaceTecSDK);
    //         this.currentDynamicDimmingCustomization = defaultCustomization;
    //         return defaultCustomization;
    //     }


    //     var currentCustomization;
    //     var currentLowLightCustomization;
    //     var currentDynamicDimmingCustomization;

    //     // -------------------------------------
    //     // Boolean to indicate the FaceTec SDK Configuration Wizard was used to generate this file.
    //     // In this Sample App, if this variable is true, a "Config Wizard Theme" will be added to this App's Design Showcase,
    //     // and choosing this option will set the FaceTec SDK UI/UX Customizations to the Customizations that you selected in the
    //     // Configuration Wizard.
    //     var wasSDKConfiguredWithConfigWizard = true;



    //     return {
    //         wasSDKConfiguredWithConfigWizard,
    //         DeviceKeyIdentifier,
    //         BaseURL,
    //         PublicFaceScanEncryptionKey,
    //         ProductionKeyText,
    //         initializeFromAutogeneratedConfig,
    //         currentCustomization,
    //         currentLowLightCustomization,
    //         currentDynamicDimmingCustomization,
    //         retrieveConfigurationWizardCustomization,
    //         retrieveLowLightConfigurationWizardCustomization,
    //         retrieveDynamicDimmingConfigurationWizardCustomization
    //     };


    // })();





    function initializeFromAutogeneratedConfig(FaceTecSDK, keys, callback) {
        FaceTecSDK.initializeInProductionMode(keys, this.DeviceKeyIdentifier, this.PublicFaceScanEncryptionKey,
            function (initializedSuccessfully) {
                callback(initializedSuccessfully);
            });
    };

    function retrieveConfigurationWizardCustomization(FaceTecSDK) {
        var sdkImageDirectory = "assets/facetec/images/";


        // Personalizado
        var outerBackgroundColor = "#ffffff";
        var frameColor = "#ffffff";
        var borderColor = "#ffffff";
        var ovalColor = "#EC7000";

        // For Color Customization
        // var outerBackgroundColor = "#ffffff"; //claro
        // var frameColor = "#ffffff";
        // var borderColor = "#f24f00";
        // var ovalColor = "#f24f00";

        var dualSpinnerColor = "#EC7000"; //laranja meio escuro        
        var textColor = "#231D19";        
        var buttonAndFeedbackBarColor = "#f24f00";
        var buttonAndFeedbackBarTextColor = "#ffffff";
        var buttonColorPressed = "#EC7000";
        var DisabledColor = "#CCCCCC";


        // For Frame Corner Radius Customization
        let frameCornerRadius = "20px";

        // For Cancel Button Customization
        // var cancelButtonImage = sdkImageDirectory + "FaceTec_cancel.png";
        var cancelButtonImage = sdkImageDirectory + "FaceTec_cancel_itau.png";
        // var cancelButtonLocation = FaceTecSDK.FaceTecCancelButtonLocation.Disabled;        
        var cancelButtonLocation = FaceTecSDK.FaceTecCancelButtonLocation.TopRight;

        // For image Customization
        var yourAppLogoImage = sdkImageDirectory + "itau_logo.png";
        var securityWatermarkImage = FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec_ZoOm;

        // Set a default customization
        var defaultCustomization = new FaceTecSDK.FaceTecCustomization();

        // Set Frame Customization
        defaultCustomization.frameCustomization.borderCornerRadius = frameCornerRadius;
        defaultCustomization.frameCustomization.backgroundColor = frameColor;
        defaultCustomization.frameCustomization.borderColor = borderColor;

        // Set Overlay Customization
        // Remover Your Logo
        defaultCustomization.overlayCustomization.showBrandingImage = false;
        defaultCustomization.overlayCustomization.brandingImage = ""; // yourAppLogoImage;
        defaultCustomization.overlayCustomization.backgroundColor = ""; // outerBackgroundColor;

        // Set Guidance Customization
        defaultCustomization.guidanceCustomization.backgroundColors = frameColor;        
        defaultCustomization.guidanceCustomization.foregroundColor = textColor; 
        defaultCustomization.guidanceCustomization.buttonBackgroundNormalColor = "#EC7000"; // buttonAndFeedbackBarColor;
        defaultCustomization.guidanceCustomization.buttonBackgroundDisabledColor = DisabledColor;
        // defaultCustomization.guidanceCustomization.buttonBackgroundDisabledColor = buttonColorPressed;
        defaultCustomization.guidanceCustomization.buttonBackgroundHighlightColor = buttonColorPressed;
        defaultCustomization.guidanceCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.buttonTextDisabledColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.retryScreenImageBorderColor = borderColor;
        // defaultCustomization.guidanceCustomization.retryScreenOvalStrokeColor = borderColor;
        // Ajustar cor da Elipse para laranja
        defaultCustomization.guidanceCustomization.retryScreenOvalStrokeColor = ovalColor;        
    
        // Set Oval Customization
        defaultCustomization.ovalCustomization.strokeColor = ovalColor;
        defaultCustomization.ovalCustomization.progressColor1 = dualSpinnerColor;
        defaultCustomization.ovalCustomization.progressColor2 = dualSpinnerColor;

        // Set Feedback Customization
        defaultCustomization.feedbackCustomization.backgroundColor = "#ffffff"; // buttonAndFeedbackBarColor;
        defaultCustomization.feedbackCustomization.textColor = "#231D19"; // buttonAndFeedbackBarTextColor;

        // Set Cancel Customization
        defaultCustomization.cancelButtonCustomization.customImage = cancelButtonImage;
        defaultCustomization.cancelButtonCustomization.location = cancelButtonLocation;

        // Set Security Watermark Customization
        defaultCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(securityWatermarkImage);

        // Set Result Screen Customization
        defaultCustomization.resultScreenCustomization.backgroundColors = frameColor;
        defaultCustomization.resultScreenCustomization.foregroundColor = textColor;
        defaultCustomization.resultScreenCustomization.activityIndicatorColor = buttonAndFeedbackBarColor;
        defaultCustomization.resultScreenCustomization.resultAnimationBackgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.resultScreenCustomization.resultAnimationForegroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.resultScreenCustomization.uploadProgressFillColor = buttonAndFeedbackBarColor;

        this.currentCustomization = defaultCustomization;

        // Set ID Scan Customization
        defaultCustomization.idScanCustomization.selectionScreenBackgroundColors = frameColor;
        defaultCustomization.idScanCustomization.selectionScreenForegroundColor = textColor;
        defaultCustomization.idScanCustomization.reviewScreenBackgroundColors = frameColor;
        defaultCustomization.idScanCustomization.reviewScreenForegroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.reviewScreenTextBackgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.captureScreenForegroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.captureScreenTextBackgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.buttonBackgroundNormalColor = buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.buttonBackgroundDisabledColor = buttonColorPressed;
        defaultCustomization.idScanCustomization.buttonBackgroundHighlightColor = buttonColorPressed;
        defaultCustomization.idScanCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.buttonTextDisabledColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.captureScreenBackgroundColor = frameColor;
        defaultCustomization.idScanCustomization.captureFrameStrokeColor = borderColor;

        // Set Initial Loading Customization
        // defaultCustomization.initialLoadingAnimationCustomization.backgroundColor = buttonAndFeedbackBarTextColor;
        // defaultCustomization.initialLoadingAnimationCustomization.foregroundColor = buttonAndFeedbackBarColor;   

        // alterar cor loader
        defaultCustomization.initialLoadingAnimationCustomization.backgroundColor = "rgba(255,255,255,0.0)";
        defaultCustomization.initialLoadingAnimationCustomization.foregroundColor = "rgba(255,255,255,0.0)";    
        
        
        this.currentCustomization.vocalGuidanceCustomization.mode = 2;

        return defaultCustomization;
    };


    function retrieveLowLightConfigurationWizardCustomization(FaceTecSDK) {
        var sdkImageDirectory = "assets/facetec/images/";


        // Personalizado
        var outerBackgroundColor = "#ffffff";
        var frameColor = "#ffffff";
        var borderColor = "#ffffff";
        var ovalColor = "#EC7000";

        var dualSpinnerColor = "#EC7000"; //laranja meio escuro        
        var textColor = "#FFFFFF";
        var buttonAndFeedbackBarColor = "#f24f00";
        var buttonAndFeedbackBarTextColor = "#ffffff";
        var buttonColorPressed = "#EC7000";



        // For Frame Corner Radius Customization
        let frameCornerRadius = "20px";

        // For Cancel Button Customization
        // var cancelButtonImage = sdkImageDirectory + "FaceTec_cancel.png";
        var cancelButtonImage = sdkImageDirectory + "FaceTec_cancel_itau.png";
        // var cancelButtonLocation = FaceTecSDK.FaceTecCancelButtonLocation.Disabled;        
        var cancelButtonLocation = FaceTecSDK.FaceTecCancelButtonLocation.TopRight;

        // For image Customization
        var yourAppLogoImage = sdkImageDirectory + "itau_logo.png";
        var securityWatermarkImage = FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec_ZoOm;

        // Set a default customization
        var defaultCustomization = new FaceTecSDK.FaceTecCustomization();   

        // Set Frame Customization
        defaultCustomization.frameCustomization.borderCornerRadius = frameCornerRadius;
        defaultCustomization.frameCustomization.backgroundColor = frameColor;
        defaultCustomization.frameCustomization.borderColor = borderColor;

        // Set Overlay Customization
        // Remover Your Logo
        defaultCustomization.overlayCustomization.showBrandingImage = false;
        defaultCustomization.overlayCustomization.brandingImage = ""; // yourAppLogoImage;
        defaultCustomization.overlayCustomization.backgroundColor = ""; // outerBackgroundColor;

        // Set Guidance Customization
        defaultCustomization.guidanceCustomization.backgroundColors = frameColor;        
        defaultCustomization.guidanceCustomization.foregroundColor = textColor; 
        defaultCustomization.guidanceCustomization.buttonBackgroundNormalColor = "#EC7000"; // buttonAndFeedbackBarColor;
        defaultCustomization.guidanceCustomization.buttonBackgroundDisabledColor = buttonColorPressed;
        defaultCustomization.guidanceCustomization.buttonBackgroundHighlightColor = buttonColorPressed;
        defaultCustomization.guidanceCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.buttonTextDisabledColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.retryScreenImageBorderColor = borderColor;
        // defaultCustomization.guidanceCustomization.retryScreenOvalStrokeColor = borderColor;
        // Ajustar cor da Elipse para laranja
        defaultCustomization.guidanceCustomization.retryScreenOvalStrokeColor = ovalColor;        
    
        // Set Oval Customization
        defaultCustomization.ovalCustomization.strokeColor = ovalColor;
        defaultCustomization.ovalCustomization.progressColor1 = dualSpinnerColor;
        defaultCustomization.ovalCustomization.progressColor2 = dualSpinnerColor;

        // Set Feedback Customization
        defaultCustomization.feedbackCustomization.backgroundColor = "#ffffff"; // buttonAndFeedbackBarColor;
        defaultCustomization.feedbackCustomization.textColor = "#231D19"; // buttonAndFeedbackBarTextColor;

        // Set Cancel Customization
        defaultCustomization.cancelButtonCustomization.customImage = cancelButtonImage;
        defaultCustomization.cancelButtonCustomization.location = cancelButtonLocation;

        // Set Security Watermark Customization
        defaultCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(securityWatermarkImage);

        // Set Result Screen Customization
        defaultCustomization.resultScreenCustomization.backgroundColors = frameColor;
        defaultCustomization.resultScreenCustomization.foregroundColor = textColor;
        defaultCustomization.resultScreenCustomization.activityIndicatorColor = buttonAndFeedbackBarColor;
        defaultCustomization.resultScreenCustomization.resultAnimationBackgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.resultScreenCustomization.resultAnimationForegroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.resultScreenCustomization.uploadProgressFillColor = buttonAndFeedbackBarColor;

        this.currentCustomization = defaultCustomization;

        // Set ID Scan Customization
        defaultCustomization.idScanCustomization.selectionScreenBackgroundColors = frameColor;
        defaultCustomization.idScanCustomization.selectionScreenForegroundColor = textColor;
        defaultCustomization.idScanCustomization.reviewScreenBackgroundColors = frameColor;
        defaultCustomization.idScanCustomization.reviewScreenForegroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.reviewScreenTextBackgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.captureScreenForegroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.captureScreenTextBackgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.buttonBackgroundNormalColor = buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.buttonBackgroundDisabledColor = buttonColorPressed;
        defaultCustomization.idScanCustomization.buttonBackgroundHighlightColor = buttonColorPressed;
        defaultCustomization.idScanCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.buttonTextDisabledColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.captureScreenBackgroundColor = frameColor;
        defaultCustomization.idScanCustomization.captureFrameStrokeColor = borderColor;

        // Set Initial Loading Customization
        // defaultCustomization.initialLoadingAnimationCustomization.backgroundColor = buttonAndFeedbackBarTextColor;
        // defaultCustomization.initialLoadingAnimationCustomization.foregroundColor = buttonAndFeedbackBarColor;        


        // alterar cor loader                   
        defaultCustomization.initialLoadingAnimationCustomization.backgroundColor = "rgba(255,255,255,0.0)";
        defaultCustomization.initialLoadingAnimationCustomization.foregroundColor = "rgba(255,255,255,0.0)";  
        
        this.currentCustomization.vocalGuidanceCustomization.mode = 2;

        return defaultCustomization;
    };

    var currentCustomization;

    var wasSDKConfiguredWithConfigWizard = true;


    return {
        BaseURL,
        DeviceKeyIdentifier,
        PublicFaceScanEncryptionKey,
        currentCustomization,
        retrieveConfigurationWizardCustomization,
        retrieveLowLightConfigurationWizardCustomization,
        wasSDKConfiguredWithConfigWizard,
        initializeFromAutogeneratedConfig
    };

})();
