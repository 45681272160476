import { Injectable } from '@angular/core';
import { ItauDigitalAnalyticsView } from '@app-analytics/analytics';
import { AnalyticsGA4State } from 'src/app/interfaces/AnalyticsGA4State';
import { IDefaultParams } from 'src/app/interfaces/IDefaultParams';

declare const window: any;
const BUSINESS = 'pf';
const ENVIRONMENT = 'nao-logado';

@Injectable({
  providedIn: 'root',
})
export class TaggingServiceGA4 {
  visitorSegmento: string;
  analyticsData: any;
  constructor(
    private itauDigitalAnalytics: ItauDigitalAnalyticsView,
  ) {
    this.analyticsData = {
      canal: sessionStorage.getItem('cn'),
      ambiente: sessionStorage.getItem('ambiente'),
      negocio: sessionStorage.getItem('negocio'),
      tipoCanal: sessionStorage.getItem('tipoDeCanal'),
      prefixPagina: sessionStorage.getItem('prefix_pagina'),
      tid: sessionStorage.getItem('tid'),
      idproposta: sessionStorage.getItem('idproposta'),
    };
  }

  initTagging(): void {
    this.init();
  }

  private init(): void {
    let paramsAnalytics: IDefaultParams = {
      analytics: {
        parameters: {
          business: BUSINESS,
          environment: ENVIRONMENT,
          business_channel: 'default'
        }
      }
    };
    switch (this.analyticsData.prefixPagina) {
      case 'AberturaSimplificada/Biometria':
        paramsAnalytics.analytics.parameters.business_channel = 'abreconta';
        break;
      case 'CockpitAgencias':
        paramsAnalytics.analytics.parameters.business_channel = 'phygital';
        break;
      case 'Corban':
        paramsAnalytics.analytics.parameters.business_channel = 'corban';
        break;
      default:
        paramsAnalytics.analytics.parameters.business_channel = 'default';
        break;
    }
    this.itauDigitalAnalytics.setDefaultParameters(paramsAnalytics);
  }

  getPageAnalyticsData(
    ambiente: string,
    negocio: string,
    tipoDeCanal: string,
    pagina: string,
    rule: string,
    visitor: {},
    custom: any) {
    const obj = {
      site: {
        ambiente,
        negocio,
        tipoDeCanal
      },
      page: {
        ambiente: '0',
        nome: `AC:${ambiente}:${negocio}:${pagina}`
      },
      visitor,
      rule,
      custom
    };
    if (!obj.custom) {
      delete obj.custom;
    }
    return obj;
  }

  trackItemEvent(eventName: string, pageName: string, event: string) {
    window.analyticsData = {
      rule: 'customLink',
      page: {
        nome: `${this.analyticsData.canal}:${this.analyticsData.ambiente}:${this.analyticsData.negocio}:${this.analyticsData.prefixPagina}:${eventName}`,
      },
      events: {
        category: event,
        action: `${this.analyticsData.prefixPagina}:${eventName}`,
        label: `BTN:${this.analyticsData.prefixPagina}:${eventName}:${pageName}`,
      },
    };
    setTimeout(() => {
      window._frameworkDA.Track();
    }, 100);
  }

  trackGA4(state: Partial<AnalyticsGA4State>) {

    let newState = { ...state };

    // tratamento para atribuir a descricao do erro
    if (newState.event_name === 'alert') {
      newState = { ...newState, description: newState.detail };
      delete newState.detail;
    }

    const user_property = {
      analytics: {
        user: {
          person_id: newState.person_id,
          customer_segment: newState.customer_segment
        }
      }
    };
    this.itauDigitalAnalytics.setUserProperties(user_property);

    const evento = {
      analytics: {
        event_name: newState.event_name,
        parameters: {
          custom_path: newState.custom_path,
          detail: newState.detail,
          description: newState.description,
          implementation_team: 'hub:aquisicoes',
          proposal_id: newState.proposal_id
        }
      }
    };

    if (
      !evento.analytics.parameters.detail ||
      evento.analytics.parameters.detail === ''
    ) {
      delete evento.analytics.parameters.detail;
    }
    if (
      !evento.analytics.parameters.description ||
      evento.analytics.parameters.description === ''
    ) {
      delete evento.analytics.parameters.description;
    }
    this.itauDigitalAnalytics.track(evento);
  }
}